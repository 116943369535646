import { BASE_CORE_URL, BASE_URL } from "../../config";
import { ErrorToast } from "../../CustomHooks/ErrorToast";
import { SuccessToast } from "../../CustomHooks/SuccessToast";
import { cameraActions } from "../../redux/features/camera/camera";
import { fetchSavedFiltersList } from "../filterService";

export const saveFilter = (pName, pType, filterText) => async (dispatch) => {
    try {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authcode": localStorage.getItem('authCode'),
                "Authorization": "Bearer " + localStorage.getItem('authToken')
            },
        };
        let loggedInUser = JSON.parse(localStorage.getItem('user'));
        let userUniqueId = loggedInUser?.UserUniqueID;
        let url = BASE_CORE_URL + "Device/SaveFilter" + `?pName=${pName}&pType=${pType}&userUniqueId=${userUniqueId}&filterText=${filterText}`;
        const response = await fetch(url, requestOptions).then(res => res.json());

        if (response?.Status === "success") {
            SuccessToast(response?.Message)

            console.log("saved filter response => ", response);
            dispatch(fetchSavedFiltersList(pType))
            // const DDLData = response?.Data?.map(item => {
            //     const { ClientId, ClientName } = item;
            //     return { label: ClientName, value: parseInt(ClientId) }
            // })
            // dispatch(cameraActions.setSavedFiltersList(DDLData));
        } else if (response?.Status === "failed") {
            ErrorToast(response?.Message)
        }
    }
    catch (ex) {
        console.log(ex);
    }
}

export const fetchDevicesList = (clientIds, siteIds, interfaceSubTypeIds, interfaceIds, devicesIds, isAppliedBySavedFilter) => async (dispatch) => {
    try {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authcode": localStorage.getItem('authCode'),
                "Authorization": "Bearer " + localStorage.getItem('authToken')
            },
        };

        let url = BASE_CORE_URL + "Cameras/GetDevicesList" + `?clientIds=${clientIds}&siteIds=${siteIds}&interfaceSubTypeIds=${interfaceSubTypeIds}&interfaceIds=${interfaceIds}&devicesIds=${devicesIds}`;
        const response = await fetch(url, requestOptions).then(res => res.json());

        if (response?.Status === "success" && response?.Data?.length > 0) {
            SuccessToast(response?.Message)
            if (isAppliedBySavedFilter) {
                dispatch(cameraActions.setDevicesList(response?.Data));
            } else {
                const devicesList = response?.Data?.map(item => {
                    return {
                        ...item,
                        isChecked: true
                    }
                })
                dispatch(cameraActions.setDevicesList(devicesList));
            }
        }
    }
    catch (ex) {
        console.log(ex);
    }
}

export const fetchCameraDetailsById = (deviceId) => async (dispatch) => {
    try {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authcode": localStorage.getItem('authCode'),
                "Authorization": "Bearer " + localStorage.getItem('authToken')
            },
        };

        let url = BASE_CORE_URL + "Cameras/GetCameraDetailsById" + `?deviceId=${deviceId}`;
        const response = await fetch(url, requestOptions).then(res => res.json());

        if (response?.Status === "success" && response?.Data) {
            dispatch(cameraActions.setCameraDetails(response?.Data));
        }
    }
    catch (ex) {
        console.log(ex);
    }
}

// WD => Without dispatch
export const fetchCameraDetailsByIdWD = async (deviceId) => {
    try {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authcode": localStorage.getItem('authCode'),
                "Authorization": "Bearer " + localStorage.getItem('authToken')
            },
        };

        let url = BASE_CORE_URL + "Cameras/GetCameraDetailsById" + `?deviceId=${deviceId}`;
        const response = await fetch(url, requestOptions).then(res => res.json());

        if (response?.Status === "success" && response?.Data) {
            return response?.Data;
        }
    }
    catch (ex) {
        console.log(ex);
    }
}
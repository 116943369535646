import React, { useEffect, useState } from "react";

import "rsuite/dist/rsuite.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-alice-carousel/lib/alice-carousel.css";
import {
  CameraBlock,
  CameraStatus,
  DVRStatus,
  DeviceGrayIcon,
  FaultyDevice,
  HDDstatus,
  InfoBlue,
  InfoBlueWhite,
  OpenPopupIconBlueWhite,
  OpenPopupIconBlue,
  RefreshBtnIcon,
  SystemHealth,
  PanelStatus,
  SensorStatusIcon,
  ArrowLeft,
  ArrowRight,
  DeleteIcon,
  FilterIcon,
  ClearFilter,
  SaveFilter,
  RemoveIcon,
  WarningDelete,
  InfoIcon1,
  SearchIcon,
  SearchIconWhite,
} from "../../assets/images";

import {
  AllWidgetData,
  getHealthMonitorData,
  getHealthMonitoringSiteID,
} from "../../services/healthMonitoringService";

import Skeleton from "react-loading-skeleton";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import AliceCarousel from "react-alice-carousel";
import {
  Chart as ChartJS,
  ArcElement,
  Legend,
  LinearScale,
  CategoryScale,
  PointElement,
  TimeScale,
  BarElement,
} from "chart.js";

import HealthCard from "./HealthCard";
import HealthTable from "./HealthTable";
import HealthOfflineDevices from "./HealthOfflineDevices";
import HealthEvents from "./HealthEvents";
import SensorStatus from "./SensorStatus";
import HealthPercent from "./HealthPercent";
import { HEALTHMONITORING_INTERVAL_TIMER, HM_SELECT_SITE } from "../../config";
import {
  AllDateFormat,
  getCSIds,
  isNotNullOrEmpty,
  parseSCSEntitiesToObect,
} from "../../utils/utils";
import HealthIntegratedChart from "./HealthIntegratedChart";
import FilterTool from "../Reports/FilterTool";
import HMOfflineDev from "./HMOfflineDev";
import FilterToolDDL from "../../components/Filter/FilterToolDDL";
import { useDispatch, useSelector } from "react-redux";
import { filterActions } from "../../redux/features/shared/filter";
import { modalActions } from "../../redux/features/shared/modal";
import {
  deleteSavedFilter,
  fetchClientList,
  fetchSavedFiltersList,
  fetchSiteList,
  saveFilter,
} from "../../services/filterService";
import { buttonActions } from "../../redux/features/shared/button";
import { Modal } from "react-bootstrap";
import { ErrorToast } from "../../CustomHooks/ErrorToast";
import { SuccessToast } from "../../CustomHooks/SuccessToast";
import { Toggle } from "rsuite";
import {
  getCustomerList,
  getInfoList,
  getSiteList,
} from "../../services/alertGridServices";
import { useForm } from "react-hook-form";
import AlertTemplateMessage from "../Configuration/AlertTemplate/AlertTemplateMessage";
import { HMDashboardActions } from "../../redux/features/HMDashboard";

//charts---
ChartJS.register(
  ArcElement,
  Legend,
  TimeScale,
  LinearScale,
  CategoryScale,
  PointElement,
  BarElement
);

const tooltip = (
  <Tooltip id="tooltip">
    Camera Tampering includes, Camera Block, Scene Change and Camera Tampering
  </Tooltip>
);

const HMDashboard = "Health Monitoring";
const CUSTOMER = "Customer";
const SITE = "Site";
const HealthMonitoring = ({ isDarkTheme }) => {
  //region useState start
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [refreshButton, setRefreshButton] = useState(false);
  const [cameraOnlineCount, setCameraOnlineCount] = useState(0);
  const [cameraOfflineCount, setCameraOfflineCount] = useState(0);
  const [nvrOnlineCount, setNVROnlineCount] = useState(0);
  const [nvrOfflineCount, setNVROfflineCount] = useState(0);
  const [hddOnlineCount, setHDDOnlineCount] = useState(0);
  const [hddOfflineCount, setHDDOfflineCount] = useState(0);
  const [totalCameraBlockCount, setTotalCameraBlockCount] = useState(0);
  const [systemHealthDevicesOfflineCount, setSystemHealthDevicesOfflineCount] =
    useState(0);
  const [systemHealthDevicesOnlineCount, setSystemHealthDevicesOnlineCount] =
    useState(0);
  const [onlinePercent, setOnlinePercent] = useState(0);
  const [top3OfflineDevices, setTop3OfflineDevices] = useState([]);
  const [show, setShow] = useState(false);
  const [panelOnlineCount, setPanelOnlineCount] = useState(0);
  const [panelOfflineCount, setPanelOfflineCount] = useState(0);
  const [sensorStatus, setSensorStatus] = useState([]);
  const [sensorCount, setSensorCount] = useState([]);
  const [bgcolour, setBgColour] = useState([]);
  const [sensorsum, setSensorSum] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [resetHmRefresh, setResetHmRefresh] = useState(false);
  const [nonIntegratedDevicesCount, setNonIntegratedDevicesCount] = useState(0);
  const [acsControllerOnlineCount, setACSControllerOnlineCount] = useState(0);
  const [acsControllerOfflineCount, setACSControllerOfflineCount] = useState(0);
  const [acsReaderOnlineCount, setACSReaderOnlineCount] = useState(0);
  const [acsReaderOfflineCount, setACSReaderOfflineCount] = useState(0);

  const [resetClicked, setResetClicked] = useState(false);
  // const [siteIds, setSiteIds] = useState("");
  // const [customerIds, setCustomerIds] = useState("");
  const [toggleSelection, settoggleSelection] = useState(true);
  const [singleView, setSingleView] = useState(false)

  const [siteId, setSiteId] = useState(0);
  const [customerId, setcustomerId] = useState(0);

  const [openInfoFilter, setOpenInfoFilter] = useState(false);
  //const [openDeleteFilter, setOpenDeleteFilter] = useState(false);
  const [modalFilterName, setModalFilterName] = useState("");
  const [modalFilterId, setModalFilterId] = useState(0);
  const [customerMasterArr, setCustomerMasterArr] = useState([]);
  const [customerInfoArr, setCustomerInfoArr] = useState([]);
  const [siteMasterArr, setSiteMasterArr] = useState([]);
  const [siteInfoArr, setSiteInfoArr] = useState([]);
  const [customerSearch, setCustomerSearch] = useState("");
  const [siteSearch, setSiteSearch] = useState("");
  const [siteList, setSiteList] = useState([]);
  const [customerList, setCustomerList] = useState([]);

  const CLIENT = "Client";
  const SITE = "Site";

  // const [containerWidth, setContainerWidth] = useState(0);

  //region useState end

  //#region Redux states and dispatcher

  const dispatch = useDispatch();

  const filter = useSelector((state) => state?.filter);
  const { savedFilters, customer, site, filterName } = filter;
  const buttonVisibilityState = useSelector(
    (state) => state?.button?.buttonVisibilityState
  );
  const { visibleFilterBtn, visibleClearFilterBtn, visibleSaveFilterBtn } =
    buttonVisibilityState;
  const modalVisibilityState = useSelector(
    (state) => state?.modal?.modalVisibilityState
  );
  const { showSavedFilterSaveModal, showSavedFilterDeleteModal } =
    modalVisibilityState;
  const HMDashboardPage = useSelector(
    (state) => state?.HMDashboard?.HMDashboardPage
  );
  const { customerIds, siteIds } = HMDashboardPage;

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { isDirty, touchedFields },
  } = useForm({ defaultValue: { ...site } });

  //#endregion

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
    1280: { items: 2 },
    1366: { items: 3 },
  };

  console.count("HealthMonitoring conponent render count: ");

  //#region useEffect Start
  useEffect(() => {
    return () => {
      dispatch(filterActions.resetFilterStates());
      dispatch(buttonActions.resetAllButtons());
      dispatch(HMDashboardActions.resetHMDashboardStates());
    };
  }, []);

  useEffect(() => {
    dispatch(fetchSavedFiltersList(HMDashboard));
    dispatch(fetchClientList());
  }, []);

  useEffect(() => {
    //true is disabled, false is enable
    if (
      isNotNullOrEmpty(savedFilters?.selected) ||
      isNotNullOrEmpty(customer?.selected)
    ) {
      //Keep enable Filter and Clear Filter buttons when there's at least one data is selected
      dispatch(buttonActions.disableFilterBtn(false));
      dispatch(buttonActions.disableClearFilterBtn(false));
      if (isNotNullOrEmpty(savedFilters?.selected)) {
        dispatch(buttonActions.disableSaveFilterBtn(true));
        dispatch(buttonActions.disableFilterBtn(true));
        dispatch(buttonActions.disableClearFilterBtn(true));
      } else {
        dispatch(buttonActions.disableSaveFilterBtn(false));
        dispatch(buttonActions.disableFilterBtn(false));
        dispatch(buttonActions.disableClearFilterBtn(false));
      }
    } else {
      //Disable Filter and Clear Filter buttons when no data is selected
      dispatch(buttonActions.disableFilterBtn(true));
      dispatch(buttonActions.disableClearFilterBtn(true));
      dispatch(buttonActions.disableSaveFilterBtn(true));
    }

    //Fetch saved filters list
    if (!isNotNullOrEmpty(savedFilters?.options)) {
      dispatch(fetchSavedFiltersList(HMDashboard));
    }

    //Fetch customer DDL data
    if (!isNotNullOrEmpty(customer?.options)) {
      dispatch(fetchClientList());
    }

    //Fetch site DDL data
    if (customer?.selected?.length > 0) {
      let ids = getCSIds(customer?.selected);
      dispatch(fetchSiteList(ids ?? ""));
    }
  }, [savedFilters?.selected, customer?.selected, site?.selected]);

  useEffect(() => {
    getAllWidgetData(false);
  }, []);

  useEffect(() => {
    if (!isNotNullOrEmpty(customer?.selected)) {
      dispatch(HMDashboardActions.setCustomerIds(""));
      dispatch(HMDashboardActions.setSiteIds(""));
      // setCustomerIds("");
      // setSiteIds("");
    }
  }, [customer?.selected]);

  useEffect(() => {
    // if (siteIds !== "" || customerIds !== "") {
    //   // Call API
    // }
    getAllWidgetData(true);
  }, [siteIds, customerIds]);

  useEffect(() => {
    const interval = setInterval(async () => {
      refreshData();
      // }, 10000);
    }, HEALTHMONITORING_INTERVAL_TIMER);
    return () => {
      clearInterval(interval);
    };
  }, [siteIds, customerIds]);

  useEffect(() => {
    getCustomerList()
      .then((res) => {
        console.log("Customer List : ", res);
        if (res.Status == "success") {
          setCustomerList(res.Data);
        }
        console.log("Customer Data : ", res.Data);
      })
      .catch((err) => console.log(err));
  }, []);

  const getSiteListData = (id) => {
    getSiteList(id)
      .then((res) => {
        console.log("Site List : ", res);
        if (res.Status == "success") {
          setSiteList(res.Data);
        }
      })
      .catch((err) => console.log(err));
  };

  console.log("SiteList Data ", siteList);

  // useEffect(() => {
  //   const adjustContainerWidth = () => {
  //     const mainContainer = document.getElementById('mainContainer');
  //     const contents = mainContainer.children;
  //     let totalWidth = 0;

  //     for (let content of contents) {
  //       totalWidth += content.offsetWidth + 20;
  //     }

  //     setContainerWidth(Math.min(totalWidth, 1410));
  //   };

  //   // adjustContainerWidth();
  //   window.addEventListener('resize', adjustContainerWidth);

  //   return () => window.removeEventListener('resize', adjustContainerWidth);
  // }, []);

  // console.log("customerIds outside useEffect => ", customerIds);
  // console.log("siteIds outside useEffect => ", siteIds);

  // useEffect(() => {
  //   console.log("customerIds inside useEffect => ", customerIds);
  //   console.log("siteIds inside useEffect => ", siteIds);
  //   const interval = setInterval(async () => {
  //     refreshData();
  //   }, 10000);
  //   // }, HEALTHMONITORING_INTERVAL_TIMER);
  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, []);

  //#endregion useEffcet End

  //#region functions start
  const handleShow = () => setShow(true);

  const refreshData = () => {
    setRefreshButton(true);
    getAllWidgetData(false);
  };

  const getAllWidgetData = (showToast) => {
    setLoading(true);
    let tempUser = JSON.parse(localStorage.getItem("user"));
    let userUniqueID = tempUser.UserUniqueID;
    try {
      AllWidgetData(userUniqueID, customerIds ?? "", siteIds ?? "").then(
        (resp) => {
          if (resp != undefined) {
            if (resp.Status == "success") {
              if (
                showToast &&
                (isNotNullOrEmpty(savedFilters?.selected) ||
                  isNotNullOrEmpty(customer?.selected))
              ) {
                SuccessToast("Filter applied successfully");
              }
              let refreshDateHM = new Date();
              //setResetHmRefresh(AllDateFormat(refreshDateHM))
              let onlineCount = 0;
              let offlineCount = 0;
              let total = 0;
              let sum = 0;
              let totalSystemHealthOffline = resp.Data.filter(
                (item) =>
                  item.Description == "System Health" && item.Status == "0"
              );
              setSystemHealthDevicesOfflineCount(
                totalSystemHealthOffline[0]?.Count || 0
              );
              offlineCount = totalSystemHealthOffline[0]?.Count || 0;
              let totalSystemHealthOnline = resp.Data.filter(
                (item) =>
                  item.Description == "System Health" && item.Status == "1"
              );
              setSystemHealthDevicesOnlineCount(
                totalSystemHealthOnline[0]?.Count || 0
              );
              onlineCount = totalSystemHealthOnline[0]?.Count || 0;

              let nonIntegratedDevices = resp.Data.filter(
                (item) =>
                  item.Description == "System Health" && item.Status == "-1"
              );
              setNonIntegratedDevicesCount(nonIntegratedDevices[0]?.Count || 0);
              let tempCount = 0;
              let tempOfflineNVR = resp.Data.filter(
                (item) =>
                  (item.Description == "NVR" ||
                    item.Description == "DVR" ||
                    item.Description == "Hybrid") &&
                  item.Status == "0"
              );
              if (tempOfflineNVR != undefined && tempOfflineNVR.length > 0) {
                tempOfflineNVR?.map((x) => (tempCount += parseInt(x.Count)));
              }
              // setNVROfflineCount(tempOfflineNVR[0]?.Count || 0)
              setNVROfflineCount(tempCount);

              tempCount = 0;
              let tempOnlineNVR = resp.Data.filter(
                (item) =>
                  (item.Description == "NVR" ||
                    item.Description == "DVR" ||
                    item.Description == "Hybrid") &&
                  item.Status == "1"
              );
              if (tempOnlineNVR != undefined && tempOnlineNVR.length > 0) {
                tempOnlineNVR?.map((x) => (tempCount += parseInt(x.Count)));
              }
              // setNVROnlineCount(tempOnlineNVR[0]?.Count || 0)
              setNVROnlineCount(tempCount);

              let tempOfflineCamera = resp.Data.filter(
                (item) => item.Description == "Camera" && item.Status == "0"
              );
              setCameraOfflineCount(tempOfflineCamera[0]?.Count || 0);
              let tempOnlineCamera = resp.Data.filter(
                (item) => item.Description == "Camera" && item.Status == "1"
              );
              setCameraOnlineCount(tempOnlineCamera[0]?.Count || 0);

              let tempOfflineHDD = resp.Data.filter(
                (item) => item.Description == "HDD" && item.Status == "0"
              );
              setHDDOfflineCount(tempOfflineHDD[0]?.Count || 0);
              let tempOnlineHDD = resp.Data.filter(
                (item) => item.Description == "HDD" && item.Status == "1"
              );
              setHDDOnlineCount(tempOnlineHDD[0]?.Count || 0);

              let totalCameraBlock = resp.Data.filter(
                (item) => item.Description == "CameraBlockAlert"
              );
              setTotalCameraBlockCount(totalCameraBlock[0]?.Count || 0);

              let top3OfflineDevices = resp.Data.filter(
                (item) => item.Description == "OfflineDevice"
              );
              setTop3OfflineDevices(top3OfflineDevices);

              let tempOfflinePanel = resp.Data.filter(
                (item) =>
                  item.Description == "PanelStatus" && item.Status == "0"
              );
              setPanelOfflineCount(tempOfflinePanel[0]?.Count || 0);
              let tempOnlinePanel = resp.Data.filter(
                (item) =>
                  item.Description == "PanelStatus" && item.Status == "1"
              );
              setPanelOnlineCount(tempOnlinePanel[0]?.Count || 0);

              let tempSensorStatus = resp.Data.filter(
                (item) => item.Description == "SensorStatus"
              );
              let sensorCount = tempSensorStatus.map((item) =>
                parseInt(item.Count)
              );
              setSensorCount(sensorCount);
              for (let i = 0; i < sensorCount.length; i++) {
                sum += sensorCount[i];
              }
              setSensorSum(sum);

              let sensorStatusLocal = tempSensorStatus.map(
                (item) => item.Status
              );
              setSensorStatus(sensorStatusLocal);

              let backgroundColor = [];
              for (let i = 0; i < tempSensorStatus.length; i++) {
                if (tempSensorStatus[i].Status == "Zone Restoral") {
                  backgroundColor.push("#7BB526");
                } else if (tempSensorStatus[i].Status == "Zone Alert") {
                  backgroundColor.push("#F32C22");
                } else if (tempSensorStatus[i].Status == "No Information") {
                  backgroundColor.push("#131415");
                }
              }
              setBgColour(backgroundColor);
              setLoading(false);

              let tempOfflineACSController = resp.Data.filter(
                (item) =>
                  item.Description == "ACS Controller" && item.Status == "0"
              );
              setACSControllerOfflineCount(
                tempOfflineACSController[0]?.Count || 0
              );
              let tempOnlineACSController = resp.Data.filter(
                (item) =>
                  item.Description == "ACS Controller" && item.Status == "1"
              );
              setACSControllerOnlineCount(
                tempOnlineACSController[0]?.Count || 0
              );

              let tempOfflineACSReader = resp.Data.filter(
                (item) => item.Description == "ACS Reader" && item.Status == "0"
              );
              setACSReaderOfflineCount(tempOfflineACSReader[0]?.Count || 0);
              let tempOnlineACSReader = resp.Data.filter(
                (item) => item.Description == "ACS Reader" && item.Status == "1"
              );
              setACSReaderOnlineCount(tempOnlineACSReader[0]?.Count || 0);

              total = parseInt(onlineCount) + parseInt(offlineCount);
              let percent = (onlineCount / total) * 100;
              if (isNaN(percent)) {
                setOnlinePercent(0);
              } else {
                setOnlinePercent(percent.toFixed(0));
              }
            } else {
              setLoading(false);
            }
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleSavedFilterItemSelectClick = (e, item) => {
    dispatch(filterActions.resetCustomer());
    dispatch(filterActions.resetSite());
    let filterData = parseSCSEntitiesToObect(item?.filterValues);
    if (isNotNullOrEmpty(filterData)) {
      let clientIds = filterData?.Client;
      let siteIds = filterData?.Site;
      dispatch(HMDashboardActions.setCustomerIds(clientIds));
      dispatch(HMDashboardActions.setSiteIds(siteIds));
      // setCustomerIds(clientIds);
      // setSiteIds(siteIds);
    }
    // dispatch(filterActions.resetCustomer());
    dispatch(filterActions.setSavedFilterSelected(item));
  };

  const handleFilterClick = () => {
    // else {
    let clientIds = getCSIds(customer?.selected);
    let siteIds = getCSIds(site?.selected);
    dispatch(HMDashboardActions.setCustomerIds(clientIds));
    dispatch(HMDashboardActions.setSiteIds(siteIds));
    // setCustomerIds(clientIds);
    // setSiteIds(siteIds);
    // let interfaceSubTypeIds = getCSIds(interfaceSubType?.selected);
    // let interfaceIds = getCSIds(interfaceName?.selected);
    // let devicesIds = getCSDeviceIds();
    //send false if applying from individual filters
    // dispatch(fetchDevicesList(clientIds ?? "", siteIds ?? "", interfaceSubTypeIds ?? "", interfaceIds ?? "", null ?? "", false));
    // }
  };

  const handleClearFilterClick = () => {
    dispatch(filterActions.resetSavedFilter());
    dispatch(filterActions.resetCustomer());
    dispatch(filterActions.resetSite());
    dispatch(buttonActions.resetAllButtons());
    dispatch(HMDashboardActions.setCustomerIds(""));
    dispatch(HMDashboardActions.setSiteIds(""));
    // setCustomerIds("");
    // setSiteIds("");
  };

  const handleFilterNameInputChange = (e) => {
    let { value } = e.target;
    dispatch(filterActions.setFilterName(value));
  };

  const handleSaveFilterModalClick = () => {
    let clientIds = getCSIds(customer?.selected);
    let siteIds = getCSIds(site?.selected);

    let filterText = `clientIds:${clientIds ?? ""};siteIds:${siteIds ?? ""}`;
    if (filterName === "") {
      console.log("filterName", filterName);
      ErrorToast("Kindly enter a filter name");
      return;
    }
    dispatch(saveFilter(filterName, HMDashboard, filterText));
    dispatch(modalActions.showSavedFilterSaveModal(false));
    dispatch(filterActions.resetFilterName());
  };

  const handleDeleteSavedFilterModalYesClick = (e) => {
    let filterId = savedFilters?.selected?.filterId;
    dispatch(deleteSavedFilter(filterId));
  };

  const handleDeleteSavedFilterNoClick = (e) => {
    dispatch(modalActions.showSavedFilterDeleteModal(false));
  };

  const handleClearSelectedClick = () => {
    dispatch(filterActions.resetSavedFilter());
    dispatch(HMDashboardActions.setCustomerIds(""));
    dispatch(HMDashboardActions.setSiteIds(""));
    // setCustomerIds("");
    // setSiteIds("");
  };

  const handleFilterItemChange = (value, filter) => {
    switch (filter) {
      case CUSTOMER:
        dispatch(filterActions?.setCustomerSelected(value));
        dispatch(filterActions.resetSavedFilter());
        break;
      case SITE:
        dispatch(filterActions?.setSiteSelected(value));
        break;
      default:
        break;
    }
  };

  const infoFilterList = (pId) => {
    getInfoList(pId)
      .then((res) => {
        if (res.Status == "success") {
          let temp = JSON.parse(res.Data);
          infoDataFilter(temp);
        } else {
          ErrorToast(res.Message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const infoDataFilter = (arr) => {
  //   let customerArr = arr.filter((item) => item.Entity == CLIENT);
  //   setCustomerInfoArr(customerArr)
  //   setCustomerMasterArr(customerArr)
  //   let siteArr = arr.filter((item) => item.Entity == SITE);
  //   setSiteInfoArr(siteArr)
  //   setSiteMasterArr(siteArr)

  // }

  const searchCustomerHandler = (e) => {
    setCustomerSearch(e.target.value);
    let tempCustomer = customerMasterArr.filter((x) =>
      x.Values.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setCustomerInfoArr(tempCustomer);
  };

  // const searchSiteHandler = (e) => {
  //   setSiteSearch(e.target.value)
  //   let tempSite = siteMasterArr.filter(x => x.Values.toLowerCase().includes(e.target.value.toLowerCase()))
  //   setSiteInfoArr(tempSite)
  // }

  // const infoFilterList = (pId) => {
  //   getInfoList(pId)
  //     .then((res) => {
  //       if (res.Status == "success") {
  //         let temp = JSON.parse(res.Data)
  //         infoDataFilter(temp)
  //       } else {
  //         ErrorToast(res.Message)
  //       }
  //     }).catch((err) => {
  //       console.log(err);
  //     })
  // }

  const infoDataFilter = (arr) => {
    let customerArr = arr.filter((item) => item.Entity == CLIENT);
    setCustomerInfoArr(customerArr);
    setCustomerMasterArr(customerArr);
    let siteArr = arr.filter((item) => item.Entity == SITE);
    setSiteInfoArr(siteArr);
    setSiteMasterArr(siteArr);
  };

  // const searchCustomerHandler = (e) => {
  //   setCustomerSearch(e.target.value)
  //   let tempCustomer = customerMasterArr.filter(x => x.Values.toLowerCase().includes(e.target.value.toLowerCase()))
  //   setCustomerInfoArr(tempCustomer)
  // }

  const searchSiteHandler = (e) => {
    setSiteSearch(e.target.value);
    let tempSite = siteMasterArr.filter((x) =>
      x.Values.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setSiteInfoArr(tempSite);
  };

  //#endregion functions end

  //#region tooltip UI start
  const tooltip1 = (
    <Tooltip id="tooltip1">
      <div style={{ textAlign: "left" }}>
        <p
          style={{
            maxWidth: "150px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          className="mb-0"
        >
          {top3OfflineDevices[0]?.Count.slice(0, -3)}
        </p>
        <div style={{ height: "auto" }}>
          <p className="deviceNameTooltipText mb-0">Device Name - </p>
          <p className="deviceNameTooltipName mb-0">
            {top3OfflineDevices[0]?.DeviceName}
          </p>
        </div>
        <div style={{ height: "auto" }}>
          <p className="siteNameTooltipText mb-0">Site Name - </p>
          <p className="siteNameTooltipName mb-0">
            {top3OfflineDevices[0]?.SiteName}
          </p>
        </div>
      </div>
    </Tooltip>
  );

  const tooltip2 = (
    <Tooltip id="tooltip2">
      <div style={{ textAlign: "left" }}>
        <p
          style={{
            maxWidth: "150px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          className="mb-0"
        >
          {top3OfflineDevices[1]?.Count.slice(0, -3)}
        </p>
        <div style={{ height: "auto" }}>
          <p className="deviceNameTooltipText mb-0">Device Name - </p>
          <p className="deviceNameTooltipName mb-0">
            {top3OfflineDevices[1]?.DeviceName}
          </p>
        </div>
        <div style={{ height: "auto" }}>
          <p className="siteNameTooltipText mb-0">Site Name - </p>
          <p className="siteNameTooltipName mb-0">
            {top3OfflineDevices[1]?.SiteName}
          </p>
        </div>
      </div>
    </Tooltip>
  );

  const tooltip3 = (
    <Tooltip id="tooltip3">
      <div style={{ textAlign: "left" }}>
        <p className="timeTooltipCount mb-0">
          {top3OfflineDevices[2]?.Count.slice(0, -3)}
        </p>
        <div style={{ height: "auto" }}>
          <p className="deviceNameTooltipText mb-0">Device Name - </p>
          <p className="deviceNameTooltipName mb-0">
            {top3OfflineDevices[2]?.DeviceName}
          </p>
        </div>
        <div style={{ height: "auto" }}>
          <p className="siteNameTooltipText mb-0">Site Name - </p>
          <p className="siteNameTooltipName mb-0">
            {top3OfflineDevices[2]?.SiteName}
          </p>
        </div>
      </div>
    </Tooltip>
  );
  //#endregion tooltip UI end

  const items = [
    <div className="item" data-value="1" onClick={() => setActiveIndex(0)}>
      <div>
        <div className="CardBoxWithBorder">
          <div className="CardBoxContent float-left w-100">
            <div className="IntegratedTextDiv">
              <div
                className="CardBoxImage"
                style={{ backgroundColor: "#1e93c2" }}
              >
                <img src={SystemHealth} alt="System health" />
              </div>
              <h4 className="SystemHealthCardText">System Health</h4>

              <div style={{ float: "left" }}>
                <div style={{ textAlign: "center" }}>
                  {loading ? (
                    <>
                      <Skeleton width={50} className="statusValueText" />
                      <Skeleton width={70} className="statusValueP" />
                    </>
                  ) : (
                    <>
                      <p className="statusValueP">
                        {parseInt(systemHealthDevicesOfflineCount) +
                          parseInt(systemHealthDevicesOnlineCount) +
                          parseInt(nonIntegratedDevicesCount)}
                      </p>
                      <p className="statusValueText">Total Devices</p>
                    </>
                  )}
                </div>

                <div className="mt-2" style={{ display: "flex" }}>
                  <div style={{ textAlign: "center" }}>
                    {loading ? (
                      <>
                        <Skeleton width={50} className="statusValueText" />
                        <Skeleton width={70} className="statusValueP" />
                      </>
                    ) : (
                      <>
                        <p
                          className="statusValueP"
                          style={{ fontSize: "20px" }}
                        >
                          {parseInt(systemHealthDevicesOfflineCount) +
                            parseInt(systemHealthDevicesOnlineCount)}
                        </p>
                        <p
                          className="statusValueText"
                          style={{ fontSize: "12px" }}
                        >
                          Integrated Devices
                        </p>
                      </>
                    )}
                  </div>
                  <div style={{ textAlign: "center" }} className="ml-2">
                    {loading ? (
                      <>
                        <Skeleton width={50} className="statusValueText" />
                        <Skeleton width={70} className="statusValueP" />
                      </>
                    ) : (
                      <>
                        <p
                          className="statusValueP"
                          style={{ fontSize: "20px" }}
                        >
                          {nonIntegratedDevicesCount}
                        </p>
                        <p
                          className="statusValueText"
                          style={{ fontSize: "12px" }}
                        >
                          Non Integrated Devices
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="IntegratedChartDiv">
              <div className="DonutChartWidthDiv" style={{ width: "auto" }}>
                <div style={{ width: "100%", height: "125px" }}>
                  {loading && !onlinePercent ? null : (
                    <div>
                      {/* <HealthPercent systemHealthDevicesOnlineCount={systemHealthDevicesOnlineCount} systemHealthDevicesOfflineCount={systemHealthDevicesOfflineCount} onlinePercent={onlinePercent} isDarkTheme={isDarkTheme} /> */}
                      <HealthIntegratedChart
                        isDarkTheme={isDarkTheme}
                        systemHealthDevicesOfflineCount={
                          systemHealthDevicesOfflineCount
                        }
                        systemHealthDevicesOnlineCount={
                          systemHealthDevicesOnlineCount
                        }
                        nonIntegratedDevicesCount={nonIntegratedDevicesCount}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/*  <div className='CardBoxText' style={{ display: "flex", justifyContent: "space-between", width:"85%" }}>
              <div>
                <div className="StatusText">
                  <div className="StatusValueDiv" style={{ width: "auto" }}>
                    {
                      loading
                        ? (
                          <>
                            <Skeleton width={70} className="statusValueText" />
                            <Skeleton width={50} className="statusValueP" />
                          </>
                        )
                        : (<>
                          <p className="statusValueP">{(parseInt(systemHealthDevicesOfflineCount)) + (parseInt(systemHealthDevicesOnlineCount))}</p>
                          <p className="statusValueText">Total Devices</p>
                        </>)
                    }
                    <p className="integratedDeviceText">Integrated devices count only</p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <HealthCard
          cardName="Storage Device Status"
          imageName={HDDstatus}
          loading={loading}
          altImageName="HDD status"
          bgColor="#561400"
          offlineCount={hddOfflineCount}
          onlineCount={hddOnlineCount}
          totalCount={parseInt(hddOfflineCount) + parseInt(hddOnlineCount)}
        />
      </div>
    </div>,

    <div className="item" data-value="2">
      <div>
        <HealthCard
          cardName="DVR/NVR Status"
          imageName={DVRStatus}
          loading={loading}
          altImageName="DVR/NVR status"
          bgColor="#da8012"
          offlineCount={nvrOfflineCount}
          onlineCount={nvrOnlineCount}
          totalCount={parseInt(nvrOfflineCount) + parseInt(nvrOnlineCount)}
        />
        <div className="CardBoxWithBorder">
          <div className="CardBoxContent float-left w-100">
            <div
              className="CardBoxImage"
              style={{ backgroundColor: "#1d8cdb" }}
            >
              <img src={CameraBlock} alt="Camera Block" />
            </div>
            <div
              className="CardBoxText"
              style={{ position: "relative", display: "block" }}
            >
              <div className="CameraInfoDiv">
                <OverlayTrigger placement="left" overlay={tooltip}>
                  <button style={{ background: "transparent" }}>
                    {isDarkTheme ? (
                      <img alt="InfoBlue" src={InfoBlue} />
                    ) : (
                      <img alt="InfoWhite" src={InfoBlueWhite} height="17" />
                    )}
                  </button>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>View details</Tooltip>}
                >
                  <button
                    style={{ background: "transparent" }}
                    onClick={handleShow}
                  >
                    {isDarkTheme ? (
                      <img alt="OpenPopup" src={OpenPopupIconBlue} />
                    ) : (
                      <img
                        alt="OpenPopupWhite"
                        src={OpenPopupIconBlueWhite}
                        height="17p"
                      />
                    )}
                  </button>
                </OverlayTrigger>
              </div>

              <h4>Camera Tampering For Today</h4>

              <div className="OnlySingleValue">
                {loading ? (
                  <Skeleton width={50} />
                ) : (
                  <p className="statusValueP">{totalCameraBlockCount}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,

    <div className="item" data-value="3">
      <div>
        <HealthCard
          cardName="Camera Status"
          imageName={CameraStatus}
          loading={loading}
          altImageName="Camera status"
          bgColor="#6459d6"
          offlineCount={cameraOfflineCount}
          onlineCount={cameraOnlineCount}
          totalCount={
            parseInt(cameraOfflineCount) + parseInt(cameraOnlineCount)
          }
        />
        <div className="CardBoxWithBorder">
          <div className="CardBoxContent float-left w-100">
            <div
              className="CardBoxImage"
              style={{ backgroundColor: "#27ad93" }}
            >
              <img src={FaultyDevice} alt="Faulty Device" />
            </div>
            <div className="CardBoxText">
              <div>
                <h4 style={{ marginTop: "0px" }}>
                  Top 3 Offline Camera Devices For Today
                </h4>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                {/* <div className="StatusText1">
                  <div className="StatusValueDiv">
                    <p className="statusValueP">
                      <span>{top3OfflineDevices[1]?.Count.slice(0, -3)}</span>
                      <OverlayTrigger placement="bottom" overlay={tooltip2}>
                        <span className="InfoImg">
                          <button style={{ background: "transparent" }}>
                            {
                              isDarkTheme ?
                                <img alt="InfoBlue" src={InfoBlue} /> :
                                <img alt="InfoWhite" src={InfoBlueWhite} height="17" />
                            }
                          </button>
                        </span>
                      </OverlayTrigger>
                    </p>
                    <div className="mb-1">
                      <div className="OfflineDeviceDiv">
                        <img alt="Device" src={DeviceGrayIcon} />
                        <p className="offlineDeviceText">{top3OfflineDevices[1]?.DeviceName}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="StatusText1">
                  <div className="StatusValueDiv">
                    <p className="statusValueP">
                      <span>{top3OfflineDevices[2]?.Count.slice(0, -3)}</span>
                      <OverlayTrigger placement="bottom" overlay={tooltip3}>
                        <span className="InfoImg">
                          <button style={{ background: "transparent" }}>
                            {
                              isDarkTheme ?
                                <img alt="InfoBlue" src={InfoBlue} /> :
                                <img alt="InfoWhite" src={InfoBlueWhite} height="17" />
                            }
                          </button>
                        </span>
                      </OverlayTrigger>
                    </p>
                    <div className="mb-1">
                      <div className="OfflineDeviceDiv">
                        <img alt="Device" src={DeviceGrayIcon} />
                        <p className="offlineDeviceText">{top3OfflineDevices[2]?.DeviceName}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="StatusText1">
                  <div className="StatusValueDiv">
                    <p className="statusValueP">
                      <span>{top3OfflineDevices[0]?.Count.slice(0, -3)}</span>
                      <OverlayTrigger placement="bottom" overlay={tooltip1}>
                        <span className="InfoImg">
                          <button style={{ background: "transparent" }}>
                            {
                              isDarkTheme ?
                                <img alt="InfoBlue" src={InfoBlue} /> :
                                <img alt="InfoWhite" src={InfoBlueWhite} height="17" />
                            }
                          </button>
                        </span>
                      </OverlayTrigger>
                    </p>
                    <div className="mb-1">
                      <div className="OfflineDeviceDiv">
                        <img alt="Device" src={DeviceGrayIcon} />
                        <p className="offlineDeviceText">{top3OfflineDevices[0]?.DeviceName}</p>
                      </div>
                    </div>
                  </div>
                </div> */}

                <HMOfflineDev
                  isDarkTheme={isDarkTheme}
                  data={top3OfflineDevices[1]}
                  tooltip={tooltip2}
                />
                <HMOfflineDev
                  isDarkTheme={isDarkTheme}
                  data={top3OfflineDevices[2]}
                  tooltip={tooltip3}
                />
                <HMOfflineDev
                  isDarkTheme={isDarkTheme}
                  data={top3OfflineDevices[0]}
                  tooltip={tooltip1}
                />
              </div>

              {/* Old UI */}
              <div style={{ display: "none" }}>
                {loading ? (
                  <>
                    <div className="StatusValueDiv">
                      <div className="mb-1">
                        <Skeleton width={70} />
                        <Skeleton width={70} />
                      </div>
                      <Skeleton width={70} className="statusValueP" />
                    </div>
                    <div className="StatusValueDiv">
                      <div className="mb-1">
                        <Skeleton width={70} />
                        <Skeleton width={70} />
                      </div>
                      <Skeleton width={70} className="statusValueP" />
                    </div>
                    <div className="StatusValueDiv">
                      <div className="mb-1">
                        <Skeleton width={70} />
                        <Skeleton width={70} />
                      </div>
                      <Skeleton width={70} className="statusValueP" />
                    </div>
                  </>
                ) : (
                  <>
                    {top3OfflineDevices.map((item) => (
                      <HealthOfflineDevices key={item.DeviceName} item={item} />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,

    <div className="item" data-value="4">
      <div>
        <HealthCard
          cardName="Panel Status"
          imageName={PanelStatus}
          loading={loading}
          altImageName="Panel status"
          bgColor="#776700"
          offlineCount={panelOfflineCount}
          onlineCount={panelOnlineCount}
          totalCount={parseInt(panelOfflineCount) + parseInt(panelOnlineCount)}
        />
        <div className="CardBoxWithBorder">
          <div className="CardBoxContent float-left w-100">
            <div
              className="CardBoxImage"
              style={{ backgroundColor: "#98309F" }}
            >
              <img src={SensorStatusIcon} alt="System health" />
            </div>
            <div
              className="CardBoxText"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <h4>Sensor States</h4>
                {/* <div className="StatusText">
                                                  <div className="StatusValueDiv" style={{width:"auto"}}>
                                                      {
                                                        loading 
                                                        ? (
                                                          <>
                                                          <Skeleton width={70} className="statusValueText" />
                                                          <Skeleton width={50} className="statusValueP" />
                                                          </>
                                                        )
                                                        : (<>
                                                          <p className="statusValueP">{(parseInt(systemHealthDevicesOfflineCount)) + (parseInt(systemHealthDevicesOnlineCount))}</p>                            
                                                          <p className="statusValueText">Total Devices</p>
                                                        </>)
                                                      }

                                                  </div>
                                              </div> */}
              </div>

              <div className="DonutChartWidthDiv">
                <div
                  style={{
                    width: "100%",
                    height: "125px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {loading && !onlinePercent ? null : (
                    <SensorStatus
                      isDarkTheme={isDarkTheme}
                      sensorStatus={sensorStatus}
                      sensorCount={sensorCount}
                      bgcolour={bgcolour}
                      sensorsum={sensorsum}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,

    <div className="item" data-value="5">
      <div>
        <HealthCard
          cardName="ACS Controller"
          imageName={PanelStatus}
          loading={loading}
          altImageName="Panel status"
          bgColor="#667BC6"
          offlineCount={acsControllerOfflineCount}
          onlineCount={acsControllerOnlineCount}
          totalCount={
            parseInt(acsControllerOfflineCount) +
            parseInt(acsControllerOnlineCount)
          }
        />
        <HealthCard
          cardName="ACS Reader"
          imageName={PanelStatus}
          loading={loading}
          altImageName="Panel status"
          bgColor="#FF7F3E"
          offlineCount={acsReaderOfflineCount}
          onlineCount={acsReaderOnlineCount}
          totalCount={
            parseInt(acsReaderOfflineCount) + parseInt(acsReaderOnlineCount)
          }
        />
      </div>
    </div>,
  ];

  const slidePrev = () => setActiveIndex(activeIndex - 1);
  const slideNext = () => {
    setActiveIndex(activeIndex + 1);
  };

  //#region FilterTool component props
  const filterToolDDLProps = {
    visibleDDLSingleSelect: true,
    visibleDDLMultiSelect: true,
    DDLSingleSelectProps: {
      labelledBy:
        savedFilters?.selected?.filterName !== undefined
          ? "Saved Filter: " + savedFilters?.selected?.filterName
          : "Saved Filter",
      selected: isNotNullOrEmpty(savedFilters?.selected)
        ? savedFilters?.selected
        : null,
      emptyOptionLabel: "No Saved Filters",
      handleClearSelectedClick: handleClearSelectedClick,
      clearDisabled: !isNotNullOrEmpty(savedFilters?.selected),
      options: savedFilters?.options,
      valueRenderer: null,
      itemRenderer: (item) => (
        <>
          <div
            className="SavedFilterOption"
            onClick={(e) => handleSavedFilterItemSelectClick(e, item)}
          >
            <span>{item?.filterName}</span>
            <div className="SaveFilterOtionHover">
              <button
                style={{ background: "transparent" }}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(filterActions.setSavedFilterSelected(item));
                  dispatch(modalActions.showSavedFilterDeleteModal(true));
                }}
              >
                <img src={DeleteIcon} />
              </button>
              <button
                style={{ background: "transparent" }}
                onClick={(e) => {
                  infoFilterList(item.filterId);
                  e.stopPropagation();
                  setOpenInfoFilter(true);
                  setModalFilterName(item.filterName);
                  setModalFilterId(item.filterId);
                }}
              >
                <img src={InfoIcon1} />
              </button>
            </div>
          </div>
        </>
      ),
    },
    filterList: [
      {
        labelledBy: CUSTOMER,
        options: customer?.options,
        value: customer?.selected,
        onChange: (value) => handleFilterItemChange(value, CUSTOMER),
        valueRenderer: null,
        className:
          customer?.selected.length > 0
            ? "dropdownSelectionContainer multi-select"
            : "multi-select",
      },
      {
        labelledBy: SITE,
        options: site?.options,
        value: site?.selected,
        onChange: (value) => handleFilterItemChange(value, SITE),
        valueRenderer: null,
        //disabled: customer?.selected?.length === 0
        className:
          site?.selected.length > 0
            ? "dropdownSelectionContainer multi-select"
            : "multi-select",
      },
    ],
  };
  //#endregion

  const handleToggleHMFilter = () => {
    // // call the API
    // getHealthMonitoringSiteID("194")
    //   .then((res) => {
    //     if (res.status == "success") {
    //       setSiteId(res.data);
    //       console.log("Health Monitoring siteId : ", res);
    //     }
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });

    settoggleSelection(!toggleSelection);
    if(toggleSelection){
      setSingleView(true)
    }else{
      dispatch(HMDashboardActions.setCustomerIds(""));
      dispatch(HMDashboardActions.setSiteIds(""));  
      setSingleView(false)
    }
  };

  const getSingleSiteData = (pClientId, psiteId) => {
    console.log("CUSTOMER", customerIds)
    console.log("CUSTOMER", siteIds)
    if(customerIds == ""){
      ErrorToast("Kindly select a customer.")
      return;
    }

    if(siteIds == ""){
      ErrorToast("Kindly select a site.")
      return;
    }
  
    // getHealthMonitoringSiteID(siteId)
    getHealthMonitoringSiteID(psiteId)
    .then((res) => {
        console.log("ressucecs", res);
        if (res.status == "success") {
          // CALL THE ALLWIDGETDATA AND DEVICESTATUSDETAILS4
          let tempUser = JSON.parse(localStorage.getItem("user"));
          let userUniqueID = tempUser.UserUniqueID;
          // AllWidgetData(userUniqueID, pClientId, psiteId);
          getAllWidgetData(true);
          let loggedInUser = JSON.parse(localStorage.getItem("user"));
          // getHealthMonitorData(1, loggedInUser.UserUniqueID, "", pClientId, psiteId);
          //setSiteId(res.data);
          console.log("Health Monitoring SiteId : ", res);
          setSingleView(false);
        }
      })
      .catch((err) => {
        ErrorToast("Unable to get the latest status.")
        console.error(err);
      });
  };

  return (
    <>
      <div className="MainContentMargin">
        <div className="col-lg-12 HMHeader mt-3">
          <div
            className="NameHeading1"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p className="config-tool-head" style={{ float: "left" }}>
              Health Monitoring
            </p>
            <div
              className="RefreshText"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {/* <h4>page refreshed on - {resetHmRefresh}</h4> */}
              {/* Toggle button */}
              {HM_SELECT_SITE ? (
                <div className="alertSoundText">
                  <span
                    style={{
                      margin: "0px",
                      marginRight: "10px",
                      fontSize: "12px",
                    }}
                  >
                    Select Site
                  </span>
                  <div className="togglecheckbox" style={{ paddingTop: "5px" }}>
                    <label>
                      <input type="checkbox" onChange={handleToggleHMFilter} />
                      <span />
                    </label>
                  </div>
                </div>
              ) : null}
              {toggleSelection ? (
                <div className="d-flex HMFilterDiv">
                  <FilterToolDDL {...filterToolDDLProps} />
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Apply Filter</Tooltip>}
                  >
                    <button
                      className={`IconsButtonDiv ${
                        visibleFilterBtn ? "buttonDisable" : ""
                      }`}
                      name="filterBtn"
                      disabled={visibleFilterBtn}
                      onClick={handleFilterClick}
                    >
                      <img
                        src={FilterIcon}
                        className="camera-icon-height"
                        alt="Save filter icon"
                      />
                    </button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Clear Filter</Tooltip>}
                  >
                    <button
                      className={`IconsButtonDiv ${
                        visibleClearFilterBtn ? "buttonDisable" : ""
                      }`}
                      name="clearFilterBtn"
                      disabled={visibleClearFilterBtn}
                      onClick={handleClearFilterClick}
                    >
                      <img
                        src={ClearFilter}
                        className="camera-icon-height"
                        alt="Save filter icon"
                      />
                    </button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Save Filter</Tooltip>}
                  >
                    <button
                      className={`IconsButtonDiv ${
                        visibleSaveFilterBtn ? "buttonDisable" : ""
                      }`}
                      name="saveFilterBtn"
                      disabled={visibleSaveFilterBtn}
                      onClick={() =>
                        dispatch(modalActions.showSavedFilterSaveModal(true))
                      }
                    >
                      <img
                        src={SaveFilter}
                        className="camera-icon-height"
                        alt="Save filter icon"
                      />
                    </button>
                  </OverlayTrigger>
                  {/* <FilterTool isDarkTheme={isDarkTheme} setSiteIds={setSiteIds} setCustomerIds={setCustomerIds} setResetClicked={setResetClicked} /> */}
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Refresh</Tooltip>}
                  >
                    <button
                      className="DataAccessEdit"
                      style={{ width: "50px", height: "35px", float: "right" }}
                      onClick={refreshData}
                      disabled={loading}
                    >
                      <img src={RefreshBtnIcon} alt="Refresh" />
                      {/* <span className="ViewUpdate ml-1">Update Data</span> */}
                    </button>
                  </OverlayTrigger>
                </div>
              ) : (
                <div className="d-flex SingleSiteSelectionFilter">
                  <div class="inputTypeLabelContainer">
                    <select
                      name="CountryName"
                      // {...register("ClientId")}
                      //disabled={!isInterfaceEdit}
                      class="inputType ArrowSelectClass"
                      onChange={(e) => {
                        // setcustomerId(e.target.value);
                        dispatch(HMDashboardActions.setCustomerIds(e.target.value))
                        getSiteListData(e.target.value);
                        //setInterfaceTimeZoneError('')
                      }}
                    >
                      <option key="0" disabled={false} value="0" id="0">
                        Select Customer
                      </option>
                      {customerList.map((item) => (
                        <option
                          value={item.ClientId}
                          key={item.ClientId}
                          id={item.ClientName}
                        >
                          {item.ClientName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div class="inputTypeLabelContainer">
                    <select
                      name="CountryName"
                      class="inputType ArrowSelectClass"
                      onChange={(e) => {
                        setSiteId(e.target.value)
                        dispatch(HMDashboardActions.setSiteIds(e.target.value))
                      }}
                    >
                      <option key="0" value="0" id="0" disabled={false}>
                        Select Site
                      </option>
                      {siteList &&
                        siteList.map((item) => (
                          <option
                            value={item.SiteID}
                            key={item.SiteID}
                            id={item.SiteName}
                          >
                            {item.SiteName}
                          </option>
                        ))}
                    </select>
                  </div>

                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Get Status</Tooltip>}
                  >
                    <button
                      className="DataAccessEdit"
                      style={{ width: "50px", height: "35px", float: "right" }}
                      onClick={() => getSingleSiteData(customerId,siteId)}
                    >
                      <img src={RefreshBtnIcon} alt="Refresh" />
                    </button>
                  </OverlayTrigger>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <div
          className="HeightScrollCameraGrid HeightScrollSitesGrid"
          style={{ position: "relative" }}
        >
          <div className="HMWidgetMainDiv">
            <AliceCarousel
              key={activeIndex}
              items={items}
              responsive={responsive}
              disableDotsControls
              disableButtonsControls
              activeIndex={activeIndex}
            />
            <div
              key="btns"
              className="b-refs-buttons"
              style={{ textAlign: "center", position: "relative", top: "1%" }}
            >
              <button
                disabled={activeIndex === 0}
                className="CarouselPrev"
                onClick={slidePrev}
              >
                <img
                  style={{ height: "13px", width: "15px" }}
                  alt="Left"
                  src={ArrowLeft}
                />
              </button>
              <button
                disabled={activeIndex === 2}
                className="CarouselNext"
                onClick={slideNext}
              >
                <img
                  style={{ height: "13px", width: "15px" }}
                  alt="Right"
                  src={ArrowRight}
                />
              </button>
            </div>
          </div>
          <HealthTable
            tableLoading={tableLoading}
            setTableLoading={setTableLoading}
            refreshButton={refreshButton}
            setRefreshButton={setRefreshButton}
            isDarkTheme={isDarkTheme}
            customerIds={customerIds}
            siteIds={siteIds}
          />
        </div>
        {show ? (
          <HealthEvents
            show={show}
            setShow={setShow}
            isDarkTheme={isDarkTheme}
            customerIds={customerIds}
            siteIds={siteIds}
          />
        ) : null} */}
        
        {!singleView ? (
          <div
            className="HeightScrollCameraGrid HeightScrollSitesGrid"
            style={{ position: "relative" }}
          >
            <div className="HMWidgetMainDiv">
              <AliceCarousel
                key={activeIndex}
                items={items}
                responsive={responsive}
                disableDotsControls
                disableButtonsControls
                activeIndex={activeIndex}
              />
              <div
                key="btns"
                className="b-refs-buttons"
                style={{ textAlign: "center", position: "relative", top: "1%" }}
              >
                <button
                  disabled={activeIndex === 0}
                  className="CarouselPrev"
                  onClick={slidePrev}
                >
                  <img
                    style={{ height: "13px", width: "15px" }}
                    alt="Left"
                    src={ArrowLeft}
                  />
                </button>
                <button
                  disabled={activeIndex === 2}
                  className="CarouselNext"
                  onClick={slideNext}
                >
                  <img
                    style={{ height: "13px", width: "15px" }}
                    alt="Right"
                    src={ArrowRight}
                  />
                </button>
              </div>
            </div>
            <HealthTable
              tableLoading={tableLoading}
              setTableLoading={setTableLoading}
              refreshButton={refreshButton}
              setRefreshButton={setRefreshButton}
              isDarkTheme={isDarkTheme}
              customerIds={customerIds}
              siteIds={siteIds}
            />
          </div>
        ) : (
          <div>
            <AlertTemplateMessage
              message={"Select a site to get its status."}
            />
          </div>
        )}

        {show ? (
          <HealthEvents
            show={show}
            setShow={setShow}
            isDarkTheme={isDarkTheme}
            customerIds={customerIds}
            siteIds={siteIds}
          />
        ) : null}

        {/* Modal popup for save filter */}
        <Modal
          size="lg"
          show={showSavedFilterSaveModal}
          onHide={() => dispatch(modalActions.showSavedFilterSaveModal(false))}
          dialogClassName="modal-90w"
          backdrop="static"
          className="saveCameraFilterModal"
        >
          <>
            <Modal.Header>
              <Modal.Title id="example-custom-modal-styling-title">
                Save Filter
              </Modal.Title>
              <img
                src={RemoveIcon}
                alt="Remove"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  dispatch(modalActions.showSavedFilterSaveModal(false))
                }
              />
            </Modal.Header>

            <Modal.Body>
              <div className="inputMainContainer w40dot75rem w-100">
                <div className="inputTypeLabelContainer">
                  <label className="formFieldLabels">Filter Name</label>
                  <input
                    style={{ width: "40vw" }}
                    type="text"
                    className="inputType"
                    value={filterName}
                    placeholder="Ex. Filter for xyz company with 10 sites"
                    // {...register("AddCustomerName")}
                    onChange={handleFilterNameInputChange}
                  />
                  <p className="Error_P" style={{ float: "left" }}>
                    <span>{}</span>
                  </p>
                </div>
              </div>
              <button
                className="DataAccessEdit mt-3"
                style={{ width: "100%" }}
                onClick={handleSaveFilterModalClick}
              >
                <span className="ViewUpdate">Save</span>
              </button>
            </Modal.Body>
          </>
        </Modal>

        {/* Modal popup for delete of scheduler */}
        <Modal
          size="lg"
          show={showSavedFilterDeleteModal}
          onHide={() =>
            dispatch(modalActions.showSavedFilterDeleteModal(false))
          }
          className="deleteModalMainClass"
          backdrop="static"
          // centered
        >
          <Modal.Body>
            <div className="ModalBodyDiv">
              <div style={{ padding: "37px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <img
                    className="informationIcon infoorangeicon"
                    src={WarningDelete}
                    alt="Info"
                  />
                  <div style={{ width: "100%" }}>
                    <h3 className="No-Data-Access-Group">
                      Delete Saved Filter!
                    </h3>
                  </div>
                </div>
                <p className="DeleteText mb-4">
                  Are you sure you want to delete the saved filter
                  <br />
                  <span className="font-weight-bold">
                    {savedFilters?.selected?.filterName}
                  </span>
                </p>
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    className="DataAccessEdit ml-0"
                    style={{ width: "45%", backgroundColor: "#FB3030" }}
                    onClick={handleDeleteSavedFilterModalYesClick}
                  >
                    <span className="ViewUpdate">Yes</span>
                  </button>
                  <button
                    onClick={handleDeleteSavedFilterNoClick}
                    className="DataAccessEdit ml-0"
                    style={{ width: "45%" }}
                  >
                    <span className="ViewUpdate">No</span>
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Modal popup for info saved filter */}
        <Modal
          size="xl"
          show={openInfoFilter}
          onHide={() => setOpenInfoFilter(false)}
          className="deleteModalMainClass"
          backdrop="static"
          // centered
          scrollable
        >
          <Modal.Header>
            <Modal.Title id="example-custom-modal-styling-title">
              {modalFilterName} - info
            </Modal.Title>
            <img
              src={RemoveIcon}
              alt="Remove"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenInfoFilter(false);
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <div id="mainContainer" className="main-container">
              {customerMasterArr?.length > 0 ? (
                <div className="SiteLeftContainer ml-2 mr-2">
                  <div className="infoFilterHeader">
                    <p>Customer</p>
                  </div>
                  <div className="m-2 HeaderSiteList">
                    <input
                      id="Search_txt_site"
                      className="SearchViewInput1"
                      type="text"
                      placeholder="Search Customer"
                      value={customerSearch}
                      onChange={(e) => searchCustomerHandler(e)}
                    />
                    <div className="SearchTextDivConfigTool">
                      {isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          src={SearchIcon}
                          alt="search icon"
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          src={SearchIconWhite}
                          alt="search icon"
                          height="15"
                        />
                      )}
                    </div>
                  </div>
                  <div className="SaveFilterInfoList">
                    {customerInfoArr && customerInfoArr?.length > 0 ? (
                      <div>
                        {customerInfoArr.map((item) => (
                          <p className="ListofText">{item.Values}</p>
                        ))}
                      </div>
                    ) : (
                      <div className="no-data-found">
                        <p>No Customer Found.</p>
                      </div>
                    )}
                    {!customerInfoArr && (
                      <div className="no-data-found">
                        No Customer Available.
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
              {siteMasterArr?.length > 0 ? (
                <div className="SiteLeftContainer ml-2 mr-2">
                  <div className="infoFilterHeader">
                    <p>Site</p>
                  </div>
                  <div className="m-2 HeaderSiteList">
                    <input
                      id="Search_txt_site"
                      className="SearchViewInput1"
                      type="text"
                      placeholder="Search Site"
                      value={siteSearch}
                      onChange={(e) => searchSiteHandler(e)}
                    />
                    <div className="SearchTextDivConfigTool">
                      {isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          src={SearchIcon}
                          alt="search icon"
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          src={SearchIconWhite}
                          alt="search icon"
                          height="15"
                        />
                      )}
                    </div>
                  </div>
                  <div className="SaveFilterInfoList">
                    {siteInfoArr && siteInfoArr?.length > 0 ? (
                      <div>
                        {siteInfoArr.map((item) => (
                          <p className="ListofText">{item.Values}</p>
                        ))}
                      </div>
                    ) : (
                      <div className="no-data-found">
                        <p>No Site Found.</p>
                      </div>
                    )}
                    {!siteInfoArr && (
                      <div className="no-data-found">No Sites Available.</div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default HealthMonitoring;

import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { ZeroCountAlert } from "./ZeroCountAlert";
import InfiniteScroll from "react-infinite-scroll-component";
import { DatePicker, DateRangePicker } from "rsuite";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  AlertGenericImg,
  AlertGenericImg1,
  BackToTopBlue,
  ClearFilter,
  Cross,
  DeleteIcon,
  DownArrow,
  FilterClearIcon,
  FilterIcon,
  InfoIcon1,
  RemoveIcon,
  SaveFilter,
  Search,
  SearchIcon,
  SearchIconWhite,
  WarningDelete,
} from "../../assets/images";
import { ALERT_INTERVAL_TIMER, MULTI_TIME_ZONE } from "../../config";
import { ConvertDate, multiTimeZoneHelper } from "../../utils/utils";
import { differenceInDays, subDays, subMonths } from 'date-fns';
import { ErrorToast } from "../../CustomHooks/ErrorToast";
import FilterToolDDLTemp from "../AlertViewNew/FilterToolDDLTemp";
import { GetmultiTimeZone } from "../../services/multiTimeZoneService";
import { deviceconfigcrud } from "../../services/configurationServices";
import { DeleteFilter, FilterSaved, GetAlertCount, getCustomerList, GetFilter, getFilterAlertTypeList, getInfoList, getSeverity, getSiteList } from "../../services/alertGridServices";
import { getAlertList } from "../../services";
import AlertList from "./AlertList";
import { WarningToast } from '../../CustomHooks/WarningToast';
import Swal from "sweetalert2";
import { FilterAlert } from "../../services/alertAuthService";
import { SuccessToast } from "../../CustomHooks/SuccessToast";

const TODAY = "TD";
const YESTERDAY = "YD";
const SEVENDAY = "7D";
const ONEMONTH = "1M";
const THREEMONTH = "3M";
const NONE = "NONE";

const tempFilterList = [
  {
    "FilterId": 14,
    "FilterName": "FilterAlertOne",
    "FilterValues": "Status:1,2;AlertSeverity:;AlertType:;DeviceType:1;Site:444;Client:24"
  }
]

export default function AlertFilterListView({ isDarkTheme, onAddAlertChat, alertChildWindow }) {
  const options = [
    { label: "Grapes", value: "grapes" },
    { label: "Mango", value: "mango" },
    { label: "Strawberry", value: "strawberry" },
  ];

  const [selected, setSelected] = useState([]);
  const [isimage, setisimage] = useState(false);
  const [isOpenmodal, setisOpenmodal] = useState(false);
  const [snapshoturl, setsnapshoturl] = useState([]);
  const [scrollStart, setScrollStart] = useState(false);
  const [activeTab, setActiveTab] = useState(TODAY);
  const [show, setShow] = useState(false);
  const [openInfoFilter, setOpenInfoFilter] = useState(false);
  const [openDeleteFilter, setOpenDeleteFilter] = useState(false);
  const [data, setData] = useState([]);
  const [singleAlert, setSingleAlert] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
  const [alertTypeOptions, setAlertTypeOptions] = useState([]);
  const [severityOptions, setSeverityOptions] = useState([]);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [deviceNameOptions, setDeviceNameOptions] = useState([])
  const [statusOptions, setStatusOptions] = useState([
    {value: "1,2", label: "Open"},
    {value: "3", label: "Closed"},
  ])
  const [customerSelected, setCustomerSelected] = useState([]);
  const [siteSelected, setSiteSelected] = useState([]);
  const [deviceTypeSelected, setDeviceTypeSelected] = useState([]);
  const [alertTypeSelected, setAlertTypeSelected] = useState([]);
  const [severitySelected, setSeveritySelected] = useState([]);
  const [timezoneSelected, setTimezoneSelected] = useState([]);
  const [deviceNameSelected, setDeviceNameSelected] = useState([]);
  const [statusSelected, setStatusSelected] = useState([]);
  const [filterName, setFilterName] = useState("")
  const [selectedtablist, setselectedtablist] = useState([]);
  const [displayStartDate, setDisplayStartDate] = useState("");
  const [displayEndDate, setDisplayEndDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [inputDate, setInputDate] = useState([]);
  const [initialDate, setInitialDate] = useState([]);
  const [alertIdSearch, setAlertIdSearch] = useState("");
  const [count, setCount] = useState(0);
  const [resetInfiniteScroll, setResetInfiniteScroll] = useState(0);

  const [customerString, setCustomerString] = useState("")
  const [siteString, setSiteString] = useState("")
  const [deviceTypeString, setDeviceTypeString] = useState("")
  const [alertTypeString, setAlertTypeString] = useState("")
  const [severityString, setSeverityString] = useState("")
  const [statusString, setStatusString] = useState("1,2")
  const [filterList, setFilterList] = useState([]);
  const [stopPolling, setStopPolling] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState("Open");
  const [selectedFilterName, setSelectedFilterName] = useState("")
  const [modalFilterName, setModalFilterName] = useState("")
  const [modalFilterId, setModalFilterId] = useState(0)
  const[alertCount,setAlertCount] = useState(0)
  const statusRef = useRef("1,2");
  const [filterApplied, setFilterApplied] = useState(false);
  const [customerMasterArr,setCustomerMasterArr]=useState([])
  const [customerInfoArr,setCustomerInfoArr]=useState([])
  const [siteMasterArr,setSiteMasterArr]=useState([])
  const [siteInfoArr,setSiteInfoArr]=useState([])
  const [deviceTypeMasterArr,setDeviceTypeMasterArr]=useState([])
  const [devieceTypeInfoArr,setDeviceTypeInfoArr]=useState([])
  const [alertTypeMasterArr,setalertTypeMasterArr]=useState([])
  const [alertTypeInfoArr,setAlertTypeInfoArr]=useState([])
  const [alertSeverityMasterArr,setAlertSeverityMasterArr]=useState([])
  const [alertSeverityInfoArr,setAlertSeverityInfoArr]=useState([])
  const [customerSearch,setCustomerSearch]=useState("")
  const [siteSearch,setSiteSearch]=useState("")
  const [deviceTypeSearch,setDeviceTypeSearch]=useState("")
  const [alertTypeSearch,setAlertTypeSearch]=useState("")
  const [alertSeveritySearch,setAlertSeveritySearch]=useState("")
  const CLIENT = "Client"
  const SITE = "Site"
  const DEVICE_TYPE = "Device Type"
  const ALERT_TYPE = "Alert Type"
  const ALERT_SEVERITY = "Alert Severity"

  // const [containerWidth, setContainerWidth] = useState(0);

  //#region useEffect start
  useEffect(() => {
    // API Call for customer list
    getCustomerList()
      .then((res) => {
        console.log("customer", res);
        if (res.Status == "success") {
          let arr = res.Data.map((item) => ({
            value: item.ClientId,
            label: item.ClientName
          }))
          setCustomerOptions(arr);
        }
      })
      .catch(err => console.log(err))

    // API Call for Severity List
    getSeverity()
      .then((res) => {
        console.log("severity", res);
        if (res.Status == "success") {
          let arr = res.Data.map((item) => ({
            value: item.SeverityId,
            label: item.SeverityTitle
          }))
          setSeverityOptions(arr);
        }
      })
      .catch(err => console.log(err))

    // API Call for Device Type List
    getidsmasterslist();
    getTimeZoneList();
    getFilterList();
    getAlertType();

    let start = getFirstDate();
    let end = getSecondDate();
    let startDate = ConvertDate(new Date(start.setHours(0, 0, 0, 0)));
    setFromDate(startDate);
    let endCurrDate = ConvertDate(end);
    setEndDate(endCurrDate)
    setInputDate([])
    displayDateSet(startDate, endCurrDate);
    getAlertData("", "", "", "", "", "", "", startDate, endCurrDate);
  }, [])

  useEffect(() => {
    let timer = setInterval(() => {
      if(!stopPolling){
        console.log("SStorage", sessionStorage.getItem('alertWindowKey'))
        if(sessionStorage.getItem('alertWindowKey') == "alertWindow"){
          return
        }
        let end;
        let endCurrDate;
        if(activeTab == YESTERDAY || activeTab == NONE){
          endCurrDate = endDate;
        }else{
          end = getSecondDate();
          endCurrDate = ConvertDate(end);  
        }
        getAlertData(customerString, siteString, deviceTypeString, alertTypeString, "", severityString, statusString, fromDate, endCurrDate);
      }
    }, ALERT_INTERVAL_TIMER);

    return () => {
      clearInterval(timer);
    }
  }, [customerString, siteString, deviceTypeString, alertTypeString, severityString, statusString, fromDate, endDate, stopPolling, activeTab]);

  useEffect(() => {
    if(customerSelected.length > 0){
      // Call API for getting sitedata.
      let customerIds = customerSelected.map((item) => item.value).join(',');
      console.log('customerIds', customerIds);
      getSiteList(customerIds)
        .then((res) => {
          if(res.Status == "success"){
            let arr = res.Data.map((item) => ({
              value: item.SiteID,
              label: item.SiteName
            }))
            setSiteOptions(arr);
          }
        })
        .catch(err => console.log(err));
    }else{
      setSiteOptions([]);
      setSiteSelected([]);
    }
  }, [customerSelected])

  useEffect(() => {
    if(deviceTypeSelected.length > 0){
      let alertTypeIds = deviceTypeSelected.map((item) => item.value).join(',');
      console.log('checkdata', alertTypeIds);

    }else{
      // setAlertTypeOptions([]);
    }
  }, [deviceTypeSelected]);

  useEffect(() => {
    if(alertIdSearch == ""){
      setSingleAlert([]);
      setStopPolling(false);
    }
  }, [alertIdSearch])

  useEffect(() => {
    if(selectedFilter != 0){
      let selected = filterList.find((item) => item.FilterId == selectedFilter);
      console.log("selected", selected);
      if(selected != null){
        extractIds(selected.FilterValues);

      }
    }
  }, [selectedFilter]);

  useEffect(() => {
    if(customerSelected.length > 0 || siteSelected.length > 0 || deviceTypeSelected.length > 0 || alertTypeSelected.length > 0 || severitySelected.length > 0){
      setDisableSaveBtn(true);
      
      if(selectedFilterName != ""){
        clearSelectedFilter();
      }else{
        setSelectedFilterName("");
      }
    }else{
      setDisableSaveBtn(false);
    }

    if(customerSelected.length == 0 && siteSelected.length == 0 && deviceTypeSelected.length == 0 && alertTypeSelected.length == 0 && severitySelected == 0 && filterApplied){
      setFilterApplied(false);
      setCustomerString("");
      setSiteString("");
      setDeviceTypeString("");
      setAlertTypeString("");
      setSeverityString("");
      setStatusString("1,2");
      statusRef.current = "1,2"
      let end;
    let endCurrDate;
    if(activeTab == YESTERDAY || activeTab == NONE){
      endCurrDate = endDate;
    }else{
      end = getSecondDate();
      endCurrDate = ConvertDate(end);  
    }
      getAlertData("", "", "", "", "", "", "", fromDate, endCurrDate);
    }

  }, [customerSelected, siteSelected, deviceTypeSelected, alertTypeSelected, severitySelected]);

  // useEffect(() => {
  //   const adjustContainerWidth = () => {
  //     const mainContainer = document.getElementById('mainContainer');
  //     const contents = mainContainer.children;
  //     let totalWidth = 0;

  //     for (let content of contents) {
  //       totalWidth += content.offsetWidth + 20;
  //     }

  //     setContainerWidth(Math.min(totalWidth, 1410));
  //   };

  //   // adjustContainerWidth();
  //   window.addEventListener('resize', adjustContainerWidth);

  //   return () => window.removeEventListener('resize', adjustContainerWidth);
  // }, []);

  //#endregion

  const getFilterList = () => {
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    GetFilter("all alerts", loggedInUser?.UserUniqueID)
      .then((res) => {
        if(res.Status == "success"){
          console.log("GetFilter", res.Data);
          setFilterList(res.Data);
        }else{
          setFilterList([]); 
        }
      }).catch(err => console.log(err));

  }

  const getAlertData = async (customerIds = "", siteIds = "", deviceTypeIds = "", alertTypeIds = "", deviceNameIds = "", severityIds = "", statusIds = "", fromDate="", endDate="", showToast=false) => {
    try {
      // getAlertCount()
      let loggedInUser = JSON.parse(localStorage.getItem('user'))
      var obj = {
          "action": "getAlertListNew",
          "severity": "all",
          "userUniqueId": loggedInUser?.UserUniqueID,
          "startIndex": 0,
          "numberOfElements": 50,
          "authCode": localStorage.getItem('authCode'),
          "customerIds": customerIds,
          "siteIds": siteIds,
          "deviceTypeIds": deviceTypeIds,
          "alertTypeIds":alertTypeIds,
          "deviceNameIds": deviceNameIds,
          "severityIds": severityIds,
          "statusIds": statusIds,
          "fromDate": fromDate,
          "endDate": endDate,
          "loadBalance": loggedInUser?.ApplicationFlag || "0"
      }
      console.log("obj", obj)
      await getAlertList(obj).then((resp) => {
          if (resp.status == "Ok") {
              if (resp.message) {
                console.log("getAlertData", resp.message);
                let tempArr = resp.message.map((item) => {
                  return {
                    ...item,
                    isSelected: false
                  }
                })
                setData(tempArr);
                setAlertCount(resp.alertCount)
                if(showToast){
                  SuccessToast("Filter applied successfully.")
                  console.log("FILTER", loggedInUser?.ApplicationFlag);
                  console.log("FILTER", loggedInUser?.UserRoleID)
                  if(loggedInUser?.ApplicationFlag == "1" && loggedInUser?.UserRoleID == 3){
                    WarningToast("Since load balancing is enabled, applying filter is not recommended.")
                  }
                }
              }
          }
      })
  } catch (error) {
      console.error(error)
  }
  }

  const getidsmasterslist = async () => {
    try {
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        var obj = {
            "action": "getDevicesMasterList",
            "userUniqueId": loggedInUser.UserUniqueID || loggedInUser.userUniqueID
        }
        await deviceconfigcrud(obj).then((resp) => {
            if (resp.status == "Ok") {
                if (resp.message != null) {
                  console.log("deviceTypeList", resp.message.deviceTypeList);
                  let arr = resp.message.deviceTypeList.map((item) => ({
                    value: item.deviceTypeID,
                    label: item.deviceType
                  }))
                  setDeviceTypeOptions(arr);

                  // let arrTwo = resp.message.alertCodeList.map((item) => ({
                  //   value: item.alertCodeId,
                  //   label: item.alertAssociated
                  // }))
                  // setAlertTypeOptions(arrTwo);

                }
            }
        })
    } catch (error) {
        console.log(error)
    }
  }

  const getAlertType = () => {
    getFilterAlertTypeList()
      .then((res) => {
        if(res.Status == "success"){
          let arrTwo = res.Data.map((item) => ({
            value: item.AlertTypeId,
            label: item.AlertTypeName
          }))
          setAlertTypeOptions(arrTwo);
        }
      })
      .catch(err => console.log(err));
  }


  const getTimeZoneList = () => {
    try {
        GetmultiTimeZone().then((res) => {
            //debugger
            console.log("getTimeZoneList", res)
            if (res != undefined) {
              if (res.Status == "success") {
                let arr = res.Data.map((item) => ({
                  value: item.TimeZoneId,
                  label: item.Abbreviation
                }))
                setTimezoneOptions(arr)
              } else {
                // setTimeZone(null)
              }
            }
          })
    } catch (error) {
        console.error(error)
    }
}

  const ClosePreview = () => {
    setisOpenmodal(false);
  };
  const handleChange = (isimg, snapurl) => {
    setisimage(isimg);
    setisOpenmodal(true);
    setsnapshoturl(snapurl);
  };

  const handleScroll = (e) => {
    if (e.currentTarget.scrollTop === 0) {
        // alert("on top")
        // setAlertSearch(false);
        let c = resetInfiniteScroll + 1;
        setResetInfiniteScroll(c);
        setStopPolling(false);
    } else {
        // setAlertSearch(true);
     }
    if(e.currentTarget.scrollTop > 300){
        setScrollStart(true);
    }else{
        setScrollStart(false);
    }
}

  const scrollingDiv = document.getElementById("scrollableDivOne");

  const scrollToTop = () => {
    scrollingDiv.scrollTo({ top: 0, behavior: "smooth" });
  };
  const getFirstDate = () => {
    let d;
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    if (MULTI_TIME_ZONE) {
      d = multiTimeZoneHelper(loggedInUser.OffsetMinutes)
    } else {
      d = new Date();
    }
    return d;
  }
  const getSecondDate = () => {
    let e;
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    if (MULTI_TIME_ZONE) {
      e = multiTimeZoneHelper(loggedInUser.OffsetMinutes)
    } else {
      e = new Date();
    }
    return e;
  }

  const displayDateSet = (startDate, endCurrDate) => {
    let one = startDate.split(" ");
    let tempOne = `${one[0].split("-")[2]}-${one[0].split("-")[1]}-${one[0].split("-")[0]}`;
    setDisplayStartDate(`${tempOne} ${one[1]}`);
    let two = endCurrDate.split(" ");
    let tempTwo = `${two[0].split("-")[2]}-${two[0].split("-")[1]}-${two[0].split("-")[0]}`;
    setDisplayEndDate(`${tempTwo} ${two[1]}`);
  }
  const today = () => {
    let start = getFirstDate();
    let end = getSecondDate();
    let startDate = ConvertDate(new Date(start.setHours(0, 0, 0, 0)));
    setFromDate(startDate);
    let endCurrDate = ConvertDate(end);
    setEndDate(endCurrDate)
    setInputDate([])
    displayDateSet(startDate, endCurrDate);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
   // getEventData(loggedInUser.UserUniqueID, startDate, endCurrDate, 1, searchString, customerIds, siteIds);
    //setCurrentPageNo(1)
    setActiveTab(TODAY)
    applyFilter(startDate, endCurrDate)
  };
  const yesterday = () => {
    let start = getFirstDate();
    let end = getSecondDate();
    let startDate = ConvertDate(subDays(new Date(start.setHours(0, 0, 0, 0)), 1));
    console.log("yesterday", startDate)
    setFromDate(startDate);
    let endCurrDate = ConvertDate(subDays(new Date(end.setHours(24, 0, 0, 0)), 1));
    console.log("yesterday", endCurrDate)
    setEndDate(endCurrDate)
    setInputDate([])
    displayDateSet(startDate, endCurrDate);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    //getEventData(loggedInUser.UserUniqueID, startDate, endCurrDate, 1, searchString, customerIds, siteIds);
    //setCurrentPageNo(1)
    setActiveTab(YESTERDAY);
    applyFilter(startDate, endCurrDate)
  };
  const sevenDay = () => {
    let start = getFirstDate();
    let end = getSecondDate();
    let startDate = ConvertDate(subDays(new Date(start.setHours(0, 0, 0, 0)), 6));
    setFromDate(startDate);
    let endCurrDate = ConvertDate(end);
    setEndDate(endCurrDate)
    setInputDate([])
    displayDateSet(startDate, endCurrDate);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    //getEventData(loggedInUser.UserUniqueID, startDate, endCurrDate, 1, searchString, customerIds, siteIds);
    //setCurrentPageNo(1)
    setActiveTab(SEVENDAY)
    applyFilter(startDate, endCurrDate)
  };
  const oneMonth = () => {
    let start = getFirstDate();
    let end = getSecondDate();
    let startDate = ConvertDate(subMonths(new Date(start.setHours(0, 0, 0, 0)), 1));
    setFromDate(startDate);
    let endCurrDate = ConvertDate(end);
    setEndDate(endCurrDate)
    setInputDate([])
    displayDateSet(startDate, endCurrDate);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    //getEventData(loggedInUser.UserUniqueID, startDate, endCurrDate, 1, searchString, customerIds, siteIds);
    //setCurrentPageNo(1)
    setActiveTab(ONEMONTH);
    applyFilter(startDate, endCurrDate)
  };
  const onHandleEventDateTimeOkClick = (e) => {
    console.log("onHandleEventDateTimeOkClick", e)
    const [startDate, endDate] = [e[0], e[1]];
    if (startDate && endDate) {
      const daysDifference = differenceInDays(endDate, startDate);
      if (daysDifference > 30) {
        ErrorToast('Maximum Date range cannot exceeded 30 days.');
        return;
      }
    }
    let sDate = ConvertDate(e[0]);
    setFromDate(sDate);
    let eDate = ConvertDate(e[1]);
    setEndDate(eDate);
    setInputDate([e[0], e[1]])
    displayDateSet(sDate, eDate);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    // getEventData(loggedInUser.UserUniqueID, sDate, eDate, 1, searchString, customerIds, siteIds);
    // setCurrentPageNo(1)
    setActiveTab(NONE);
    applyFilter(sDate, eDate)
  }

  const filterToolDDLProps = {
    visibleSavedfilter: false,
    savedFilterProps: {
      labelledBy: "Saved Filter",
      options: options,
      value: [],
      onChange: (value) => setSelected(value),
      valueRenderer: null
    },
    filterList: [
      {
        labelledBy: "Customer",
        options: customerOptions,
        value: customerSelected,
        onChange: (value) => setCustomerSelected(value),
        // onChange: (value) => dispatch(cameraActions?.setCustomerSelected(value)),
        valueRenderer: null,
        className: customerSelected.length > 0 ? "dropdownSelectionContainer multi-select" : "multi-select",
      },
      {
        labelledBy: "Site",
        options: siteOptions,
        value: siteSelected,
        onChange: (value) => setSiteSelected(value),
        valueRenderer: null,
        className: siteSelected.length > 0 ? "dropdownSelectionContainer multi-select" : "multi-select",
      },
      {
        labelledBy: "Device Type",
        options: deviceTypeOptions,
        value: deviceTypeSelected,
        onChange: (value) => setDeviceTypeSelected(value),
        valueRenderer: null,
        className: deviceTypeSelected.length > 0 ? "dropdownSelectionContainer multi-select" : "multi-select",
      },
      {
        labelledBy: "Alert Type",
        options: alertTypeOptions,
        value: alertTypeSelected,
        onChange: (value) => setAlertTypeSelected(value),
        valueRenderer: null,
        className: alertTypeSelected.length > 0 ? "dropdownSelectionContainer multi-select" : "multi-select",
      },
      // {
      //   labelledBy: "Device Name",
      //   options: deviceNameOptions,
      //   value: deviceNameSelected,
      //   onChange: (value) => setDeviceNameSelected(value),
      //   valueRenderer: null
      // },
      {
        labelledBy: "Alert Severity",
        options: severityOptions,
        value: severitySelected,
        onChange: (value) => setSeveritySelected(value),
        valueRenderer: null,
        className: severitySelected.length > 0 ? "dropdownSelectionContainer multi-select" : "multi-select",
      },
      // {
      //   labelledBy: "Status",
      //   options: statusOptions,
      //   value: statusSelected,
      //   onChange: (value) => setStatusSelected(value),
      //   valueRenderer: null
      // },
      // {
      //   labelledBy: "Timezone",
      //   options: timezoneOptions,
      //   value: timezoneSelected,
      //   onChange: (value) => setTimezoneSelected(value),
      //   valueRenderer: null
      // },
    ]
  }

  const clearFilterList = () => {
    setTimezoneSelected([]);
    setSeveritySelected([]);
    setDeviceTypeSelected([]);
    setAlertTypeSelected([]);
    setSiteSelected([]);
    setCustomerSelected([]);
    setStatusSelected([]);
    setDeviceNameSelected([]);
    statusRef.current = "1,2"
    setSelectedStatus("Open")
    clearSelectedFilter();
    // setCustomerString("");
    // setSiteString("");
    // setDeviceTypeString("");
    // setAlertTypeString("");
    // setSeverityString("");
    // setStatusString("1,2");
    // getAlertData("", "", "", "", "", "", "", fromDate, endDate);
  }

  const clearSelectedFilter = () => {
    setCustomerString("");
    setSiteString("");
    setDeviceTypeString("");
    setAlertTypeString("");
    setSeverityString("");
    setStatusString("1,2");
    setSelectedFilterName("")
    setSelectedFilter(0)
    statusRef.current = "1,2"
    let end;
    let endCurrDate;
    if(activeTab == YESTERDAY || activeTab == NONE){
      endCurrDate = endDate;
    }else{
      end = getSecondDate();
      endCurrDate = ConvertDate(end);  
    }
    getAlertData("", "", "", "", "", "", "", fromDate, endCurrDate);
  }

  const applyFilter = (startDate, endDate) => {
    // let customerIds = customerSelected.map((i) => i.value).join(",");
    // let siteIds = siteSelected.map((i) => i.value).join(",");
    // let deviceTypeIds = deviceTypeSelected.map((i) => i.value).join(",");
    // let alertTypeIds = alertTypeSelected.map((i) => i.value).join(",");
    // let deviceNameIds = deviceNameSelected.map((i) => i.value).join(",");
    // let severityIds = severitySelected.map((i) => i.value).join(",");
    // let statusIds = statusSelected.map((i) => i.value).join(",");
    // getAlertData(customerIds, siteIds, deviceTypeIds, alertTypeIds, deviceNameIds, severityIds, statusIds, startDate, endDate);
    console.log("applyFilter", customerString, siteString, deviceTypeString, alertTypeString, "", severityString, statusString, startDate, endDate);
    getAlertData(customerString, siteString, deviceTypeString, alertTypeString, "", severityString, statusString, startDate, endDate);
  }

  const applyFilterOnClick = () => {
    let customerIds = customerSelected.map((i) => i.value).join(",");
    setCustomerString(customerIds);
    let siteIds = siteSelected.map((i) => i.value).join(",");
    setSiteString(siteIds)
    let deviceTypeIds = deviceTypeSelected.map((i) => i.value).join(",");
    setDeviceTypeString(deviceTypeIds)
    let alertTypeIds = alertTypeSelected.map((i) => i.value).join(",");
    setAlertTypeString(alertTypeIds)
    let deviceNameIds = deviceNameSelected.map((i) => i.value).join(",");
    let severityIds = severitySelected.map((i) => i.value).join(",");
    setSeverityString(severityIds);
    // let statusIds = statusString;
    let statusIds = statusRef.current;
    setStatusString(statusIds);
    setFilterApplied(true);
    getAlertData(customerIds, siteIds, deviceTypeIds, alertTypeIds, deviceNameIds, severityIds, statusIds, fromDate, endDate, true);
  }

  const saveFilter = () => {
    if(filterName == ""){
      ErrorToast("Kindly enter a filter name")
      return
    }

    let pType = "all alerts";
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    if(customerSelected.length == 0 && siteSelected.length == 0 && deviceTypeSelected.length == 0 && alertTypeSelected.length == 0 && severitySelected.length == 0){
      ErrorToast("Kindly select at least one filter")
      return;
    }
    let clientIds = customerSelected.map((i) => i.value).join(",");
    let siteIds = siteSelected.map((i) => i.value).join(",");
    let deviceTypeIds = deviceTypeSelected.map((i) => i.value).join(",");
    let alertTypeIds = alertTypeSelected.map((i) => i.value).join(",");
    let severityIds = severitySelected.map((i) => i.value).join(",");
    let statusIds = statusString;
    const fileterTextstring = `clientIds:${clientIds};siteIds:${siteIds};deviceTypeIds:${deviceTypeIds};alertTypeIds:${alertTypeIds};alertSeverityIds:${severityIds};statusIds:${statusIds}`;
    FilterSaved(filterName,pType,loggedInUser.UserUniqueID,fileterTextstring).then((resp)=>{
      console.log("FilterSaved",  resp);
      if (resp.Status == "success"){
        SuccessToast(resp.Message)
        setShow(false);
        setFilterName("");
        getFilterList();
      }else{
        ErrorToast(resp.Message)
      }
    }).catch(err => console.log(err));
  }

  const searchAlert = () => {
    var alertid = alertIdSearch.trim()
    if(alertid == ""){
      Swal.fire({ text: 'Please enter alert id!' })
      // alert("Please enter alert id!")
      return
    }
    let LoggedInUser = JSON.parse(localStorage.getItem('user'));
    let obj = {
      "userId": LoggedInUser.UserID,
      "searchInput": alertid
    }
    FilterAlert(obj)
      .then((res) => {
        if(res.status == "Ok"){
          let lst = [];
          if(res.message.status == "Closed"){
            lst.push(res.message)
            setSingleAlert(lst)
            setStopPolling(true);
          }else if(res.message.status != "Closed"){
            lst.push(res.message)
            setSingleAlert(lst)
            setStopPolling(true);
          }else{
            Swal.fire({text: "Alert Not Found"})  
          }
        }else{
          Swal.fire({text: "Alert Not Found"})
        }
      })
  }

  const fetchMoreData = () => {
    let set = 50;
    setStopPolling(true);
    sortingList(data, set)
  }

  const sortingList = (tempList, index) => {
    var currentindex = index
    console.log("getAlertDataScroll", tempList)
    if (tempList.length < currentindex) {
        return;
        currentindex = tempList.length - 1
    } else {
        currentindex = tempList.length
    }
    console.log("getAlertDataScroll", currentindex)

    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    // let customerIds = customerSelected.map((i) => i.value).join(",");
    // let siteIds = siteSelected.map((i) => i.value).join(",");
    // let deviceTypeIds = deviceTypeSelected.map((i) => i.value).join(",");
    // let alertTypeIds = alertTypeSelected.map((i) => i.value).join(",");
    // let deviceNameIds = deviceNameSelected.map((i) => i.value).join(",");
    // let severityIds = severitySelected.map((i) => i.value).join(",");
    // let statusIds = statusSelected.map((i) => i.value).join(",");
    var obj = {
        "action": "getAlertListNew",
        "severity": "all",
        "userUniqueId": loggedInUser?.UserUniqueID,
        "startIndex": currentindex,
        "numberOfElements": index,
        "authCode": localStorage.getItem('authCode'),
        "customerIds": customerString,
        "siteIds": siteString,
        "deviceTypeIds": deviceTypeString,
        "alertTypeIds":alertTypeString,
        "deviceNameIds": "",
        "severityIds": severityString,
        "statusIds": statusString,
        "fromDate": fromDate,
        "endDate": endDate,
        "loadBalance": loggedInUser?.ApplicationFlag || "0"
    }
    getAlertList(obj).then((resp) => {
      if (resp.status == "Ok") {
          if (resp.message) {
            console.log("getAlertDataScroll", resp.message);
            let tempArr = resp.message;
            setData(tempList.concat(tempArr));
          }
      }
  })
  }

  const extractIds = (str) => {
    let arr = str.split(";");
    let cString, sString, dString, aTString, aSString, statusString = "";
    for(let i = 0; i < arr.length; i++){
      if(arr[i].split(":")[0] == "Client"){
        cString = arr[i].split(":")[1]
      }else if(arr[i].split(":")[0] == "Site"){
        sString = arr[i].split(":")[1]
      }else if(arr[i].split(":")[0] == "DeviceType"){
        dString = arr[i].split(":")[1]
      }else if(arr[i].split(":")[0] == "AlertType"){
        aTString = arr[i].split(":")[1]
      }else if(arr[i].split(":")[0] == "AlertSeverity"){
        aSString = arr[i].split(":")[1]
      }   
      else if(arr[i].split(":")[0] == "Status"){
        statusString = arr[i].split(":")[1]
      }   
    }
    setCustomerString(cString);
    setSiteString(sString);
    setDeviceTypeString(dString);
    setAlertTypeString(aTString);
    setSeverityString(aSString);
    setStatusString(statusString);
    statusRef.current = statusString;
    getAlertData(cString, sString, dString, aTString, "", aSString, statusString, fromDate, endDate, true);
  }

  const deleteFilter = (id) => {
    DeleteFilter(id)
      .then((res) => {
        if(res.Status == "success"){
          SuccessToast(res.Message);
          setModalFilterId(0)
          setModalFilterName("")
          setOpenDeleteFilter(false);
          getFilterList();
        }else{
          ErrorToast(res.Message);
        }
      })
      .catch((err) => {
        console.log(err);
      }) 
  }
  const getAlertCount=()=>{
    GetAlertCount().then((res)=>{
      if(res.Status == "Ok"){
        
        setAlertCount(res.message)
      }
    })
    .catch((err) =>{
      console.log(err);
    })
  }

  const infoFilterList = (pId) => {
    getInfoList(pId)
      .then((res) => {
        if (res.Status == "success") {
          let temp = JSON.parse(res.Data)
          infoDataFilter(temp)
        } else {
          ErrorToast(res.Message)
        }
      }).catch((err) => {
        console.log(err);
      })
  }
  const infoDataFilter = (arr) => {
    let customerArr = arr.filter((item) => item.Entity == CLIENT);
    setCustomerInfoArr(customerArr)
    setCustomerMasterArr(customerArr)
    let siteArr = arr.filter((item) => item.Entity == SITE);
    setSiteInfoArr(siteArr)
    setSiteMasterArr(siteArr)
    let deviceTypeArr = arr.filter((item) => item.Entity == DEVICE_TYPE);
    setDeviceTypeInfoArr(deviceTypeArr)
    setDeviceTypeMasterArr(deviceTypeArr)
    let alertTypeArr = arr.filter((item) => item.Entity == ALERT_TYPE);
    setAlertTypeInfoArr(alertTypeArr)
    setalertTypeMasterArr(alertTypeArr)
    let alertSeverityArr = arr.filter((item) => item.Entity == ALERT_SEVERITY);
    setAlertSeverityInfoArr(alertSeverityArr)
    setAlertSeverityMasterArr(alertSeverityArr)
  }
  const searchCustomerHandler = (e) => {
    setCustomerSearch(e.target.value)
    let tempCustomer = customerMasterArr.filter(x => x.Values.toLowerCase().includes(e.target.value.toLowerCase()))
    setCustomerInfoArr(tempCustomer)
  }
  const searchSiteHandler = (e) => {
    setSiteSearch(e.target.value)
    let tempSite = siteMasterArr.filter(x => x.Values.toLowerCase().includes(e.target.value.toLowerCase()))
    setSiteInfoArr(tempSite)
  }
  const searchDeviceTypeHandler = (e) => {
    setDeviceTypeSearch(e.target.value)
    let tempDeviceType = deviceTypeMasterArr.filter(x => x.Values.toLowerCase().includes(e.target.value.toLowerCase()))
    setDeviceTypeInfoArr(tempDeviceType)
  }
  const searchAlertTypeHandler = (e) => {
    setAlertTypeSearch(e.target.value)
    let tempAlertType = alertTypeMasterArr.filter(x => x.Values.toLowerCase().includes(e.target.value.toLowerCase()))
    setAlertTypeInfoArr(tempAlertType)
  }
  const searchAlertSeverityHandler = (e) => {
    setAlertSeveritySearch(e.target.value)
    let tempAlertSeverity = alertSeverityMasterArr.filter(x => x.Values.toLowerCase().includes(e.target.value.toLowerCase()))
    setAlertSeverityInfoArr(tempAlertSeverity)
  }

  return (
    <div className="col-lg-12 mt-2">
      <div className="SiteMainContainer">
        <div className="soundContainer" style={{ padding: "10px" }}>
          <div>
            <div
              style={{
                display: "flex",
                height: "120px",
                // alignItems: "center",
                // flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  // alignItems: "center",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                <div className="HeaderSiteList" style={{ height: "35px" }}>
                  <input
                    id="Search_txt_site"
                    className="SearchViewInput1"
                    style={{ width: "84%" }}
                    type="text"
                    value={alertIdSearch}
                    onChange={(e) => setAlertIdSearch(e.target.value)}
                    // onChange={(e) => {
                    //   setSearchTextSite(e.target.value)
                    //   onSearchChange(e.target.value, "Site List")
                    // }
                    // }
                    placeholder="Search Alert ID"
                    // value={searchTextSite}
                  />
                  <div className="SearchTextDivConfigTool">
                    {isDarkTheme ? (
                      <img
                        className="EyeIcon"
                        src={SearchIcon}
                        alt="search icon"
                        onClick={searchAlert}
                      />
                    ) : (
                      <img
                        className="EyeIcon"
                        src={SearchIconWhite}
                        alt="search icon"
                        height="15"
                      />
                    )}
                  </div>
                </div>
                <div 
                className={selectedFilterName == "" ? "SavedFilterDiv" : "SavedFilterDiv dropdownSelectionContainer"}
                >
                  <Dropdown>
                    <Dropdown.Toggle  variant="light">
                      {
                        selectedFilterName != ""
                        ? `Saved Filter: ${selectedFilterName}` 
                        : "Saved Filter"
                      }
                    </Dropdown.Toggle>
                    {
                      selectedFilterName != ""
                      ?
                      <button className="SavedFilterCancelBtn" onClick={() => {
                        if(selectedFilterName != ""){
                          clearSelectedFilter()
                        }
                        }}><img src={FilterClearIcon} /></button>
  
                      : null
                    }
                    <Dropdown.Menu>
                      <>
                      {
                        filterList.length > 0
                        ?
                        filterList.map((item) => (
                          <Dropdown.Item className='p-0'>
                        <div className="SavedFilterOption" onClick={() => {
                          console.log("Clicked")
                          setSelectedFilterName(item.FilterName)
                          setSelectedFilter(item.FilterId)
                          setSeveritySelected([]);
                          setDeviceTypeSelected([]);
                          setAlertTypeSelected([]);
                          setSiteSelected([]);
                          setCustomerSelected([]);
                          setDisableSaveBtn(false);
                          setFilterApplied(false);
                        }}>
                          <span>{item.FilterName}</span>
                          <div className="SaveFilterOtionHover">
                          <button style={{background:"transparent"}} onClick={(e) => {
                            e.stopPropagation();
                            setOpenDeleteFilter(true)
                            setModalFilterName(item.FilterName)
                            setModalFilterId(item.FilterId)
                          }}><img src={DeleteIcon}/></button>
                          <button style={{background:"transparent"}} onClick={(e) => {
                            infoFilterList(item.FilterId)
                            e.stopPropagation();
                            setOpenInfoFilter(true)
                            setModalFilterName(item.FilterName)
                            setModalFilterId(item.FilterId)
                          }}><img src={InfoIcon1}/></button>
                          </div>
                        </div>
                          </Dropdown.Item>
                        ))
                        : 
                        <Dropdown.Item style={{cursor: 'default'}}>
                            <span>No Saved Filters.</span>
                        </Dropdown.Item>
                      }
                      </>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <h4>OR</h4>
                <FilterToolDDLTemp {...filterToolDDLProps} />

<div className='SavedFilterDiv dropdownSelectionContainer'>
                  <Dropdown>
                    <Dropdown.Toggle  variant="light">
                      Select Status: {selectedStatus}
                    </Dropdown.Toggle>
      
                    <Dropdown.Menu>
                      <Dropdown.Item className='p-0'>
                        <div className="SavedFilterOption" onClick={() => {
                          setSelectedStatus("Open")
                          statusRef.current = "1,2"
                          // setStatusString("1,2")
                          setDisableSaveBtn(true);
                          setSelectedFilterName("");
                        }}>
                          <span>Open</span>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item className='p-0'>
                        <div className="SavedFilterOption" onClick={() => {
                          setSelectedStatus("Closed")
                          statusRef.current = "3"
                          // setStatusString("3")
                          setDisableSaveBtn(true);
                          setSelectedFilterName("");
                        }}>
                          <span>Closed</span>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
      

                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Apply Filter</Tooltip>}
                >
                  <button 
                  className={disableSaveBtn ? "IconsButtonDiv" : "IconsButtonDiv buttonDisable"}
                  disabled={!disableSaveBtn} onClick={applyFilterOnClick}>
                    <img
                      src={FilterIcon}
                      className="camera-icon-height"
                      alt="filter icon"
                    />
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Clear Filter</Tooltip>}
                >
                  <button 
                  className={disableSaveBtn ? "IconsButtonDiv" : "IconsButtonDiv buttonDisable"}
                  onClick={clearFilterList}
                  disabled={!disableSaveBtn}
                  >
                    <img
                      src={ClearFilter}
                      className="camera-icon-height"
                      alt="Clear filter icon"
                    />
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Save Filter</Tooltip>}
                >
                  <button
                    className={disableSaveBtn ? "IconsButtonDiv" : "IconsButtonDiv buttonDisable"}
                    disabled={!disableSaveBtn}
                    onClick={(e) => {
                      e.preventDefault();
                      setShow(!show);
                    }}
                  >
                    <img
                      src={SaveFilter}
                      className="camera-icon-height"
                      alt="Save filter icon"
                    />
                  </button>
                </OverlayTrigger>

                <div className="FilterOpenAlertsText">
                  <p className="ListofText">
                    {alertCount} <span className="SiteNameText"> Open alerts</span>
                  </p>
                  <p className="ListofText" style={{marginLeft: "1rem"}}>
                    {
                      selectedFilterName || filterApplied
                        ?
                        <span className="SiteNameText">
                          Filter Applied
                        </span>
                        :
                        <span className="SiteNameText">
                          Default Data
                        </span>
                    }
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: "grid",
                  gridAutoFlow: "column",
                  gridGap: "10px",
                  alignItems: "center",
                  position: "absolute",
                  right: "0",
                  // bottom: "0",
                  top: "70px",
                  marginRight: "10px",
                }}
              >
                <button
                  onClick={today}
                  type="button"
                  className={`${
                    activeTab == TODAY
                      ? "btn btn-outline-primary active"
                      : "btn btn-outline-primary"
                  }`}
                >
                  TD
                </button>
                <button
                  onClick={yesterday}
                  type="button"
                  className={`${
                    activeTab == YESTERDAY
                      ? "btn btn-outline-primary active"
                      : "btn btn-outline-primary"
                  }`}
                >
                  YD
                </button>
                <button
                  onClick={sevenDay}
                  type="button"
                  className={`${
                    activeTab == SEVENDAY
                      ? "btn btn-outline-primary active"
                      : "btn btn-outline-primary"
                  }`}
                >
                  7D
                </button>
                <button
                  onClick={oneMonth}
                  type="button"
                  className={`${
                    activeTab == ONEMONTH
                      ? "btn btn-outline-primary active"
                      : "btn btn-outline-primary"
                  }`}
                >
                  30D
                </button>
                <DateRangePicker
                  key={count}
                  className="mr-1"
                  format="dd-MM-yyyy HH:mm:ss"
                  cleanable={false}
                  editable={false}
                  defaultCalendarValue={initialDate}
                  value={inputDate}
                  ranges={[]}
                  placeholder="Select date range"
                  placement="bottomEnd"
                  onOk={(e) => {
                    onHandleEventDateTimeOkClick(e);
                  }}
                  disabledDate={(current) => {
                    let d;
                    if (MULTI_TIME_ZONE) {
                      let loggedInUser = JSON.parse(
                        localStorage.getItem("user")
                      );
                      d = multiTimeZoneHelper(loggedInUser.OffsetMinutes);
                    } else {
                      d = new Date();
                    }
                    return current && current > d;
                  }}
                />
              </div>
            </div>
          </div>
          <div className="">
            <div className="MainListDiv">
              <div className="newListofAlert">
                <div className="ListMain" style={{ height: "55px" }}>
                  <div className="headerOperateByDiv">
                    <p className="OperateBy">Code</p>
                  </div>
                  <div className="headerOperateByDiv">
                    <p className="OperateBy">ID</p>
                  </div>
                  <div className="headerOperateByDiv">
                    <p className="OperateBy">Site</p>
                  </div>
                  <div className="headerOperateByDiv">
                    <p className="OperateBy">Customer</p>
                  </div>
                  <div className="headerOperateByDiv">
                    <p className="OperateBy">Device</p>
                  </div>
                  <div className="headerOperateByDiv">
                    <p className="OperateBy">ACK By</p>
                  </div>
                  <div className="headerOperateByDiv">
                    <p className="OperateBy">Date & Time</p>
                  </div>
                  <div className="headerOperateByDiv">
                    <p className="OperateBy">Status</p>
                  </div>
                  <div className="headerOperateByDiv">
                    <p className="OperateBy">Severity</p>
                  </div>
                  <div className="headerOperateByDiv">
                    <p className="OperateBy">Type</p>
                  </div>
                  <div className="headerOperateByDiv">
                    <p className="OperateBy">Actions</p>
                  </div>
                  <div className="ChartsButtonDiv">
                  </div>
                </div>
              </div>
              <div
                id="scrollableDivOne"
                className="infinite-scroll-div-list"
                onScroll={handleScroll}
              >
                {
                  singleAlert.length > 0
                  ?
                  <div className="scrollbar" id="style-3">
                    {
                      singleAlert.map((item) => 
                        <AlertList 
                          key={item.alertId}
                          id={item.alertId}
                          alert={item}
                          onAddAlertChat={onAddAlertChat}
                      // handleChange={handleChange}
                      />
                      )
                    }
                  </div>
                  :
                  <InfiniteScroll
                  key={resetInfiniteScroll}
                  dataLength={data.length}
                  next={fetchMoreData}
                  hasMore={true}
                  // loader={<h4>Loading...</h4>}
                  scrollableTarget="scrollableDivOne"
                >
                  {
                    data.length > 0
                    ?
                  <div className="scrollbar" id="style-3">
                      {
                        data.map((item) => 
                          <AlertList 
                          key={item.alertId}
                          id={item.alertId}
                          alert={item}
                          onAddAlertChat={onAddAlertChat}
                          // handleChange={handleChange}
                          />
                        )
                      }
                  </div>
                  :
                  <div>
                      <ZeroCountAlert />
                  </div>
                  }
                </InfiniteScroll>

                }
              </div>

              {scrollStart ? (
                <div
                  className="m-backtotop"
                  aria-hidden="true"
                  id="back-to-up"
                  onClick={scrollToTop}
                >
                  <div className="arrow">
                    <img src={BackToTopBlue} />
                    <span>TOP</span>
                  </div>
                </div>
              ) : null}

              {/* Modal popup for save filter */}
              <Modal
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                backdrop="static"
                className="saveCameraFilterModal"
              >
                <>
                  <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title">
                      Save Filter
                    </Modal.Title>
                    <img
                      src={RemoveIcon}
                      alt="Remove"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShow(false);
                        setFilterName("");
                        //   setAddCustomerError('')
                      }}
                    />
                  </Modal.Header>

                  <Modal.Body>
                    <div className="inputMainContainer w40dot75rem w-100">
                      <div className="inputTypeLabelContainer">
                        <label className="formFieldLabels">Filter Name</label>
                        <input
                          style={{ width: "40vw" }}
                          type="text"
                          className="inputType"
                          placeholder="Ex. Filter for xyz company with 10 sites"
                          value={filterName}
                          onChange={(e) => setFilterName(e.target.value)}
                        />
                        <p className="Error_P" style={{ float: "left" }}>
                          <span>{}</span>
                        </p>
                      </div>
                    </div>
                    <button
                      className="DataAccessEdit mt-3"
                      style={{ width: "100%" }}
                      onClick={saveFilter}
                    >
                      <span className="ViewUpdate">Save</span>
                    </button>
                  </Modal.Body>
                </>
              </Modal>

              {/* Modal popup for delete of scheduler */}
              <Modal
                // size="lg"
                show={openDeleteFilter}
                onHide={() => setOpenDeleteFilter(false)}
                className="deleteModalMainClass"
                backdrop="static"
                // centered
              >
                <Modal.Body>
                  <div className="ModalBodyDiv">
                    <div style={{ padding: "37px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <img
                          className="informationIcon infoorangeicon"
                          src={WarningDelete}
                          alt="Info"
                        />
                        <div style={{ width: "100%" }}>
                          <h3 className="No-Data-Access-Group">
                            Delete Saved Filter!
                          </h3>
                        </div>
                      </div>
                      <p className="DeleteText mb-4">
                        Are you sure you want to delete the saved filter{" "}
                        <span className="font-weight-bold"> {modalFilterName}</span>
                      </p>
                      <div
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          className="DataAccessEdit ml-0"
                          style={{ width: "45%" , backgroundColor: "#FB3030"}}
                          onClick={() => deleteFilter(modalFilterId)}
                        >
                          <span className="ViewUpdate">Yes</span>
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenDeleteFilter(false);
                            setModalFilterId(0)
                            setModalFilterName("")                  
                          }}
                          className="DataAccessEdit ml-0"
                          style={{ width: "45%" }}
                        >
                          <span className="ViewUpdate">No</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* Modal popup for info saved filter */}
              <Modal
                size="xl"
                show={openInfoFilter}
                onHide={() => setOpenInfoFilter(false)}
                className="deleteModalMainClass"
                backdrop="static"
                // centered
                scrollable
              >
                <Modal.Header>
                  <Modal.Title id="example-custom-modal-styling-title">
                    {modalFilterName} - info
                  </Modal.Title>
                  <img
                    src={RemoveIcon}
                    alt="Remove"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOpenInfoFilter(false);
                    }}
                  />
                </Modal.Header>
                <Modal.Body>  
                  <>
                    <div id="mainContainer" className="main-container" 
                    // style={{ width: `${containerWidth}px` }}
                    >
                    {
                      customerMasterArr?.length > 0
                        ?
                          <div className="SiteLeftContainer ml-2 mr-2" 
                          // style={{ width: "100%" }}
                          >
                            <div className="infoFilterHeader">
                              <p>Customer</p>
                            </div>
                            <div className="m-2 HeaderSiteList">
                              <input
                                id="Search_txt_site"
                                className="SearchViewInput1"
                                type="text"
                                placeholder="Search Site"
                                value={customerSearch}
                                onChange={(e) => searchCustomerHandler(e)}
                              />
                              <div className="SearchTextDivConfigTool">
                                {isDarkTheme ? (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIcon}
                                    alt="search icon"
                                  />
                                ) : (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIconWhite}
                                    alt="search icon"
                                    height="15"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="SaveFilterInfoList">
                              {customerInfoArr &&
                                customerInfoArr?.length > 0
                                ? (
                                  <div>{
                                    customerInfoArr.map((item) => (
                                      <p className="ListofText">
                                        {item.Values}
                                      </p>
                                    ))
                                  }

                                  </div>
                                ) : (
                                  <div className="no-data-found">
                                    <p>No Customer Found.</p>
                                  </div>
                                )}
                              {!customerInfoArr && (
                                <div className="no-data-found">
                                  No Customer Available.
                                </div>
                              )}
                            </div>
                          </div>
                        :
                        null
                    }

                    {
                      siteMasterArr?.length > 0
                        ?
                          <div className="SiteLeftContainer ml-2 mr-2" 
                          >
                            <div className="infoFilterHeader">
                              <p>Site</p>
                            </div>
                            <div className="m-2 HeaderSiteList">
                              <input
                                id="Search_txt_site"
                                className="SearchViewInput1"
                                type="text"
                                placeholder="Search Site"
                                value={siteSearch}
                                onChange={(e) => searchSiteHandler(e)}
                              />
                              <div className="SearchTextDivConfigTool">
                                {isDarkTheme ? (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIcon}
                                    alt="search icon"
                                  />
                                ) : (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIconWhite}
                                    alt="search icon"
                                    height="15"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="SaveFilterInfoList">
                              {siteInfoArr &&
                                siteInfoArr?.length > 0
                                ? (
                                  <div>
                                    {
                                      siteInfoArr?.map((item) => (
                                        <p className="ListofText">
                                          {item.Values}

                                        </p>
                                      ))
                                    }
                                  </div>
                                ) : (
                                  <div className="no-data-found">
                                    <p>No Site Found.</p>
                                  </div>
                                )}
                              {!siteInfoArr && (
                                <div className="no-data-found">
                                  No Sites Available.
                                </div>
                              )}
                            </div>
                          </div>
                        :
                        null
                    }

                    {
                      deviceTypeMasterArr?.length > 0
                        ?
                          <div className="SiteLeftContainer ml-3 mr-2" >
                            <div className="infoFilterHeader">
                              <p>Device Type</p>
                            </div>
                            <div className="m-2 HeaderSiteList">
                              <input
                                id="Search_txt_site"
                                className="SearchViewInput1"
                                type="text"
                                placeholder="Search Device Type"
                                value={deviceTypeSearch}
                                onChange={(e) => searchDeviceTypeHandler(e)}
                              />
                              <div className="SearchTextDivConfigTool">
                                {isDarkTheme ? (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIcon}
                                    alt="search icon"
                                  />
                                ) : (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIconWhite}
                                    alt="search icon"
                                    height="15"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="SaveFilterInfoList">
                              {devieceTypeInfoArr &&
                                devieceTypeInfoArr?.length > 0
                                ? (
                                  <div>
                                    {
                                      devieceTypeInfoArr.map((item) => (
                                        <p className="ListofText">
                                          {item.Values}
                                        </p>
                                      ))
                                    }
                                  </div>
                                ) : (
                                  <div className="no-data-found">
                                    <p>No Device Type Found.</p>
                                  </div>
                                )}
                              {!devieceTypeInfoArr && (
                                <div className="no-data-found">
                                  No Device Type Available.
                                </div>
                              )}
                            </div>
                          </div>
                        :
                        null
                    }
                    {
                      alertTypeMasterArr?.length > 0
                        ?
                          <div className="SiteLeftContainer ml-3 mr-2">
                            <div className="infoFilterHeader">
                              <p>Alert Type</p>
                            </div>
                            <div className="m-2 HeaderSiteList">
                              <input
                                id="Search_txt_site"
                                className="SearchViewInput1"
                                type="text"
                                placeholder="Search Alert Type"
                                value={alertTypeSearch}
                                onChange={(e) => searchAlertTypeHandler(e)}
                              />
                              <div className="SearchTextDivConfigTool">
                                {isDarkTheme ? (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIcon}
                                    alt="search icon"
                                  />
                                ) : (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIconWhite}
                                    alt="search icon"
                                    height="15"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="SaveFilterInfoList">
                              {alertTypeInfoArr &&
                                alertTypeInfoArr?.length > 0
                                ? (
                                  <div>
                                    {
                                      alertTypeInfoArr.map((item) => (
                                        <p className="ListofText">
                                          {item.Values}
                                        </p>

                                      ))
                                    }
                                  </div>
                                ) : (
                                  <div className="no-data-found">
                                    <p>No Alert Type Found.</p>
                                  </div>
                                )}
                              {!alertTypeInfoArr && (
                                <div className="no-data-found">
                                  No Alert Type Available.
                                </div>
                              )}
                            </div>
                          </div>
                        :
                        null
                    }
                    {
                      alertSeverityMasterArr?.length > 0
                        ?
                          <div className="SiteLeftContainer ml-3 mr-2">
                            <div className="infoFilterHeader">
                              <p>Alert Severity</p>
                            </div>
                            <div className="m-2 HeaderSiteList">
                              <input
                                id="Search_txt_site"
                                className="SearchViewInput1"
                                type="text"
                                placeholder="Search Site"
                                value={alertSeveritySearch}
                                onChange={(e) => searchAlertSeverityHandler(e)}
                              />
                              <div className="SearchTextDivConfigTool">
                                {isDarkTheme ? (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIcon}
                                    alt="search icon"
                                  />
                                ) : (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIconWhite}
                                    alt="search icon"
                                    height="15"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="SaveFilterInfoList">
                              {alertSeverityInfoArr &&
                                alertSeverityInfoArr?.length > 0
                                ? (
                                  <div>{
                                    alertSeverityInfoArr.map((item) => (
                                      <p className="ListofText">
                                        {item.Values}
                                      </p>
                                    ))
                                  }
                                  </div>
                                ) : (
                                  <div className="no-data-found">
                                    <p>No Alert Severity Found.</p>
                                  </div>
                                )}
                              {!alertSeverityInfoArr && (
                                <div className="no-data-found">
                                  No Alert Severity Available.
                                </div>
                              )}
                            </div>
                          </div>
                        :
                        null
                    }
                    </div>
                  
                  <div 
                  style={{width: "2000px", overflowX: "scroll", display: "none"}}
                  >
                    <div style={{display: "flex"}}>
                    {
                      customerMasterArr?.length > 0
                        ?
                          <div className="SiteLeftContainer ml-2 mr-2" 
                          >
                            <div className="infoFilterHeader">
                              <p>Customer</p>
                            </div>
                            <div className="m-2 HeaderSiteList">
                              <input
                                id="Search_txt_site"
                                className="SearchViewInput1"
                                type="text"
                                placeholder="Search Site"
                                value={customerSearch}
                                onChange={(e) => searchCustomerHandler(e)}
                              />
                              <div className="SearchTextDivConfigTool">
                                {isDarkTheme ? (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIcon}
                                    alt="search icon"
                                  />
                                ) : (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIconWhite}
                                    alt="search icon"
                                    height="15"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="SaveFilterInfoList">
                              {customerInfoArr &&
                                customerInfoArr?.length > 0
                                ? (
                                  <div>{
                                    customerInfoArr.map((item) => (
                                      <p className="ListofText">
                                        {item.Values}
                                      </p>
                                    ))
                                  }

                                  </div>
                                ) : (
                                  <div className="no-data-found">
                                    <p>No Customer Found.</p>
                                  </div>
                                )}
                              {!customerInfoArr && (
                                <div className="no-data-found">
                                  No Customer Available.
                                </div>
                              )}
                            </div>
                          </div>
                        // </div>
                        :
                        null
                    }

                    {
                      siteMasterArr?.length > 0
                        ?
                          <div className="SiteLeftContainer ml-2 mr-2">
                            <div className="infoFilterHeader">
                              <p>Site</p>
                            </div>
                            <div className="m-2 HeaderSiteList">
                              <input
                                id="Search_txt_site"
                                className="SearchViewInput1"
                                type="text"
                                placeholder="Search Site"
                                value={siteSearch}
                                onChange={(e) => searchSiteHandler(e)}
                              />
                              <div className="SearchTextDivConfigTool">
                                {isDarkTheme ? (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIcon}
                                    alt="search icon"
                                  />
                                ) : (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIconWhite}
                                    alt="search icon"
                                    height="15"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="SaveFilterInfoList">
                              {siteInfoArr &&
                                siteInfoArr?.length > 0
                                ? (
                                  <div>
                                    {
                                      siteInfoArr?.map((item) => (
                                        <p className="ListofText">
                                          {item.Values}
                                        </p>
                                      ))
                                    }
                                  </div>
                                ) : (
                                  <div className="no-data-found">
                                    <p>No Site Found.</p>
                                  </div>
                                )}
                              {!siteInfoArr && (
                                <div className="no-data-found">
                                  No Sites Available.
                                </div>
                              )}
                            </div>
                          </div>
                        :
                        null
                    }

                    {
                      deviceTypeMasterArr?.length > 0
                        ?
                          <div className="SiteLeftContainer ml-3 mr-2" >
                            <div className="infoFilterHeader">
                              <p>Device Type</p>
                            </div>
                            <div className="m-2 HeaderSiteList">
                              <input
                                id="Search_txt_site"
                                className="SearchViewInput1"
                                type="text"
                                placeholder="Search Device Type"
                                value={deviceTypeSearch}
                                onChange={(e) => searchDeviceTypeHandler(e)}
                              />
                              <div className="SearchTextDivConfigTool">
                                {isDarkTheme ? (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIcon}
                                    alt="search icon"
                                  />
                                ) : (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIconWhite}
                                    alt="search icon"
                                    height="15"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="SaveFilterInfoList">
                              {devieceTypeInfoArr &&
                                devieceTypeInfoArr?.length > 0
                                ? (
                                  <div>
                                    {
                                      devieceTypeInfoArr.map((item) => (
                                        <p className="ListofText">
                                          {item.Values}
                                        </p>
                                      ))
                                    }
                                  </div>
                                ) : (
                                  <div className="no-data-found">
                                    <p>No Device Type Found.</p>
                                  </div>
                                )}
                              {!devieceTypeInfoArr && (
                                <div className="no-data-found">
                                  No Device Type Available.
                                </div>
                              )}
                            </div>
                          </div>
                        // </div>
                        :
                        null
                    }
                    {
                      alertTypeMasterArr?.length > 0
                        ?
                          <div className="SiteLeftContainer ml-3 mr-2" >
                            <div className="infoFilterHeader">
                              <p>Alert Type</p>
                            </div>
                            <div className="m-2 HeaderSiteList">
                              <input
                                id="Search_txt_site"
                                className="SearchViewInput1"
                                type="text"
                                placeholder="Search Alert Type"
                                value={alertTypeSearch}
                                onChange={(e) => searchAlertTypeHandler(e)}
                              />
                              <div className="SearchTextDivConfigTool">
                                {isDarkTheme ? (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIcon}
                                    alt="search icon"
                                  />
                                ) : (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIconWhite}
                                    alt="search icon"
                                    height="15"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="SaveFilterInfoList">
                              {alertTypeInfoArr &&
                                alertTypeInfoArr?.length > 0
                                ? (
                                  <div>
                                    {
                                      alertTypeInfoArr.map((item) => (
                                        <p className="ListofText">
                                          {item.Values}
                                        </p>

                                      ))
                                    }
                                  </div>
                                ) : (
                                  <div className="no-data-found">
                                    <p>No Alert Type Found.</p>
                                  </div>
                                )}
                              {!alertTypeInfoArr && (
                                <div className="no-data-found">
                                  No Alert Type Available.
                                </div>
                              )}
                            </div>
                          </div>
                        :
                        null
                    }
                    {
                      alertSeverityMasterArr?.length > 0
                        ?
                          <div className="SiteLeftContainer ml-3 mr-2" >
                            <div className="infoFilterHeader">
                              <p>Alert Severity</p>
                            </div>
                            <div className="m-2 HeaderSiteList">
                              <input
                                id="Search_txt_site"
                                className="SearchViewInput1"
                                type="text"
                                placeholder="Search Site"
                                value={alertSeveritySearch}
                                onChange={(e) => searchAlertSeverityHandler(e)}
                              />
                              <div className="SearchTextDivConfigTool">
                                {isDarkTheme ? (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIcon}
                                    alt="search icon"
                                  />
                                ) : (
                                  <img
                                    className="EyeIcon"
                                    src={SearchIconWhite}
                                    alt="search icon"
                                    height="15"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="SaveFilterInfoList">
                              {alertSeverityInfoArr &&
                                alertSeverityInfoArr?.length > 0
                                ? (
                                  <div>{
                                    alertSeverityInfoArr.map((item) => (
                                      <p className="ListofText">
                                        {item.Values}
                                      </p>
                                    ))
                                  }
                                  </div>
                                ) : (
                                  <div className="no-data-found">
                                    <p>No Alert Severity Found.</p>
                                  </div>
                                )}
                              {!alertSeverityInfoArr && (
                                <div className="no-data-found">
                                  No Alert Severity Available.
                                </div>
                              )}
                            </div>
                          </div>
                        :
                        null
                    }
                    </div>
                  </div>
                  </>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
import React from 'react'
import { Bar } from "react-chartjs-2";


export default function HealthIntegratedChart({ isDarkTheme, nonIntegratedDevicesCount, systemHealthDevicesOnlineCount, systemHealthDevicesOfflineCount }) {


    const data = {
        labels: ['Integrated', 'Non-Integrated'],
        datasets: [
            {
                label: "Online",
                data: [systemHealthDevicesOnlineCount, 0],
                backgroundColor: "#7BB526",
                barThickness: 30,
                barPercentage: 0.3,
                borderRadius: 3,
            },
            {
                label: "Offline",
                data: [systemHealthDevicesOfflineCount, 0],
                backgroundColor: "#F32C22",
                barThickness: 30,
                barPercentage: 0.3,
                borderRadius: 3,
            },
            {
                label: "NA",
                data: [0, nonIntegratedDevicesCount],
                backgroundColor: "#9D9D9D",
                barThickness: 30,
                barPercentage: 0.3,
                borderRadius: 3,
            },
        ]
    }

    const option = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: true,
        },
        scales: {
            x: {
                stacked: true,
                border: {
                    display: true,
                    color: isDarkTheme ? '#e6e6e6' : "white",
                },
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    color: isDarkTheme ? "#000000" : "#ffffff",
                },
                ticks: {
                    color: isDarkTheme ? "" : "#bababa",
                },
            },
            y: {
                stacked: true,
                border: {
                    display: true,
                    color: isDarkTheme ? '#e6e6e6' : "white",
                },
                beginAtZero: true,
                title: {
                    display: true,
                    color: isDarkTheme ? "#000000" : "#ffffff",
                },
                grid: {
                    display: false,
                },
                ticks: {
                    color: isDarkTheme ? "" : "#bababa",
                },
            },

        },
    }


    return (
        <div style={{ width: "100%", float: "right" }}>
            <Bar
                data={data}
                options={option}
                key={`${systemHealthDevicesOnlineCount}_${isDarkTheme}`}
            />
        </div>
    )
}

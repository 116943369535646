import { BASE_CORE_URL, BASE_URL } from "../config";

export const getCustomerList = async () => {
  let loggedInUser = JSON.parse(localStorage.getItem('user'))
  let uniqueId = loggedInUser.UserUniqueID
    const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "authcode": localStorage.getItem('authCode'),
          "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
      };

      let url = BASE_CORE_URL + "Cameras/GetClientsList?uniqueId=" + uniqueId;
      
      return await fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch(err => console.log(err));
}

export const getSeverity = async () => {
    const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "authcode": localStorage.getItem('authCode'),
          "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
      };

      let url = BASE_CORE_URL + "SoundSetting/GetAllSeverity";
      
      return await fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch(err => console.log(err));
}

export const getSiteList = async (clientIds) => {
  let loggedInUser = JSON.parse(localStorage.getItem('user'))
  let uniqueId = loggedInUser.UserUniqueID
  const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken')
      },
    };

    let url = BASE_CORE_URL + "Cameras/GetSiteList?ClientId=" + clientIds + "&uniqueId=" + uniqueId;
    
    return await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
          return data;
      })
      .catch(err => console.log(err));
}

export const getFilterAlertTypeList = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };

  let url = BASE_CORE_URL + "Device/FilterAlertTypeList";
  
  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
        return data;
    })
    .catch(err => console.log(err));
}

export const FilterSaved= async (pName,pType,userUniqueId,filterText) => {
  const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken')
      },
    };

    let url = BASE_CORE_URL + "Device/SaveFilter?pName=" + pName + "&pType=" + pType + "&userUniqueId=" + userUniqueId + "&filterText=" + filterText;
    
    return await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
          return data;
      })
      .catch(err => console.log(err));
}

export const GetFilter= async (pType,userUniqueId) => {
  const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken')
      },
    };

    let url = BASE_CORE_URL + "Device/GetAllFilter?pType=" + pType + "&userUniqueId=" + userUniqueId;
    
    return await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
          return data;
      })
      .catch(err => console.log(err));
}

export const DeleteFilter= async (pId) => {
  const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken')
      },
    };

    let url = BASE_CORE_URL + "Device/DeleteFilter?pId=" + pId;
    
    return await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
          return data;
      })
      .catch(err => console.log(err));
}

export const GetAlertCount = async () => {
   let loggedInUser = JSON.parse(localStorage.getItem('user'))
   
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  let url = BASE_URL + "getAlertCount?RoleID=" + loggedInUser.RoleId +"&userUniqueId=" + loggedInUser.UserUniqueID;
  return await fetch(url, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("getsitelist () Exception: ", error);
    });
}

export const getLatestAlertStatus = async (stringOfAlerts) => {
  let loggedInUser = JSON.parse(localStorage.getItem('user'))
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      stringOfAlerts: stringOfAlerts,
      userId: loggedInUser.UserID
    })
  }

  const url = BASE_URL + "latestStatus";

  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch(err => console.log(err));
}

export const getInfoList = async (pId) => {
  const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken')
      },
    };

    let url = BASE_CORE_URL + "Device/GetFilterDetails?pId=" + pId;
    
    return await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
          return data;
      })
      .catch(err => console.log(err));
}
export const closedAlertGridBtn = async (alertId,userID) => {
  const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken')
      },
    };

    let url = BASE_CORE_URL + "Alert/ForceClose?pAlertId=" + alertId + "&userId=" +  userID;
    
    return await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
          return data;
      })
      .catch(err => console.log(err));
}

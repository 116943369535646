import { BASE_CORE_URL, BASE_URL } from './../config/index'

//#region  to get the Alert Report Data
// export const getAlertActivityReport = async (obj) => {
//     console.log("getAlertActivityReport", obj);
//     let url = `${BASE_URL}alertreport`

//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(obj),
//     };

//     return await fetch(url, requestOptions)
//         .then((resp) => resp.json())
//         .then(function (data) {
//             console.log("getAlertActivityReport", data);
//             return data;
//         })
//         .catch(function (error) {
//             console.error(error);
//         });
// }
//#endregion 

// .dotnet API
export const getAlertActivityReport = async (uniqueId, startDate, endDate, pageNumber, searchText, customers, sites) => {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json', 
            "authcode": localStorage.getItem('authcode'),
            "Authorization": "Bearer " + localStorage.getItem('authcode')
        },
    };
    
    let url = BASE_CORE_URL + "ReportAlert/getAlertReport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText + "&customerIds=" + customers + "&siteIds=" + sites;
    return await fetch(url, requestOptions)
        .then(resp => resp.json())
        .then(data => {
            console.log("getAlertActivityReport", data);
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}

export const getAlertExportStatus = async (uniqueId, startDate, endDate, pageNumber, searchText, customers, sites) => {
    const requestOptions = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        }
    }
    let url = BASE_CORE_URL + "ReportAlert/getAlertReportExport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText + "&customerIds=" + customers + "&siteIds=" + sites;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            console.log("getAlertExportStatus", data);
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}

//#region  to get the Disarm Alert Report Data
export const getAlertArmDisarmReport = async (obj) => {

    let url = `${BASE_URL}alertreport`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}
//#endregion

//#region  to get the Even report Data 
export const getStatusChangeReport = async (uniqueId, startDate, endDate, pageNumber, searchText, customers, sites) => {
    console.log("FilterText", customers)
    console.log("FilterText", sites)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    };
    
    let url = BASE_CORE_URL + "Report/GetHMReport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText + "&customerIds=" + customers + "&siteIds=" + sites;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}
//#endregion

//#region  to get the Health Event report to export
export const getExportStatus = async (uniqueId, startDate, endDate, pageNumber, searchText, customers, sites) => {
    const requestOptions = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        }
    }
    let url = BASE_CORE_URL + "Report/GetReportExport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText + "&customerIds=" + customers + "&siteIds=" + sites;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}
//#endregion

//#region to get the Event Report 

export const getEventReport = async (uniqueId, startDate, endDate, pageNumber, searchText, customers, sites) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    };
    let url = BASE_CORE_URL + "EventReport/GetEventReport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText + "&customerIds=" + customers + "&siteIds=" + sites;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}
//#endregion


export const getEventExportStatus = async (uniqueId, startDate, endDate, pageNumber, searchText, customers, sites) => {
    const requestOptions = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        }
    }
    let url = BASE_CORE_URL + "EventReport/GetEventReportExport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText + "&customerIds=" + customers + "&siteIds=" + sites;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}
//#endregion

//#region to get the CustomerAlert Report 
export const getCustomerAlertReport = async (uniqueId, startDate, endDate, pageNumber, searchText,customers, sites) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    };
    let url = BASE_CORE_URL + "CustomerAlert/GetCustomerAlertReport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText + "&customerIds=" + customers + "&siteIds=" + sites;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}
//#endregion

//#region to get the Customer Alert report to export 
export const getCustomerAlertExportStatus = async (uniqueId, startDate, endDate, pageNumber, searchText,customers, sites) => {
    const requestOptions = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        }
    }
    let url = BASE_CORE_URL + "CustomerAlert/GetCustomerAlertReportExport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText + "&customerIds=" + customers + "&siteIds=" + sites;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}
//#endregion

//#region to get the CCTV Report 
export const getCCTVAlertReport = async (uniqueId, startDate, endDate, pageNumber, searchText,customers, sites) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    };
    let url = BASE_CORE_URL + "CCTVReport/GetCCTVReport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText + "&customerIds=" + customers + "&siteIds=" + sites;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}
//#endregion

//#region to get the CCTV report to export 
export const getCCTVAlertReportExportStatus = async (uniqueId, startDate, endDate, pageNumber, searchText,customers, sites) => {
    const requestOptions = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        }
    }
    let url = BASE_CORE_URL + "CCTVReport/GetCCTVReportExport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText + "&customerIds=" + customers + "&siteIds=" + sites;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}
//#endregion

//#region to get the CustomerAlert Report 
export const getCustomerSiteAlertReport = async (uniqueId, startDate, endDate, pageNumber, searchText,customers, sites) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    };
    let url = BASE_CORE_URL + "ReportCustomerSiteAlertCount/getReportCustomerSiteAlertCount?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText + "&customerIds=" + customers + "&siteIds=" + sites;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}
//#endregion

//#region to get the Customer Alert report to export 
export const getCustomerSiteAlertExportStatus = async (uniqueId, startDate, endDate, pageNumber, searchText,customers, sites) => {
    const requestOptions = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        }
    }
    let url = BASE_CORE_URL + "ReportCustomerSiteAlertCount/getReportCustomerSiteAlertCountExport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText + "&customerIds=" + customers + "&siteIds=" + sites;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}

export const getOperatorAlertReport = async (uniqueId, startDate, endDate, pageNumber, searchText, customers, sites) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    };
    let url = BASE_CORE_URL + "ReportOperatorAlertCount/getReportOperatorAlertCount?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText + "&clientIds=" + customers + "&siteIds=" + sites;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}

export const getOperatorAlertCountExport = async (uniqueId, startDate, endDate, pageNumber, searchText, customers, sites) => {
    const requestOptions = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        }
    }
    let url = BASE_CORE_URL + "ReportOperatorAlertCount/getReportOperatorAlertCountExport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText + "&clientIds=" + customers + "&siteIds=" + sites;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}
//#endregion

//#region Getting Data of Dynamic Reports
export const getDailyReport = async (uniqueId, reportNumber, isPivot, pageNumber, startDate, endDate, searchText, IsExport, customerIds, siteIds) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    };
    //let url = BASE_CORE_URL + "ReportOperatorAlertCount/getReportOperatorAlertCountExport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText + "&clientIds=" + customers + "&siteIds=" + sites;
    let url = BASE_CORE_URL + "Report/GetReport?userUniqueId=" + uniqueId + "&reportNumber=" + reportNumber + "&isPivot=" + isPivot + "&pageNumber=" + pageNumber + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&searchText=" + searchText + "&customerIds=" + customerIds + "&siteIds=" + siteIds + "&IsExport=" + IsExport;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}
//#endregion
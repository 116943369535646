import React, { useEffect, useRef, useState } from 'react'
import { MultiSelect } from "react-multi-select-component";
import WebRTCPlayer from '../Cameras/WebRTCPlayer';
import { AttachIcon, BotAvatarIcon, ClearFilter, DeleteIcon, ERTAvatarIcon, FilterClearIcon, FilterIcon, InfoIcon1, LoadingAlert, MikeIcon, NoMoreAlert, RefreshBtnIcon, RemoveIcon, SaveFilter, SearchIcon, SearchIconWhite, SendIconBlue, WarningDelete } from '../../assets/images';
import { mixpanel } from '../../utils/MixPanelUtil'
import { Tooltip, OverlayTrigger, Dropdown } from 'react-bootstrap';
import { Modal } from "react-bootstrap";
import FilterToolDDLTemp from '../AlertViewNew/FilterToolDDLTemp';
import axios from 'axios';
import { ALERT_INTERVAL_TIMER, AUTO_ALERT_CLOSE, BASE_CORE_URL, BASE_URL, CHAT_CAM_CHANGES, LOAD_BALANCE, MULTI_TIME_ZONE } from '../../config';
import { closedAlertGridBtn, DeleteFilter, FilterSaved, getCustomerList, GetFilter, getFilterAlertTypeList, getInfoList, getLatestAlertStatus, getSeverity, getSiteList } from '../../services/alertGridServices';
import { deviceconfigcrud } from '../../services/configurationServices';
import { GetmultiTimeZone } from '../../services/multiTimeZoneService';
import { getAlertDetailsById, getAlertList } from '../../services';
import ChatflowContainer from '../Chatflow/ChatflowContainer';
import { ConvertDate, multiTimeZoneHelper } from '../../utils/utils';
import { addrasaconversationindb } from '../../services/chatFlowService';
import AlertTemplateMessage from '../Configuration/AlertTemplate/AlertTemplateMessage';
import AliceCarousel from 'react-alice-carousel';
import { ErrorToast } from '../../CustomHooks/ErrorToast';
import { SuccessToast } from '../../CustomHooks/SuccessToast';
import { ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import { WarningToast } from '../../CustomHooks/WarningToast';

let dummyItems = [
  {
    "IsPTZ": "0",
    "ShowHooter": true,
    "ShowTwoWay": true,
    "associatedCameraId": 1866,
    "brandName": "",
    "camPassword": "Intellve$WSP2023",
    "camUserName": "intellve",
    "cameraBrandId": 6,
    "cameraName": "UNV Cam 1",
    "cctvtype": "NVR",
    "channelNum": "1",
    "communicationPort": 8000,
    "deviceId": 1941,
    "hlsStreamingUrl": null,
    "idsPanelZoneTypeID": 4,
    "isPlaybackTranscoded": "0",
    "isTranscoded": "0",
    "privateIpAddress": "122.176.163.171",
    "rtspUrl": "rtsp://admin:2o2oImaging@192.168.0.64:554/cam/realmonitor?channel=2%38subtype=1",
    "streamName": "952d5a3e-7e55-4cbe-a028-e8248f5049d3",
    "zoneType": ""
  },
  {
    "IsPTZ": "None",
    "ShowHooter": true,
    "ShowTwoWay": true,
    "associatedCameraId": 3463,
    "brandName": "CP Plus",
    "camPassword": "2o2oImaging",
    "camUserName": "admin",
    "cameraBrandId": 2,
    "cameraName": "CP Cam 3",
    "cctvtype": "NVR",
    "channelNum": "3",
    "communicationPort": 25001,
    "deviceId": 3463,
    "hlsStreamingUrl": null,
    "idsPanelZoneTypeID": 4,
    "isPlaybackTranscoded": "0",
    "isTranscoded": "0",
    "privateIpAddress": "192.168.0.64",
    "rtspUrl": "rtsp://admin:2o2oImaging@192.168.0.64:554/cam/realmonitor?channel=3%38subtype=1",
    "streamName": "891_45b9d51c-8194-4ef8-8e29-9688ba546017",
    "zoneType": ""
  },
  {
    "IsPTZ": "None",
    "ShowHooter": true,
    "ShowTwoWay": true,
    "associatedCameraId": 1869,
    "brandName": "",
    "camPassword": "Intellve$WSP2023",
    "camUserName": "intellve",
    "cameraBrandId": 6,
    "cameraName": "UNV Cam 5",
    "cctvtype": "NVR",
    "channelNum": "5",
    "communicationPort": 8000,
    "deviceId": 1941,
    "hlsStreamingUrl": null,
    "idsPanelZoneTypeID": 4,
    "isPlaybackTranscoded": "0",
    "isTranscoded": "0",
    "privateIpAddress": "122.176.163.171",
    "rtspUrl": "rtsp://admin:TrackeR#007@14.142.128.236:555/cam/realmonitor?channel=12%26subtype=1",
    "streamName": "6797bf43-bc45-4fc4-b0f6-f9f66515a465",
    "zoneType": ""
  },
  {
    "IsPTZ": "None",
    "ShowHooter": true,
    "ShowTwoWay": true,
    "associatedCameraId": 1868,
    "brandName": "",
    "camPassword": "Intellve$WSP2023",
    "camUserName": "intellve",
    "cameraBrandId": 6,
    "cameraName": "UNV Cam 4",
    "cctvtype": "NVR",
    "channelNum": "4",
    "communicationPort": 8000,
    "deviceId": 1941,
    "hlsStreamingUrl": null,
    "idsPanelZoneTypeID": 4,
    "isPlaybackTranscoded": "0",
    "isTranscoded": "0",
    "privateIpAddress": "122.176.163.171",
    "rtspUrl": "rtsp://intellve:Intellve$WSP2023@122.176.163.171:554/unicast/c4/s1/live",
    "streamName": "ae3a17a0-aea3-46a3-8bc0-0519c4d2c33a",
    "zoneType": ""
  }
]
export default function AlertMosaicView1({hootWay, setHootWay, associateCam, setAssociateCam, ResetAlertList ,isDarkTheme, randomProp}) {
console.log("hootWay",hootWay)
    const options = [
        { label: "Grapes 🍇", value: "grapes" },
        { label: "Mango 🥭", value: "mango" },
        { label: "Strawberry 🍓", value: "strawberry" },
      ];
      const cameraObject = {
        Abbreviation: "IST",
      BranchID: "888888",
      BrandName: "CP Plus",
      CCTVType: "NVR",
      CameraBrandId: 2,
      CameraName: "Cp Cam 2",
      CommunicationPort: 25001,
      DeviceTypeID: 1,
      IsPTZ: "0",
      Location: "Near Railway station, Ghatkopar",
      OffsetMinutes: 330,
      ParentCameraID: 1242,
      RTSPURL: "rtsp://admin:2o2oImaging@192.168.0.64:554/cam/realmonitor?channel=2%38subtype=1",
      ShowHooter: true,
      ShowTwoWay: false,
      SiteID: 194,
      SiteName: "Intellve 102",
      StreamName: "2_ca1bbdda-3d92-43be-b4e1-5edc0643b074",
      StreamingURL: null,
      TimeZoneId: 1,
      camPassword: "2o2oImaging",
      camPort: "554",
      camUserName: "admin",
      channelNum: "2",
      httpPort: 80,
      isChecked: false,
      isPlaybackTranscoded: "0",
      isTranscoded: "0",
      playbackURL: null,
      privateIPAddress: "192.168.0.64"
      }
      const cameraObj = {
        Abbreviation: "IST",
        BranchID: "888888",
        BrandName: "CP Plus",
        CCTVType: "NVR",
        CameraBrandId: 2,
        CameraName: "Cp Cam 3",
        CommunicationPort: 25001,
        DeviceTypeID: 1,
        IsPTZ: "None",
        Location: "Near Railway station, Ghatkopar",
        OffsetMinutes: 330,
        ParentCameraID: 1243,
        RTSPURL:
          "rtsp://admin:2o2oImaging@192.168.0.64:554/cam/realmonitor?channel=3%38subtype=1",
        ShowHooter: true,
        ShowTwoWay: false,
        SiteID: 194,
        SiteName: "Intellve 102",
        StreamName: "2_fb70b9a6-6495-456d-a4ee-7e95034a7443",
        StreamingURL: null,
        TimeZoneId: 1,
        camPassword: "2o2oImaging",
        camPort: "554",
        camUserName: "admin",
        channelNum: "3",
        httpPort: 9090,
        isChecked: false,
        isPlaybackTranscoded: "0",
        isTranscoded: "0",
        playbackURL: null,
        privateIPAddress: "192.168.0.64",
      };
    
     // useState start
  const [selected, setSelected] = useState([]);
  const [alertcameraDetailShow, setalertcameraDetailShow] = useState(true);
  const [alserMosaicOption, setAlertMosaicOption] = useState(1) // option value
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
  const [alertTypeOptions, setAlertTypeOptions] = useState([]);
  const [severityOptions, setSeverityOptions] = useState([]);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [deviceNameOptions, setDeviceNameOptions] = useState([])
  const [customerSelected, setCustomerSelected] = useState([]);
  const [siteSelected, setSiteSelected] = useState([]);
  const [deviceTypeSelected, setDeviceTypeSelected] = useState([]);
  const [alertTypeSelected, setAlertTypeSelected] = useState([]);
  const [severitySelected, setSeveritySelected] = useState([]);
  const [timezoneSelected, setTimezoneSelected] = useState([]);
  const [deviceNameSelected, setDeviceNameSelected] = useState([]);
  const [singleAlertDet, setSingleAlertDet] = useState(null);
  const [updateChatflow, setUpdateChatflow] = useState(0);
  const [imageData, setImageData] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [filterName, setFilterName] = useState("")
  const [alertInProgress, setAlertInProgress] = useState(false)
  const [shouldPollData, setShouldPollData] = useState(false);
  const [switchAlertModal, setSwitchAlertModal] = useState(false);
  const [alertClicked, setAlertClicked] = useState("");
  const messagesEndRef = useRef(null);
  const [singleAlertDetChatFlow, setSingleAlertDetChatFlow] = useState(null)
  const [filterList,setFilterList] = useState([])
  const [deleteFilter,setDeleteFilter] = useState([])
   const [saveDisabled,setSaveDisabled]=useState(false)
   const [openInfoFilter, setOpenInfoFilter] = useState(false);
  const [openDeleteFilter, setOpenDeleteFilter] = useState(false);
  const[selectedFilterName,setSelectedFilterName] = useState("")
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [customerString, setCustomerString] = useState("")
  const [siteString, setSiteString] = useState("")
  const [deviceTypeString, setDeviceTypeString] = useState("")
  const [alertTypeString, setAlertTypeString] = useState("")
  const [severityString, setSeverityString] = useState("")
  const [statusString, setStatusString] = useState("1,2")
  const [modalFilterName, setModalFilterName] = useState("")
  const [modalFilterId, setModalFilterId] = useState(0)
  const [maxAlertId, setMaxAlertId] = useState(0);
  const [associateData, setAssociateData] = useState([]); 
  const [filterApplied, setFilterApplied] = useState(false);
  const [customerMasterArr,setCustomerMasterArr]=useState([])
  const [customerInfoArr,setCustomerInfoArr]=useState([])
  const [siteMasterArr,setSiteMasterArr]=useState([])
  const [siteInfoArr,setSiteInfoArr]=useState([])
  const [deviceTypeMasterArr,setDeviceTypeMasterArr]=useState([])
  const [devieceTypeInfoArr,setDeviceTypeInfoArr]=useState([])
  const [alertTypeMasterArr,setalertTypeMasterArr]=useState([])
  const [alertTypeInfoArr,setAlertTypeInfoArr]=useState([])
  const [alertSeverityMasterArr,setAlertSeverityMasterArr]=useState([])
  const [alertSeverityInfoArr,setAlertSeverityInfoArr]=useState([])
  const [customerSearch,setCustomerSearch]=useState("")
  const [siteSearch,setSiteSearch]=useState("")
  const [deviceTypeSearch,setDeviceTypeSearch]=useState("")
  const [alertTypeSearch,setAlertTypeSearch]=useState("")
  const [alertSeveritySearch,setAlertSeveritySearch]=useState("")
  const [alertToClose, setAlertToClose] = useState([]);
  const CLIENT = "Client"
  const SITE = "Site"
  const DEVICE_TYPE = "Device Type"
  const ALERT_TYPE = "Alert Type"
  const ALERT_SEVERITY = "Alert Severity"

  // const [containerWidth, setContainerWidth] = useState(0);

  console.log("randomProp", randomProp)

  const filterToolDDLProps = {
    visibleSavedfilter: false,
    savedFilterProps: {
      labelledBy: "Saved Filter",
      options: options,
      value: [],
      onChange: (value) => setSelected(value),
      valueRenderer: null
    },
    filterList: [
      {
        labelledBy: "Customer",
        options: customerOptions,
        value: customerSelected,
        onChange: (value) => setCustomerSelected(value),
        // onChange: (value) => dispatch(cameraActions?.setCustomerSelected(value)),
        valueRenderer: null,
        className: customerSelected.length > 0 ? "dropdownSelectionContainer multi-select" : "multi-select",
      },
      {
        labelledBy: "Site",
        options: siteOptions,
        value: siteSelected,
        onChange: (value) => setSiteSelected(value),
        valueRenderer: null,
        className: siteSelected.length > 0 ? "dropdownSelectionContainer multi-select" : "multi-select",
      },
      {
        labelledBy: "Device Type",
        options: deviceTypeOptions,
        value: deviceTypeSelected,
        onChange: (value) => setDeviceTypeSelected(value),
        valueRenderer: null,
        className: deviceTypeSelected.length > 0 ? "dropdownSelectionContainer multi-select" : "multi-select",
      },
      {
        labelledBy: "Alert Type",
        options: alertTypeOptions,
        value: alertTypeSelected,
        onChange: (value) => setAlertTypeSelected(value),
        valueRenderer: null,
        className: alertTypeSelected.length > 0 ? "dropdownSelectionContainer multi-select" : "multi-select",
      },
      // {
      //   labelledBy: "Device Name",
      //   options: deviceNameOptions,
      //   value: deviceNameSelected,
      //   onChange: (value) => setDeviceNameSelected(value),
      //   valueRenderer: null
      // },
      {
        labelledBy: "Alert Severtity",
        options: severityOptions,
        value: severitySelected,
        onChange: (value) => setSeveritySelected(value),
        valueRenderer: null,
        className: severitySelected.length > 0 ? "dropdownSelectionContainer multi-select" : "multi-select",

      },
      // {
      //   labelledBy: "Timezone",
      //   options: timezoneOptions,
      //   value: timezoneSelected,
      //   onChange: (value) => setTimezoneSelected(value),
      //   valueRenderer: null
      // },
    ]
  }

  //region useEffect start
  // Use Effect for getting initial data
  useEffect(() => {
    if(selectedFilter != 0){
      let selected = filterList.find((item) => item.FilterId == selectedFilter);
      console.log("selected", selected);
      if(selected != null){
        extractIds(selected.FilterValues);

      }
    }
  }, [selectedFilter]);
  useEffect(() => {
    // API Call for customer list
    getCustomerList()
      .then((res) => {
        console.log("customer", res);
        if (res.Status == "success") {
          let arr = res.Data.map((item) => ({
            value: item.ClientId,
            label: item.ClientName
          }))
          setCustomerOptions(arr);
        }
      })
      .catch(err => console.log(err))

    // API Call for Severity List
    getSeverity()
      .then((res) => {
        console.log("severity", res);
        if (res.Status == "success") {
          let arr = res.Data.map((item) => ({
            value: item.SeverityId,
            label: item.SeverityTitle
          }))
          setSeverityOptions(arr);
        }
      })
      .catch(err => console.log(err))

    // API Call for Device Type List
    getidsmasterslist();
    getTimeZoneList();
    getInitialAlertData();
    getFilterList();
    getAlertType();
  }, [])

  useEffect(() => {
    if(shouldPollData){
      console.log("GRIDPOLL", shouldPollData)
      getAlertData(alserMosaicOption, customerString, siteString, deviceTypeString, alertTypeString, "", severityString, maxAlertId);
    }
  }, [shouldPollData])


  // Auto close alert (Parent Component way)
  // useEffect(() => {
  //   let timer;
  //   timer = setInterval(() => {
  //     if(AUTO_ALERT_CLOSE){
  //       console.log("masterDataCLOSEDALERTARR", masterData);
  //       // alertCloseBulk(masterData);
  //     }
  //   }, 10000);

  //   return () => {
  //     clearInterval(timer);
  //   }
  // },[masterData])
   
  // Auto close alert (Child Component way)
  useEffect(() => {
    let timer
    timer = setInterval(() => {
      if(AUTO_ALERT_CLOSE){
        console.log("EmptyArr", alertToClose);
        console.log("EmptyArr", data);
        if(alertToClose.length > 0){
          let arr = data.map((item) => {
            if(alertToClose.includes(item.alertId)){
              return { "closed" : true }
            }
            return item;
          })
          let loggedInUser = JSON.parse(localStorage.getItem('user'))
          Promise.all(alertToClose.map((i) => closedAlertGridBtn(i, loggedInUser?.UserID)))
            .then((res) => {
              console.log("EmptyArr", res);
              if(res[0].Status == "success"){
                console.log("EmptyArr", arr);
                SuccessToast(`${alertToClose.length > 1 ? + alertToClose.length + " alerts": "1 alert"} auto closed.`)
                setData(arr)
                setMasterData(arr)
                setShouldPollData(true);    
                setAlertToClose([]);
              }
            })
        }
      }
    }, 5000)

    return () => {
      clearInterval(timer);
    }
  }, [alertToClose, masterData, data])

  useEffect(() => {
    if(customerSelected.length > 0){
      // Call API for getting sitedata.
      let customerIds = customerSelected.map((item) => item.value).join(',');
      console.log('customerIds', customerIds);
      getSiteList(customerIds)
        .then((res) => {
          if(res.Status == "success"){
            let arr = res.Data.map((item) => ({
              value: item.SiteID,
              label: item.SiteName
            }))
            setSiteOptions(arr);
          }
        })
        .catch(err => console.log(err));
    }else{
      setSiteOptions([]);
    }
  }, [customerSelected])

  useEffect(() => {
    if(deviceTypeSelected.length > 0){
      let alertTypeIds = deviceTypeSelected.map((item) => item.value).join(',');
      console.log('checkdata', alertTypeIds);

    }else{
      // setAlertTypeOptions([]);
    }
  }, [deviceTypeSelected]);

  console.log("CHECKPOLL", shouldPollData)
  useEffect(() => {
    let timer = setInterval(() => {
      // console.log("MasterData check master list", masterData);
      // console.log("MasterData check mosaicOption", alserMosaicOption);
      if(alserMosaicOption == 1){
        //Logic based on 9 alerts
        let temp = checkClosedAlert(masterData)
        console.log("MasterData check mosaicOption", temp);
        if(masterData.length < 9 || temp){
          console.log("MasterData check mosaicOption in if clause", temp);
          setShouldPollData(true);
        }
      }else{
        //Logic based on 16 alerts
        let temp = checkClosedAlert(masterData)
        // console.log("MasterData check mosaicOption", temp);
        if(masterData.length < 16 || temp){
          setShouldPollData(true);
        }
      }
    }, ALERT_INTERVAL_TIMER);
    return () => {
      clearInterval(timer);
    }
  }, [masterData, alserMosaicOption]);

  // useEffect(() => {
  //   const adjustContainerWidth = () => {
  //     const mainContainer = document.getElementById('mainContainer');
  //     const contents = mainContainer.children;
  //     let totalWidth = 0;

  //     for (let content of contents) {
  //       totalWidth += content.offsetWidth + 20;
  //     }

  //     setContainerWidth(Math.min(totalWidth, 1410));
  //   };

  //   // adjustContainerWidth();
  //   window.addEventListener('resize', adjustContainerWidth);

  //   return () => window.removeEventListener('resize', adjustContainerWidth);
  // }, []);

  const getAlertType = () => {
    getFilterAlertTypeList()
      .then((res) => {
        if(res.Status == "success"){
          let arrTwo = res.Data.map((item) => ({
            value: item.AlertTypeId,
            label: item.AlertTypeName
          }))
          setAlertTypeOptions(arrTwo);
        }
      })
      .catch(err => console.log(err));
  }


  const checkClosedAlert = (arr) => {
    console.log("MasterData", arr)
    return arr.find(item => item.hasOwnProperty('noAlertFound') || item.hasOwnProperty('closed'));
    // return arr.find(item => item?.noAlertFound != undefined && item.noAlertFound);
  }

  useEffect(()=>{
    if(customerSelected.length > 0 || siteSelected.length > 0 || deviceTypeSelected.length > 0 || alertTypeSelected.length > 0 || severitySelected.length > 0) {
      setSaveDisabled(true)
      if(selectedFilterName != ""){
        clearSelectedFilter();
      }else{
        setSelectedFilterName("");
      }
    }else{
      setSaveDisabled(false)
    }
    if(customerSelected.length == 0 && siteSelected.length == 0 && deviceTypeSelected.length == 0 && alertTypeSelected.length == 0 && severitySelected == 0 && filterApplied){
      setFilterApplied(false);
      setCustomerString("");
      setSiteString("");
      setDeviceTypeString("");
      setAlertTypeString("");
      setSeverityString("");
      getInitialAlertData(alserMosaicOption,"", "", "", "", "", "","" );
    }

  },[customerSelected,siteSelected,deviceTypeSelected,alertTypeSelected,severitySelected])
  //#endregion useEffect end

  //Function for calling api of devicetype list.
  const extractIds = (str) => {
      getDataFromFilter(str);
  }

  const getDataFromFilter = (str) => {
    let arr = str.split(";");
    let cString, sString, dString, aTString, aSString, statusString = "";
    for(let i = 0; i < arr.length; i++){
      if(arr[i].split(":")[0] == "Client"){
        cString = arr[i].split(":")[1]
      }else if(arr[i].split(":")[0] == "Site"){
        sString = arr[i].split(":")[1]
      }else if(arr[i].split(":")[0] == "DeviceType"){
        dString = arr[i].split(":")[1]
      }else if(arr[i].split(":")[0] == "AlertType"){
        aTString = arr[i].split(":")[1]
      }else if(arr[i].split(":")[0] == "AlertSeverity"){
        aSString = arr[i].split(":")[1]
      }   
      else if(arr[i].split(":")[0] == "Status"){
        statusString = arr[i].split(":")[1]
      }   
    }
    setCustomerString(cString);
    setSiteString(sString);
    setDeviceTypeString(dString);
    setAlertTypeString(aTString);
    setSeverityString(aSString);
    setStatusString(statusString);
    getInitialAlertData(alserMosaicOption,cString, sString, dString, aTString, "", aSString, true);
  }

  const getidsmasterslist = async () => {
    try {
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        var obj = {
            "action": "getDevicesMasterList",
            "userUniqueId": loggedInUser.UserUniqueID || loggedInUser.userUniqueID
        }
        await deviceconfigcrud(obj).then((resp) => {
            if (resp.status == "Ok") {
                if (resp.message != null) {
                  console.log("deviceTypeList", resp.message.deviceTypeList);
                  let arr = resp.message.deviceTypeList.map((item) => ({
                    value: item.deviceTypeID,
                    label: item.deviceType
                  }))
                  setDeviceTypeOptions(arr);

                  // let arrTwo = resp.message.alertCodeList.map((item) => ({
                  //   value: item.alertCodeId,
                  //   label: item.alertAssociated
                  // }))
                  // setAlertTypeOptions(arrTwo);

                }
            }
        })
    } catch (error) {
        console.log(error)
    }
  }

  const getTimeZoneList = () => {
    try {
        GetmultiTimeZone().then((res) => {
            //debugger
            console.log("getTimeZoneList", res)
            if (res != undefined) {
              if (res.Status == "success") {
                let arr = res.Data.map((item) => ({
                  value: item.TimeZoneId,
                  label: item.Abbreviation
                }))
                setTimezoneOptions(arr)
              } else {
                // setTimeZone(null)
              }
            }
          })
    } catch (error) {
        console.error(error)
    }
}


  const onMosaicTypeChange = (e) => {
  console.log("onMosaicTypeChange", e.target.value);
  let data = e.target.value;
  if(alertInProgress){
    Swal.fire({
      title: 'Unable to change grid structure.',
      text: "Kindly close the alert you are working on before changing the alert grid structure!",
      icon: 'warning',
      //showCancelButton: true,
      confirmButtonColor: '#027aaa',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok',
      allowOutsideClick: false
    }).then((result) => {
      if(result.isConfirmed){
        return
        console.log("onMosaicTypeChange", data);
        setAlertMosaicOption(data)
        getInitialAlertData(data,customerString,siteString,deviceTypeString,alertTypeString,"",severityString);
        setSingleAlertDet(null)
        setAlertInProgress(false);
      }else{
        return
      }
    })
  }else{
    setAlertMosaicOption(e.target.value)
    getInitialAlertData(e.target.value,customerString,siteString,deviceTypeString,alertTypeString,"",severityString);
    setSingleAlertDet(null)
    setAlertInProgress(false);
}
}

const getAlertData = async (value=alserMosaicOption,customerIds="",siteIds="",deviceTypeIds="",alertTypeIds="",deviceNameIds="",severityIds="", lastAlertId=maxAlertId) => {
  let numberOfElements = value == 2 ? 16 : 9;
  try {
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    var obj = {
        "action": "getAlertListNinePoll",
        "severity": "all",
        "userUniqueId": loggedInUser?.UserUniqueID,
        "startIndex": 0,
        "numberOfElements": numberOfElements,
        "authCode": localStorage.getItem('authCode'),
        "customerIds":customerIds,
        "siteIds":siteIds,
        "deviceTypeIds":deviceTypeIds,
        "alertTypeIds":alertTypeIds,
        "deviceNameIds":deviceNameIds,
        "severityIds":severityIds,
        "lastAlertId": lastAlertId,
        "loadBalance": loggedInUser?.ApplicationFlag || "0"
        // "lastAlertId": "10510"
    }
    await getAlertList(obj).then((resp) => {
        if (resp.status == "Ok") {
            if (resp.message) {
              console.log("getAlertData", resp.message);
              // Check the master data and find the not selected data and replace it with the new alerts data.
              
              // Check if new alerts are there or not.
              if(resp.message.length > 0){
              let dockStartDate = new Date(); 
              if(alserMosaicOption == 1){
                //Check for 9 alerts in masterdata
                if(masterData.length < 9){
                  let arr = masterData
                  let counter = 0;
                  for(let i=0; i < 9 - masterData.length; i++){
                    if(counter >= resp.message.length){
                      break;                
                    }
                    let obj = {
                      ...resp.message[counter++],
                      isSelected: false,
                      dockStartDateTime: dockStartDate.getTime()
                    }
                    arr.push(obj);
                  }
                  getMaxAlertId(arr);

                  getStatus(arr)
                  // setMasterData(arr);
                  // setData(arr);

                  if(arr.length == 16){
                    // setShouldPollData(false);
                  }
                }else if(masterData.length == 9){
                  let counter = 0;
                  console.log("CHECKDATA", masterData)
                  let tempArr = masterData.map((i) => {
                    if(resp.message.length <= counter){
                      return i;
                    }
                    if(i.noAlertFound || i.closed){ 
                      return {
                        ...resp.message[counter++],
                        isSelected: false,
                        dockStartDateTime: dockStartDate.getTime()
                      }
                    }else{
                      return i;
                    }
                  });
                  getMaxAlertId(tempArr)

                  getStatus(tempArr);
                  // setMasterData(tempArr);
                  // setData(tempArr);

                  if(tempArr.length == 9){
                    // setShouldPollData(false);
                  }
                  // tempArr has the list of closed alerts, we now need to replace it with the new alerts
                }
              }else{
                //Check for 16 alerts in masterdata
                if(masterData.length < 16){
                  let arr = masterData
                  console.log("DEBUG", arr)
                  let counter = 0;
                  for(let i=0; i < 16 - masterData.length; i++){
                    if(counter >= resp.message.length){
                      break;                
                    }
                    let obj = {
                      ...resp.message[counter++],
                      isSelected: false,
                      dockStartDateTime: dockStartDate.getTime()
                    }
                    arr.push(obj);
                  }
                    getMaxAlertId(arr)

                    getStatus(arr)
                    // setMasterData(arr);
                    // setData(arr);

                    if(arr.length == 16){
                      // setShouldPollData(false);
                    }
                }else if(masterData.length == 16){
                  let counter = 0;
                  let tempArr = masterData.map((i) => {
                    if(counter >= resp.message.length){
                      return i;
                    }
                    if(i.noAlertFound || i.closed){
                      return {
                        ...resp.message[counter++],
                        isSelected: false,
                        dockStartDateTime: dockStartDate.getTime()
                      }
                    }else{
                      return i;
                    }
                  });
                  getMaxAlertId(tempArr)

                  getStatus(tempArr)
                  // setMasterData(tempArr);
                  // setData(tempArr);

                  if(tempArr.length == 16){
                    // setShouldPollData(false);
                  }
                }
              } 
              }else{
                let arr = masterData.map((i) => {
                  if(i.closed){
                    return {
                      "noAlertFound" : true
                    }
                  }
                  return i;
                })
                console.log("CLOSEDALERTARR", arr);
                getMaxAlertId(arr);
                getStatus(arr);

                // setMasterData(arr);
                // setData(arr);
              }

              setShouldPollData(false);
            }
        }
        //To be uncommented later.
        else{
          getStatus(masterData)
          setShouldPollData(false);
        }
    })
} catch (error) {
    console.error(error)
}
}

const getInitialAlertData = async (value=alserMosaicOption,customerIds="",siteIds="",deviceTypeIds="",alertTypeIds="",deviceNameIds="",severityIds="", showToast=false) => {
  console.log("SHOWTOAST", showToast);
  let numberOfElements = value == 2 ? 16 : 9;
  try {
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    var obj = {
        "action": "getAlertListNine",
        "severity": "all",
        "userUniqueId": loggedInUser?.UserUniqueID,
        "startIndex": 0,
        "numberOfElements": numberOfElements,
        "authCode": localStorage.getItem('authCode'),
        "customerIds":customerIds,
        "siteIds":siteIds,
        "deviceTypeIds":deviceTypeIds,
        "alertTypeIds":alertTypeIds,
        "deviceNameIds":deviceNameIds,
        "severityIds":severityIds,
        "loadBalance": loggedInUser?.ApplicationFlag || "0"
    }
    await getAlertList(obj).then((resp) => {
        if (resp.status == "Ok") {
            if (resp.message) {
              let dockStartDate = new Date();
              let tempArr = resp.message.map((item) => {
                return {
                  ...item,
                  isSelected: false,
                  dockStartDateTime: dockStartDate.getTime() 
                }
              })
              setData(tempArr);
              setMasterData(tempArr);
              console.log("tempArr",tempArr)
              getMaxAlertId(tempArr)
              console.log("SHOWTOAST", showToast);
              if(showToast){
                SuccessToast("Filter applied successfully.")
                console.log("FILTER", loggedInUser?.ApplicationFlag);
                console.log("FILTER", loggedInUser?.UserRoleID)
                if(loggedInUser?.ApplicationFlag == "1" && loggedInUser?.UserRoleID == 3){
                  WarningToast("Since load balancing is enabled, applying filter is not recommended.")
                }
              }

              // if(numberOfElements == 9){
              //   if(tempArr.length == 9){
              //     setShouldPollData(false);
              //   }else{
              //     setShouldPollData(true);
              //   }
              // }else{
              //   if(tempArr.length == 16){
              //     setShouldPollData(false);
              //   }else{
              //     setShouldPollData(true);
              //   }
              // }
            }
        }
    })
} catch (error) {
    console.error(error)
}
}
const getMaxAlertId = (tempArr) =>{
  console.log("CLOSEDALERTARR",tempArr)
  if(tempArr.length == 0){
    setMaxAlertId(0);
    return;
  }
  let highestAlertId = Math.max(...tempArr.map(obj => {
    return obj.hasOwnProperty("alertId") ? obj.alertId : 0
  }));
  console.log("highestAlertId",highestAlertId)
  setMaxAlertId(highestAlertId);
}

const getAlertDetails = async (alertId) => {
  try {
    await getAlertDetailsById(alertId).then((resp) => {
      if (resp.status == "Ok") {
        if (resp.message) {
          console.log("resp.message", resp.message[0]);
          setSingleAlertDet(resp.message[0])
          setSingleAlertDetChatFlow(resp.message[0])
          if(resp.message[0].associatedCamList.length > 0){
            console.log("Associated", resp.message[0].associatedCamList);
            setAssociateData(resp.message[0].associatedCamList)
          }
          // props.setCctvTypeName(resp.message[0].cctvtype)
          // props.setCamUserName(resp.message[0].camUserName)
          // props.setCamPassword(resp.message[0].camPassword)
          // props.setBrandName(resp.message[0].brandName)
          if (CHAT_CAM_CHANGES) {
            let secondObj = {
              alertId: alertId,
              alertDetail: resp.message[0],
              display: false,
              logText: "",
              shouldRefresh: false
            }
            setAssociateCam([...associateCam, secondObj]);
          }
        }
      }
    })
  } catch (error) {
    console.error(error)
  }
}
const getAlertChatFlowDetails = async (alertId) => {
  try {
    await getAlertDetailsById(alertId).then((resp) => {
      if (resp.status == "Ok") {
        if (resp.message) {
          setSingleAlertDetChatFlow(resp.message[0])
          // props.setCctvTypeName(resp.message[0].cctvtype)
          // props.setCamUserName(resp.message[0].camUserName)
          // props.setCamPassword(resp.message[0].camPassword)
          // props.setBrandName(resp.message[0].brandName)
          if (CHAT_CAM_CHANGES) {
            let secondObj = {
              alertId: alertId,
              alertDetail: resp.message[0],
              display: false,
              logText: "",
              shouldRefresh: false
            }
            setAssociateCam([...associateCam, secondObj]);
          }
        }
      }
    })
  } catch (error) {
    console.error(error)
  }
}

const chatFlowClicked = (alertId, byPass = false) => {
  console.log("chatFlowClicked", alertInProgress)
  console.log("chatFlowClicked", byPass)
  if(alertInProgress && !byPass){
    setSwitchAlertModal(true);
    setAlertClicked(alertId);
  }else{
    console.log("chatFlowClicked", alertId)
    getAlertDetails(alertId);
    let arr = data.map((item) => {
      if(item.alertId == alertId){
        return {
          ...item,
          isSelected: true
        }
      }else{
        return {
          ...item,
          isSelected: false
        }
      }
    })
    setData(arr);
    setMasterData(arr);
    setAlertInProgress(true);
    // setShouldPollData(false);
    setSwitchAlertModal(false);
  }
}

const scrollToBottom = () => {
  setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "auto" })
  }, 1000)
}

const getDate = () => {
  let d;
  if (MULTI_TIME_ZONE) {
      let offsetTemp = singleAlertDet.OffsetMinutes != null ? singleAlertDet.OffsetMinutes : 330
      d = multiTimeZoneHelper(offsetTemp);
  } else {
      d = new Date();
  }
  return d
}

const attachEvidence = (text) => {
  let d = getDate();
  let myCurrentDatetime = d;
  let _datetime = ConvertDate(myCurrentDatetime)
  let rasaconvobj = {
      "evidenceType": "comment",
      "text": `${text}.`,
      "timestamp": _datetime,
      "type": "user"
  }
  let LoggedInUser = JSON.parse(localStorage.getItem('user'))
  let ConversationId = "AlertID_" + singleAlertDet.alertId + "_UserID_" + localStorage.getItem("UserID")
  addrasaconversationindb({
      "assignedTo": LoggedInUser.UserID,
      "rasaConversationID": ConversationId,
      "uploadType": "file",
      "evidence": rasaconvobj,
      "conversation": null,
      "userType": "operator",
      "createdOn": _datetime
  }).then((resp) => {
      if (resp.status === "Ok") {
          let temp = updateChatflow + 1;
          setUpdateChatflow(temp);
      }
  }).catch(err => console.log(err));
}

const AttachalertevidencesParent = (e, fileName) => {
  setImageData(e);
  setFileName(fileName);
}

const clearFilterList = () => {
  setTimezoneSelected([]);
  setSeveritySelected([]);
  setDeviceTypeSelected([]);
  setAlertTypeSelected([]);
  setSiteSelected([]);
  setCustomerSelected([]);
  setDeviceNameSelected([]);
  setCustomerString("");
  setSiteString("");
  setDeviceTypeString("");
  setAlertTypeString("");
  setSeverityString("");
  setStatusString("1,2");
  getInitialAlertData(alserMosaicOption,"", "", "", "", "", "","" );
}
const clearSelectedFilter = () => {
  if(alertInProgress){
    Swal.fire({
      title: 'Unable to clear filter.',
      text: "Kindly close the alert you are working on before clearing the filter selection!",
      icon: 'warning',
      //showCancelButton: true,
      confirmButtonColor: '#027aaa',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok',
      allowOutsideClick: false
    }).then((result) => {
      if(result.isConfirmed){
        return
        setCustomerString("");
        setSiteString("");
        setDeviceTypeString("");
        setAlertTypeString("");
        setSeverityString("");
        setStatusString("1,2");
        setSelectedFilterName("")
        setSelectedFilter(0)
        getInitialAlertData(alserMosaicOption,"", "", "", "", "", "","" );
        setAlertInProgress(false);
        setSingleAlertDet(null)
      }
    })
  }else{
    setCustomerString("");
    setSiteString("");
    setDeviceTypeString("");
    setAlertTypeString("");
    setSeverityString("");
    setStatusString("1,2");
    setSelectedFilterName("")
    setSelectedFilter(0)
    getInitialAlertData(alserMosaicOption,"", "", "", "", "", "","" );
  }
}

const applyFilter = () => {
  let customerIds = customerSelected.map((i) => i.value).join(",");
  let siteIds = siteSelected.map((i) => i.value).join(",");
  let deviceTypeIds = deviceTypeSelected.map((i) => i.value).join(",");
  let alertTypeIds = alertTypeSelected.map((i) => i.value).join(",");
  let deviceNameIds = deviceNameSelected.map((i) => i.value).join(",");
  let severityIds = severitySelected.map((i) => i.value).join(",");
  getInitialAlertData(alserMosaicOption,customerIds,siteIds,deviceTypeIds,alertTypeIds,deviceNameIds,severityIds)
}

const saveFilter = () => {
  if(filterName == ""){
    ErrorToast("Kindly Enter a Filter Name")
    return
  }
  if (customerSelected.length == 0 && siteSelected.length == 0 && deviceTypeSelected.length == 0 && alertTypeSelected.length == 0 && severitySelected.length == 0){
    ErrorToast("Kindly select at least one filter")
    return
  }
  let pType = "all alerts";
  let loggedInUser = JSON.parse(localStorage.getItem('user'))
  let clientIds = customerSelected.map((i) => i.value).join(",");
  let siteIds = siteSelected.map((i) => i.value).join(",");
  let deviceTypeIds = deviceTypeSelected.map((i) => i.value).join(",");
  let alertTypeIds = alertTypeSelected.map((i) => i.value).join(",");
  let severityIds = severitySelected.map((i) => i.value).join(",");
  let statusIds = "1,2";
  const fileterTextstring = `clientIds:${clientIds};siteIds:${siteIds};deviceTypeIds:${deviceTypeIds};alertTypeIds:${alertTypeIds};alertSeverityIds:${severityIds};statusIds:${statusIds}`;
  FilterSaved(filterName,pType,loggedInUser?.UserUniqueID,fileterTextstring).then((resp)=>{
    if (resp.Status == "success"){
      SuccessToast(resp.Message)
      setShow(false);
      setFilterName("");
      getFilterList();
    }else{
      ErrorToast(resp.Message)
    }
  }).catch(err => console.log(err));
  
}

const getFilterList = () => {
  let pType = "all alerts";
  let loggedInUser = JSON.parse(localStorage.getItem('user'))
  GetFilter(pType,loggedInUser?.UserUniqueID)
  .then((res)=>{
    if (res.Status == "success"){
      setFilterList(res.Data)
    }else{
      setFilterList([]);
    }
  }).catch(err => console.log(err))
  
}

const deleteFilterList = (pId) => {
 DeleteFilter(pId)
 .then((res)=>{
  if (res.Status == "success"){
    SuccessToast(res.Message)
    setModalFilterId(0)
          setModalFilterName("")
          setOpenDeleteFilter(false);
    getFilterList();

  }else{
    ErrorToast(res.Message)
  }
}).catch((err)=>{
  console.log(err);
})
}
const applyFilterOnClick = () => {
  if(alertInProgress){
    Swal.fire({
      title: 'Unable to apply filter.',
      text: "Kindly close the alert you are working on before applying a new filter!",
      icon: 'warning',
      //showCancelButton: true,
      confirmButtonColor: '#027aaa',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok',
      allowOutsideClick: false
    }).then((result) => {
      if(result.isConfirmed){
        return
        let customerIds = customerSelected.map((i) => i.value).join(",");
        setCustomerString(customerIds);
        let siteIds = siteSelected.map((i) => i.value).join(",");
        setSiteString(siteIds)
        let deviceTypeIds = deviceTypeSelected.map((i) => i.value).join(",");
        setDeviceTypeString(deviceTypeIds)
        let alertTypeIds = alertTypeSelected.map((i) => i.value).join(",");
        setAlertTypeString(alertTypeIds)
        let deviceNameIds = deviceNameSelected.map((i) => i.value).join(",");
        let severityIds = severitySelected.map((i) => i.value).join(",");
        setSeverityString(severityIds);
        let statusIds = statusString;
        setFilterApplied(true);
        setAlertInProgress(false);
        setSingleAlertDet(null)
        getInitialAlertData(alserMosaicOption,customerIds, siteIds, deviceTypeIds, alertTypeIds, deviceNameIds, severityIds,true);
      }else{
        return;
      }
    })
  }else{
    let customerIds = customerSelected.map((i) => i.value).join(",");
    setCustomerString(customerIds);
    let siteIds = siteSelected.map((i) => i.value).join(",");
    setSiteString(siteIds)
    let deviceTypeIds = deviceTypeSelected.map((i) => i.value).join(",");
    setDeviceTypeString(deviceTypeIds)
    let alertTypeIds = alertTypeSelected.map((i) => i.value).join(",");
    setAlertTypeString(alertTypeIds)
    let deviceNameIds = deviceNameSelected.map((i) => i.value).join(",");
    let severityIds = severitySelected.map((i) => i.value).join(",");
    setSeverityString(severityIds);
    let statusIds = statusString;
    setFilterApplied(true);
    getInitialAlertData(alserMosaicOption,customerIds, siteIds, deviceTypeIds, alertTypeIds, deviceNameIds, severityIds, true);
  }
}

const alertClosed = (alertId) => {
  console.log("alertIdseek",alertId)
  let arr = data.map((item) => {
    if(item.alertId == alertId){
      return { "closed" : true }
    }
    return item;
  })
  setData(arr);
  setMasterData(arr);
  setSingleAlertDet(null)
  setShouldPollData(true);
  setAlertInProgress(false);
  setImageData(null)
  setFileName(null)
  setSingleAlertDetChatFlow(null);
}

const closedAlertBtn = (alertId) =>{
  let loggedInUser = JSON.parse(localStorage.getItem('user'))
  closedAlertGridBtn(alertId,loggedInUser?.UserID).then((res)=>{
    if (res.Status == "success"){
      let arr = data.map((item) => {
        if(item.alertId == alertId){
          return { "closed" : true }
        }
        return item;
      })
  setData(arr);
  setMasterData(arr);
  setShouldPollData(true);
    }
    })
}

const alertCloseBulk = (arr) => {
  // let temp = 
  console.log("CLOSEDALERTARR", arr);
  let closeAlertArr = [];
  let tempArr = arr.map((item) => {
    let currentTIme = new Date();
    if(item?.isSelected){
      return {
        ...item,
      }
    }else if(item?.noAlertFound){
      return {
        ...item,
      }
    }
    else{
      if(currentTIme.getTime() - item.dockStartDateTime > 20000){
        closeAlertArr.push(item.alertId)
        return { "closed" : true }
      }else{
        return {
          ...item
        }
      }
    }
  })
  // console.log("CLOSEDALERTARR", tempArr);
  // console.log("CLOSEDALERTARR", closeAlertArr);
  let loggedInUser = JSON.parse(localStorage.getItem('user'))
  if(closeAlertArr.length > 0){
    Promise.all(closeAlertArr.map((i) => closedAlertGridBtn(i, loggedInUser?.UserID)))
      .then((res) => {
        // console.log("CLOSEDALERTARR", res);
        if(res[0].Status == "success"){
          console.log("CLOSEDALERTARR", tempArr);
          setData(tempArr)
          setMasterData(tempArr)
          setShouldPollData(true);
        }
      })
  }
}

const items = [
  <div className="item" data-value="1">
    <div className="chatflow">
                        <WebRTCPlayer
                        key={cameraObject.deviceId}
                        id={cameraObject?.deviceId + "34"}
                        devId={cameraObject?.deviceId}
                        url={cameraObject?.rtspUrl}
                        isEncode={cameraObject?.isTranscoded}
                        item={cameraObject}
                        actualTime={cameraObject.actualTime}
                        alertDetail={cameraObject}
                        upperDivCss="cameraHoverMainDiv"
                        videoCss="player-responsive1"
                        //videoCss="chatbotliveplayer"
                        fullVideoCss="player-responsive-fullScreen"
                        buttonCss="cameraButtonsMainDiv"
                        noStreamDivCss="no-stream-found"
                        // playback={false}
                        // prePostDisplay={false}
                        // liveButton={true}
                        // exportCurrentVideoHide={true}
                        // showPlaybackBtn={false}
                        // hideTwoWay={false}
                        // imageToChat={AttachalertevidencesParent}
                        // attachEvidence={attachEvidence}
                        // showDirectToChat={true}
                        // showHooterIcon={true}
                        // buttonDisable={buttonDisable}
                        />
    </div>
  </div>,
  <div className="item" data-value="2">
  <div className="chatflow">
                    <WebRTCPlayer
                    key={cameraObject.deviceId}
                    id={cameraObject?.deviceId + "34"}
                    devId={cameraObject?.deviceId}
                    url={cameraObject?.rtspUrl}
                    isEncode={cameraObject?.isTranscoded}
                    item={cameraObject}
                    actualTime={cameraObject.actualTime}
                    alertDetail={cameraObject}
                    upperDivCss="cameraHoverMainDiv"
                    videoCss="player-responsive1"
                    //videoCss="chatbotliveplayer"
                    fullVideoCss="player-responsive-fullScreen"
                    buttonCss="cameraButtonsMainDiv"
                    noStreamDivCss="no-stream-found"
                    // playback={false}
                    // prePostDisplay={false}
                    // liveButton={true}
                    // exportCurrentVideoHide={true}
                    // showPlaybackBtn={false}
                    // hideTwoWay={false}
                    // imageToChat={AttachalertevidencesParent}
                    // attachEvidence={attachEvidence}
                    // showDirectToChat={true}
                    // showHooterIcon={true}
                    // buttonDisable={buttonDisable}
                    />
                </div>
  </div>,
  <div className="item" data-value="3">
  <div className="chatflow">
                    <WebRTCPlayer
                    key={cameraObject.deviceId}
                    id={cameraObject?.deviceId + "34"}
                    devId={cameraObject?.deviceId}
                    url={cameraObject?.rtspUrl}
                    isEncode={cameraObject?.isTranscoded}
                    item={cameraObject}
                    actualTime={cameraObject.actualTime}
                    alertDetail={cameraObject}
                    upperDivCss="cameraHoverMainDiv"
                    videoCss="player-responsive1"
                    //videoCss="chatbotliveplayer"
                    fullVideoCss="player-responsive-fullScreen"
                    buttonCss="cameraButtonsMainDiv"
                    noStreamDivCss="no-stream-found"
                    // playback={false}
                    // prePostDisplay={false}
                    // liveButton={true}
                    // exportCurrentVideoHide={true}
                    // showPlaybackBtn={false}
                    // hideTwoWay={false}
                    // imageToChat={AttachalertevidencesParent}
                    // attachEvidence={attachEvidence}
                    // showDirectToChat={true}
                    // showHooterIcon={true}
                    // buttonDisable={buttonDisable}
                    />
  </div>
  </div>
];

const returnItems = (arr) => {
  console.log("returnItems", arr);
  return arr.map((item, index) => (
    <div className="item" data-value={index}>
    <div className="chatflow">
        <WebRTCPlayer
          key={item.associatedCameraId}
          id={item.associatedCameraId + "473"}
          devId={item.associatedCameraId}
          url={item.rtspUrl}
          // url="rtsp://admin:2o2oImaging@192.168.0.64:554/cam/realmonitor?channel=3%38subtype=1"
          isEncode={item.isTranscoded}
          actualTime={singleAlertDet.actualTime}
          alertDetail={singleAlertDet}
          item={item}
          upperDivCss="cameraHoverMainDiv"
          videoCss="player-responsive1"
          //videoCss="chatbotliveplayer"
          fullVideoCss="player-responsive-fullScreen"
          buttonCss="cameraButtonsMainDiv"
          noStreamDivCss="no-stream-found"

          playback={false}
          prePostDisplay={false}
          liveButton={true}
          exportCurrentVideoHide={true}
          showPlaybackBtn={false}
          hideTwoWay={false}
          imageToChat={AttachalertevidencesParent}
          attachEvidence={attachEvidence}
          showDirectToChat={true}
          showHooterIcon={true}
          // buttonDisable={buttonDisable}
        />
      </div>
  </div>
  ))
}

const renderDotsItem = ({ isActive }) => {
  return isActive ? 'x' : 'o';
};

const renderPrevButton = ({ isDisabled }) => {
  return <span style={{ opacity: isDisabled ? '0.5' : 1 }}>&lt;</span>;
};

const renderNextButton = ({ isDisabled }) => {
  return <span style={{ opacity: isDisabled ? '0.5' : 1 }}>&gt;</span>;
};

// const getStatus = (arr) => {
//   let tempArr = arr.filter((item) => item.hasOwnProperty('alertId')).map((i) => i.alertId);
//   let str = tempArr.join(",");
//   getLatestAlertStatus(str)
//     .then((res) => {
//       if(res.status == 'Ok'){
//         console.log("getStatus", res.message)        
//         if(res.message.length > 0){
//           let list = [];
//           for(let i=0; i<res.message.length; i++){
//             list.push(res.message[i][0]);
//           }
//           let masterDataTemp = masterData.map((item) => {
//             if(list.includes(item.alertId)){
//               return { "closed": true }
//             }
//             return item;
//           })
//           setData(masterDataTemp);
//           setMasterData(masterDataTemp);
//         }
//       }
//     })
//     .catch(err => console.log(err))
// }

const getStatus = (arr) => {
  console.log("CLOSEDALERTARR", arr);
  if(LOAD_BALANCE){
    setMasterData(arr);
    setData(arr);
  }else{
    let tempArr = arr.filter((item) => item.hasOwnProperty('alertId') && item.hasOwnProperty('isSelected') && !item.isSelected).map((i) => i.alertId);
    let str = tempArr.join(",");
    console.log("String", str)
    if(str == ""){
      setMasterData(arr);
      setData(arr);
      return;
    }
    getLatestAlertStatus(str)
      .then((res) => {
        if(res.status == 'Ok'){
          console.log("getStatus", res.message)        
          if(res.message.length > 0){
            let list = [];
            for(let i=0; i<res.message.length; i++){
              list.push(res.message[i][0]);
            }
            let masterDataTemp = masterData.map((item) => {
              if(list.includes(item.alertId)){
                return { "closed": true }
              }
              return item;
            })
            setData(masterDataTemp);
            setMasterData(masterDataTemp);
          }else{
            setMasterData(arr);
            setData(arr);
          }
        }else{
          setMasterData(arr);
          setData(arr);
        }
      })
      .catch(err => console.log(err))
  }
}

  const infoFilterList = (pId) => {
    getInfoList(pId)
      .then((res) => {
        if (res.Status == "success") {
          let temp = JSON.parse(res.Data)
          infoDataFilter(temp)
        } else {
          ErrorToast(res.Message)
        }
      }).catch((err) => {
        console.log(err);
      })
  }

  const infoDataFilter = (arr) => {
    let customerArr = arr.filter((item) => item.Entity == CLIENT);
    setCustomerInfoArr(customerArr)
    setCustomerMasterArr(customerArr)
    let siteArr = arr.filter((item) => item.Entity == SITE);
    setSiteInfoArr(siteArr)
    setSiteMasterArr(siteArr)
    let deviceTypeArr = arr.filter((item) => item.Entity == DEVICE_TYPE);
    setDeviceTypeInfoArr(deviceTypeArr)
    setDeviceTypeMasterArr(deviceTypeArr)
    let alertTypeArr = arr.filter((item) => item.Entity == ALERT_TYPE);
    setAlertTypeInfoArr(alertTypeArr)
    setalertTypeMasterArr(alertTypeArr)
    let alertSeverityArr = arr.filter((item) => item.Entity == ALERT_SEVERITY);
    setAlertSeverityInfoArr(alertSeverityArr)
    setAlertSeverityMasterArr(alertSeverityArr)

  }

  const searchCustomerHandler = (e) => {
    setCustomerSearch(e.target.value)
    let tempCustomer = customerMasterArr.filter(x => x.Values.toLowerCase().includes(e.target.value.toLowerCase()))
    setCustomerInfoArr(tempCustomer)
  }

  const searchSiteHandler = (e) => {
    setSiteSearch(e.target.value)
    let tempSite = siteMasterArr.filter(x => x.Values.toLowerCase().includes(e.target.value.toLowerCase()))
    setSiteInfoArr(tempSite)
  }

  const searchDeviceTypeHandler = (e) => {
    setDeviceTypeSearch(e.target.value)
    let tempDeviceType = deviceTypeMasterArr.filter(x => x.Values.toLowerCase().includes(e.target.value.toLowerCase()))
    setDeviceTypeInfoArr(tempDeviceType)
  }

  const searchAlertTypeHandler = (e) => {
    setAlertTypeSearch(e.target.value)
    let tempAlertType = alertTypeMasterArr.filter(x => x.Values.toLowerCase().includes(e.target.value.toLowerCase()))
    setAlertTypeInfoArr(tempAlertType)
  }

  const searchAlertSeverityHandler = (e) => {
    setAlertSeveritySearch(e.target.value)
    let tempAlertSeverity = alertSeverityMasterArr.filter(x => x.Values.toLowerCase().includes(e.target.value.toLowerCase()))
    setAlertSeverityInfoArr(tempAlertSeverity)
  }

  const refreshData = () => {
    if(alertInProgress){
      Swal.fire({
        title: 'Unable to refresh.',
        text: "Kindly close the alert you are working on before refreshing the alert grid!",
        icon: 'warning',
        // showCancelButton: true,
        confirmButtonColor: '#027aaa',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          return;
          getInitialAlertData(alserMosaicOption,customerString,siteString,deviceTypeString,alertTypeString,"",severityString);
        } else {
          return
        }
      })
    }else{
      getInitialAlertData(alserMosaicOption,customerString,siteString,deviceTypeString,alertTypeString,"",severityString);
    }
  }

  return (
      <div className="SiteMainContainer">
        <div className="soundContainer AlertGridHgtDiv">
          <div style={{ display: "flex", flex:"0 1 auto" }}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "95%",
              }}
            >
              {/* <Select
                        hideSelectedOptions={false}
                        isMulti
                        options={colourOptions}
                        // styles={styles}
                        // onChange={this.handleChange}
                        // value={values}
                        components={{ ValueContainer }}
                        // getOptionLabel={(option) => `${option.label}: ${option.rating}`}
                      /> */}
              
              {/* <MultiSelect
                options={options}
                value={selected}
                onChange={(value) => setSelected(value)}
                valueRenderer={(selected, _options) => {
                  return (
                    <>
                      {selected?.length ? (
                        <div style={{ width: "100%", display: "flex" }}>
                          Saved filter: {selected[0].label}{" "}
                          <span className="selectedCount">
                            {selected?.length > 1
                              ? `+ ${selected?.length - 1}`
                              : ""}
                          </span>
                        </div>
                      ) : (
                        <div> Saved filter </div>
                      )}
                    </>
                  );
                }}
              />
              <h4>OR</h4>
              <MultiSelect
                options={options}
                value={selected}
                onChange={(value) => setSelected(value)}
                valueRenderer={(selected, _options) => {
                  return (
                    <>
                      {selected?.length ? (
                        <div style={{ width: "100%", display: "flex" }}>
                          Customer: {selected[0].label}{" "}
                          <span className="selectedCount">
                            {selected.length > 1
                              ? `+ ${selected.length - 1}`
                              : ""}
                          </span>
                        </div>
                      ) : (
                        <div> Customer </div>
                      )}
                    </>
                  );
                }}
              />
              <MultiSelect
                options={options}
                value={selected}
                onChange={(value) => setSelected(value)}
                valueRenderer={(selected, _options) => {
                  return (
                    <>
                      {selected?.length ? (
                        <div style={{ width: "100%", display: "flex" }}>
                          Site: {selected[0].label}{" "}
                          <span className="selectedCount">
                            {selected.length > 1
                              ? `+ ${selected.length - 1}`
                              : ""}
                          </span>
                        </div>
                      ) : (
                        <div> Site </div>
                      )}
                    </>
                  );
                }}
              />
              <MultiSelect
                options={options}
                value={selected}
                onChange={(value) => setSelected(value)}
                valueRenderer={(selected, _options) => {
                  return (
                    <>
                      {selected?.length ? (
                        <div style={{ width: "100%", display: "flex" }}>
                          Device type: {selected[0].label}{" "}
                          <span className="selectedCount">
                            {selected.length > 1
                              ? `+ ${selected.length - 1}`
                              : ""}
                          </span>
                        </div>
                      ) : (
                        <div> Device type </div>
                      )}
                    </>
                  );
                }}
              />
              <MultiSelect
                options={options}
                value={selected}
                onChange={(value) => setSelected(value)}
                valueRenderer={(selected, _options) => {
                  return (
                    <>
                      {selected?.length ? (
                        <div style={{ width: "100%", display: "flex" }}>
                          Device Name: {selected[0].label}{" "}
                          <span className="selectedCount">
                            {selected.length > 1
                              ? `+ ${selected.length - 1}`
                              : ""}
                          </span>
                        </div>
                      ) : (
                        <div> Device Name </div>
                      )}
                    </>
                  );
                }}
              />
              <MultiSelect
                options={options}
                value={selected}
                onChange={(value) => setSelected(value)}
                valueRenderer={(selected, _options) => {
                  return (
                    <>
                      {selected?.length ? (
                        <div style={{ width: "100%", display: "flex" }}>
                          Alert type: {selected[0].label}{" "}
                          <span className="selectedCount">
                            {selected.length > 1
                              ? `+ ${selected.length - 1}`
                              : ""}
                          </span>
                        </div>
                      ) : (
                        <div> Alert type </div>
                      )}
                    </>
                  );
                }}
              />
              <MultiSelect
                options={options}
                value={selected}
                onChange={(value) => setSelected(value)}
                valueRenderer={(selected, _options) => {
                  return (
                    <>
                      {selected?.length ? (
                        <div style={{ width: "100%", display: "flex" }}>
                          Alert Severity: {selected[0].label}{" "}
                          <span className="selectedCount">
                            {selected.length > 1
                              ? `+ ${selected.length - 1}`
                              : ""}
                          </span>
                        </div>
                      ) : (
                        <div> Alert Severity </div>
                      )}
                    </>
                  );
                }}
              />
              <MultiSelect
                options={options}
                value={selected}
                onChange={(value) => setSelected(value)}
                valueRenderer={(selected, _options) => {
                  return (
                    <>
                      {selected?.length ? (
                        <div style={{ width: "100%", display: "flex" }}>
                          Status: {selected[0].label}{" "}
                          <span className="selectedCount">
                            {selected.length > 1
                              ? `+ ${selected.length - 1}`
                              : ""}
                          </span>
                        </div>
                      ) : (
                        <div> Status </div>
                      )}
                    </>
                  );
                }}
              /> */}

                  <div 
                  // className='SavedFilterDiv'
                  className={selectedFilterName == "" ? "SavedFilterDiv" : "SavedFilterDiv dropdownSelectionContainer"}
                  >
                  <Dropdown>
                    <Dropdown.Toggle  variant="light">
                    {
                        selectedFilterName != ""
                        ? `Saved Filter: ${selectedFilterName}` 
                        : "Saved Filter"
                      }
                      {/* Saved Filter {selectedFilterName} */}
                    </Dropdown.Toggle>
                    {
                       selectedFilterName != ""
                       ?
                      <button className="SavedFilterCancelBtn" onClick={() => {
                        if(selectedFilterName != ""){
                          clearSelectedFilter()
                        }
                        }}
                    ><img src={FilterClearIcon} /></button>
                   
                    :null
                    }
                    

                    <Dropdown.Menu>
                      <>
                      {
                        filterList.length > 0
                        ?
                        filterList.map((item) => (
                          <Dropdown.Item className='p-0'>
                        <div className="SavedFilterOption" onClick={() => {
                          console.log("Clicked")
                          if(alertInProgress){
                            Swal.fire({
                              title: 'Unable to select filter.',
                              text: "Kindly close the alert you are working on before selecting a filter!",
                              icon: 'warning',
                              //showCancelButton: true,
                              confirmButtonColor: '#027aaa',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'Ok',
                              allowOutsideClick: false
                            }).then((result) => {
                              if(result.isConfirmed){
                                return
                                setSelectedFilterName(item.FilterName)
                                setSelectedFilter(item.FilterId)
                                setSeveritySelected([]);
                                setDeviceTypeSelected([]);
                                setAlertTypeSelected([]);
                                setSiteSelected([]);
                                setCustomerSelected([]);
                                setSaveDisabled(false);
                                setAlertInProgress(false);
                                setSingleAlertDet(null)
                                setFilterApplied(false);
                              }
                            })
                          }else{
                            setSelectedFilterName(item.FilterName)
                            setSelectedFilter(item.FilterId)
                            setSeveritySelected([]);
                            setDeviceTypeSelected([]);
                            setAlertTypeSelected([]);
                            setSiteSelected([]);
                            setCustomerSelected([]);
                            setSaveDisabled(false);
                            setFilterApplied(false);
                          }
                        }}>
                          <span>{item.FilterName}</span>
                          <div className="SaveFilterOtionHover">
                          <button style={{background:"transparent"}} onClick={(e) => {
                            e.stopPropagation();
                            setOpenDeleteFilter(true)
                            setModalFilterName(item.FilterName)
                            setModalFilterId(item.FilterId)
                          }}><img src={DeleteIcon}/></button>
                          <button style={{background:"transparent"}} onClick={(e) => {
                            infoFilterList(item.FilterId)
                            e.stopPropagation();
                            setOpenInfoFilter(true)
                            setModalFilterName(item.FilterName)
                            setModalFilterId(item.FilterId)
                          }}><img src={InfoIcon1}/></button>
                          </div>
                        </div>
                          </Dropdown.Item>
                        ))
                        :
                        <Dropdown.Item style={{cursor: 'default'}}>
                        <span>No Saved Filters.</span>
                        </Dropdown.Item>
                      }
                      </>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <h4>OR</h4>
              <FilterToolDDLTemp {...filterToolDDLProps} />
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Apply Filter</Tooltip>}
              >
                <button 
                  className={saveDisabled? "IconsButtonDiv" : "IconsButtonDiv buttonDisable"}
                  disabled={!saveDisabled} onClick={applyFilterOnClick}>
                  <img
                    src={FilterIcon}
                    className="camera-icon-height"
                    alt="filter icon"
                  />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Clear Filter</Tooltip>}
              >
                <button 
                className={saveDisabled ? "IconsButtonDiv" : "IconsButtonDiv buttonDisable"}
                onClick={clearFilterList}
                disabled={!saveDisabled}
                >
                  <img
                    src={ClearFilter}
                    className="camera-icon-height"
                    alt="Clear filter icon"
                  />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Save Filter</Tooltip>}
              >
                <button
                  //className="IconsButtonDiv buttonDisable"
                  className={saveDisabled ? "IconsButtonDiv" : "IconsButtonDiv buttonDisable"}
                  
                  disabled={!saveDisabled}
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(!show);
                  }}
                >
                  <img
                    src={SaveFilter}
                    className="camera-icon-height"
                    alt="Save filter icon"
                  />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Refresh</Tooltip>}
                  >
                    <button
                      className="DataAccessEdit"
                      style={{ width: "50px", height: "35px", float: "right" }}
                      onClick={refreshData}
                      // disabled={loading}
                    >
                      <img src={RefreshBtnIcon} alt="Refresh" />
                      {/* <span className="ViewUpdate ml-1">Update Data</span> */}
                    </button>
                  </OverlayTrigger>
              <div className="FilterOpenAlertsText">
                <p className="ListofText">
                  {/* 111 <span className="SiteNameText"> Open alerts</span> */}
                </p>
                <p className="ListofText" style={{marginLeft: "1rem"}}>
                    {
                      selectedFilterName || filterApplied
                        ?
                        <span className="SiteNameText">
                          Filter Applied
                        </span>
                        :
                        <span className="SiteNameText">
                          Default Data
                        </span>
                    }
                  </p>
              </div>
            </div>
            <div className="inputMainContainer w40dot75rem alertMosaicSelection">
              <div className="inputTypeLabelContainer">
                <select
                  className="inputType ArrowSelectClass"
                  value={alserMosaicOption}
                  // onChange={(e) => {
                  //   if(alertInProgress){
                  //     Swal.fire({
                  //       title: 'Are you sure?',
                  //       text: "You have an alert in progress do you wish to change the grid structure.",
                  //       icon: 'warning',
                  //       showCancelButton: true,
                  //       confirmButtonColor: '#027aaa',
                  //       cancelButtonColor: '#d33',
                  //       confirmButtonText: 'Yes!',
                  //       allowOutsideClick: false
                  //     }).then((result) => {
                  //       if(result.isConfirmed){
                  //         console.log("onMosaicTypeChange", e.target.value);
                  //         onMosaicTypeChange(e)
                  //       }else{
                  //         return
                  //       }
                  //     })
                  //   }else{
                  //     onMosaicTypeChange(e)
                  //   }
                  // }}
                    onChange={(e) => onMosaicTypeChange(e)}
                >
                  <option value="1" id="1">
                    3 x 3
                  </option>
                  <option id="2" value="2">
                    4 x 4
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flex:"1 1" , overflow:"hidden"}}>
            <div className="mosaic-row">
              {
                alserMosaicOption == 1
                ?
                <>
                    {
                      data.length > 0
                      ?
                      <div className="camerafilter3x3">
                        {
                          data.map((i) => {
                            console.log("alertData", i);
                            if(i.closed){
                              return (
                                <div className='alertInprogressDiv'>
                                  <div style={{display: "flex", flexDirection: "column", alignItems: "center",justifyContent: "center", height: "100%"}}>
                                    <img src={LoadingAlert} />
                                    <div className='alertInprogressDetail'>
                                      <p style={{color: "#535353"}}>Loading new alert…</p>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                            else if(i.noAlertFound){
                              return (
                                <div className='alertInprogressDiv'>
                                <div style={{display: "flex", flexDirection: "column", alignItems: "center",justifyContent: "center", height: "100%"}}>
                                  <img src={NoMoreAlert} />
                                  <div className='alertInprogressDetail'>
                                    <p>No new alerts to display.</p>
                                  </div>
                                </div>
                              </div>
                              )
                            } 
                            else {
                              <div className='alertInprogressDiv'>
                                <div style={{display: "flex", flexDirection: "column", alignItems: "center",justifyContent: "center", height: "100%"}}>
                                  <img src={NoMoreAlert} />
                                  <div className='alertInprogressDetail'>
                                    <p>No new alerts to display.</p>
                                  </div>
                                </div>
                              </div>
                            }
                            console.log("indcmd",i)
                            let sev= "";
                            if(i.severity == "High"){
                              sev ="high-bb"
                            }else if(i.severity == "Moderate"){
                              sev ="moderate-bb"
                            }else if(i.severity == "Low"){
                              sev ="low-bb"
                            }else if(i.severity == "Extreme"){
                              sev ="extreme-bb"
                            }
    
                            return (
                            <WebRTCPlayer
                              key={i.alertId}
                            // id={cameraObj.ParentCameraID}
                            id={i.associatedCamObject.length > 0 ? i.associatedCamObject[0].associatedCameraId + i.alertId : i.cameraObject.ParentCameraID + i.alertId}
                            // url={cameraObj.RTSPURL}
                            url={i.associatedCamObject.length > 0 ? i.associatedCamObject[0].rtspUrl : i.cameraObject.RTSPURL}
                            isEncode={i.associatedCamObject.length > 0 ? i.associatedCamObject[0].isTranscoded : i.cameraObject.isTranscoded}
                            item={i.associatedCamObject.length > 0 ? i.associatedCamObject[0] : i.cameraObject}
                            upperDivCss={`HeightChildDiv ${sev}`}
                            videoCss="mosaic"
                            // fullVideoCss="player-responsive-fullScreen"
                            buttonCss="mosaicButtonsMainDiv"
                            alertCameraHeadingCss="alertCameraHeadingCss"
                            alertCameraDetailCss="alertCameraDetailCss"    
                            noStreamDivCss="HeightChildDiv"
                            // playback={false}
                            // prePostDisplay={false}
                            // liveButton={true}
                            // isSinglePlayback={true}
                            // videoExportDownload={() => onSubmitBtn()}
                            // exportStartTime={() => exportStartTime()}
                            // exportEndTime={() =>exportEndTime()}
                            // secondsDiff={() =>differenceTimeSec()}
                            displayInfo={true}
                            alertData={i}
                            chatFlowClicked={chatFlowClicked}
                            closedAlertBtn={closedAlertBtn}
                            setAlertToClose={setAlertToClose}
                              isSelected={i.isSelected}
                            />
                            )}
                        )
                        }
                      </div> 
                      :
                      <div style={{margin: '1rem'}}>
                          <AlertTemplateMessage
                            message={
                              "No alerts found"
                            }
                          />
                      </div>
                    }
                </>
                :
                <>
                  {
                    data.length > 0
                    ?
                    <div className="camerafilter4x4">
                      {
                        data.map((i) => {
                          console.log("alertData", i);
                          if(i.closed){
                            return (
                              <div className='alertInprogressDiv'>
                                <div style={{display: "flex", flexDirection: "column", alignItems: "center",justifyContent: "center", height: "100%"}}>
                                  <img src={LoadingAlert} />
                                  <div className='alertInprogressDetail'>
                                    <p style={{color: "#535353"}}>Loading new alert…</p>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                           else if(i.noAlertFound){
                            return (
                              <div className='alertInprogressDiv'>
                              <div style={{display: "flex", flexDirection: "column", alignItems: "center",justifyContent: "center", height: "100%"}}>
                                <img src={NoMoreAlert} />
                                <div className='alertInprogressDetail'>
                                  <p>No new alerts to display.</p>
                                </div>
                              </div>
                            </div>
                            )
                           }
                           else {
                            <div className='alertInprogressDiv'>
                              <div style={{display: "flex", flexDirection: "column", alignItems: "center",justifyContent: "center", height: "100%"}}>
                                <img src={NoMoreAlert} />
                                <div className='alertInprogressDetail'>
                                  <p>No new alerts to display.</p>
                                </div>
                              </div>
                            </div>
                          }
                          console.log("indcmd",i)
                          let sev= "";
                          if(i.severity == "High"){
                            sev ="high-bb"
                          }else if(i.severity == "Moderate"){
                            sev ="moderate-bb"
                          }else if(i.severity == "Low"){
                            sev ="low-bb"
                          }else if(i.severity == "Extreme"){
                            sev ="extreme-bb"
                          }
                          return (
                            <WebRTCPlayer
                            key={i.alertId+"44"}
                            id={i.associatedCamObject.length > 0 ? i.associatedCamObject[0].associatedCameraId + i.alertId : i.cameraObject.ParentCameraID + i.alertId}
                            url={i.associatedCamObject.length > 0 ? i.associatedCamObject[0].rtspUrl : i.cameraObject.RTSPURL}
                            isEncode={i.associatedCamObject.length > 0 ? i.associatedCamObject[0].isTranscoded : i.cameraObject.isTranscoded}
                            item={i.associatedCamObject.length > 0 ? i.associatedCamObject[0] : i.cameraObject}
                            upperDivCss={`HeightChildDiv ${sev}`}
                            videoCss="mosaic"
                            // fullVideoCss="player-responsive-fullScreen"
                            buttonCss="mosaicButtonsMainDiv"
                            alertCameraHeadingCss="alertCameraHeadingCss"
                            alertCameraDetailCss="alertCameraDetailCss"
                            noStreamDivCss="HeightChildDiv"
                            // playback={false}
                            // prePostDisplay={false}
                            // liveButton={true}
                            // isSinglePlayback={true}
                            // videoExportDownload={() => onSubmitBtn()}
                            // exportStartTime={() => exportStartTime()}
                            // exportEndTime={() =>exportEndTime()}
                            // secondsDiff={() =>differenceTimeSec()}
                            displayInfo={true}
                            alertData={i}
                            chatFlowClicked={chatFlowClicked}
                            closedAlertBtn={closedAlertBtn}
                            setAlertToClose={setAlertToClose}
                            isSelected={i.isSelected}
                            />
                          )   
                        }
                        )
                      }
                      </div> 
                    :
                    <div style={{margin: '1rem'}}>
                          <AlertTemplateMessage
                            message={
                              "No alerts found"
                            }
                          />
                    </div>
                  }
                </>
              }

            </div>
            <div className="alertChatflowContainer">
              {
                singleAlertDet == null
                ?
                <>
                <div className="AlertFilterHeaderDiv">
                  <p className="AlterfilterText">
                    <span>No Alert Selected</span>
                  </p>
                </div>
                <div style={{ padding: "20px" }}>
                        <AlertTemplateMessage message={"Select an alert to view detail and take action."} />
                      </div>
              </>
                :
                <>
                <div className="AlertFilterHeaderDiv">
                  {/* <p className="AlterfilterText">
                    <span>No Alert Selected</span>
                  </p> */}
                  <p className="AlterfilterText">
                    Alert ID:
                    <span>{singleAlertDet.alertId}</span>
                  </p>
                  {/* <button onClick={() => alertClosed(singleAlertDet.alertId)}>Close Alert</button> */}
                </div>
                <>
                  <div className="CameragridAssociateDiv">
                    {
                      singleAlertDet.associatedCamList.length > 0
                      // true
                      ?
                          <AliceCarousel
                            mouseTracking
                            // items={items}
                            // items={() => returnItems(singleAlertDet.associatedCamList)}
                            items={returnItems(associateData)}
                            renderDotsItem={renderDotsItem}
                            renderPrevButton={renderPrevButton}
                            renderNextButton={renderNextButton}
                          /> 
                      :
                      <div className="chatflow">
                      {
                        singleAlertDet != null 
                        // false
                        ?
                        <WebRTCPlayer
                        key={singleAlertDet.deviceId}
                        id={singleAlertDet.deviceId + "34"}
                        devId={singleAlertDet.deviceId}
                        url={singleAlertDet.rtspUrl}
                        isEncode={singleAlertDet.isTranscoded}
                        item={singleAlertDet}
                        actualTime={singleAlertDet.actualTime}
                        alertDetail={singleAlertDet}
                        upperDivCss="cameraHoverMainDiv"
                        videoCss="player-responsive1"
                        //videoCss="chatbotliveplayer"
                        fullVideoCss="player-responsive-fullScreen"
                        buttonCss="cameraButtonsMainDiv"
                        noStreamDivCss="no-stream-found"
                        playback={false}
                        prePostDisplay={false}
                        liveButton={true}
                        exportCurrentVideoHide={true}
                        showPlaybackBtn={false}
                        hideTwoWay={false}
                        imageToChat={AttachalertevidencesParent}
                        attachEvidence={attachEvidence}
                        showDirectToChat={true}
                        showHooterIcon={true}
                        // buttonDisable={buttonDisable}
                        />
                        : null
                      }
                    </div>
                    }

                  </div>
                  {/* <div className="CameragridChatBotDiv">
                    <div className="AlertCameraChatbotHeigthDiv">
                      <div className="chatFlowBoxContainer-bot">
                        <div className="chatFlowTimeIconContainerBig">
                          <img
                            className="chatFlowChatTimeIconBig"
                            src={BotAvatarIcon}
                          />
                        </div>
                        <p className="chatFlowChatTimeIconTextBig">
                          21-05-2024
                        </p>
                      </div>
                      <div className="chatFlowBoxContainer-bot">
                        <div
                          className="chatFlowChatBoxBig"
                          style={{ background: "#E6E9F0" }}
                        >
                          <span className="chatFlowChatTextBig">
                            Camera block alert generated.
                          </span>
                        </div>
                      </div>
                      <div style={{ display: "grid", justifyContent: "right" }}>
                        <div className="chatFlowBoxContainer">
                          <p className="chatFlowChatTimeIconTextBig">
                            21-05-2024
                          </p>
                          <div className="chatFlowTimeIconContainerBig">
                            <img
                              className="chatFlowChatTimeIconBig"
                              src={ERTAvatarIcon}
                            />
                          </div>
                        </div>
                        <div className="chatFlowBoxContainer-bot">
                          <div
                            className="chatFlowChatBoxBig"
                            style={{ background: "#E6E9F0" }}
                          >
                            <p className="chatFlowChatTextBig">
                              Alert acknowledged successfully
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="react-functional-chatbot-inner-div">
                        <div className="chatFlowBoxContainer-bot">
                          <div className="chatFlowTimeIconContainerBig">
                            <img
                              className="chatFlowChatTimeIconBig"
                              src={BotAvatarIcon}
                              alt="Bot"
                            />
                          </div>
                          <p className="chatFlowChatTimeIconTextBig">
                            21-05-2024
                          </p>
                        </div>
                        <div className="chatFlowBoxContainer-bot">
                          <div
                            className="chatFlowChatBoxBig"
                            style={{ background: "#E6E9F0" }}
                          >
                            <span className="chatFlowChatTextBig">
                              Alert has already been acknowledged by another
                              operator.
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="chatFlowBoxContainer">
                        <p className="chatFlowChatTimeIconTextBig">
                          21-05-2024
                        </p>
                        <div className="chatFlowTimeIconContainerBig">
                          <img
                            className="chatFlowChatTimeIconBig"
                            src={BotAvatarIcon}
                          />
                        </div>
                      </div>
                      <div className="chatFlowBoxContainer">
                        <div
                          className="chatFlowChatBoxBig"
                          style={{ background: "#E6E9F0" }}
                        >
                          <p className="chatFlowChatTextBig">
                            Great ! Please record the conversation with the fire
                            brigade team and share.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="chatbotCommentInput">
                      <div className="AlertGridChatCommentInputDiv">
                        <input
                          id="Search_txt_site"
                          className="chatbotInput"
                          type="text"
                          placeholder="Enter comments here"
                        />
                      </div>
                      <div className="AlertSendAttachMicButtons">
                        <button style={{ backgroundColor: "transparent" }}>
                          <img src={SendIconBlue} height={20} width={20} />
                        </button>
                        <button style={{ backgroundColor: "transparent" }}>
                          <img src={AttachIcon} className="ml-2" />
                        </button>
                        <button className="MikeBtn ml-2">
                          <img src={MikeIcon} />
                        </button>
                      </div>
                    </div>
                  </div> */}
                    <div className='multi-chatbot-container-div'>
                        <ChatflowContainer
                            key={singleAlertDetChatFlow.alertId}
                            scrollToBottom={scrollToBottom}
                            AlertDetails={singleAlertDetChatFlow}
                            ConversationId={"AlertID_" + singleAlertDetChatFlow.alertId + "_UserID_" + localStorage.getItem('UserID')}
                            ResetAlertList={()=> {}}
                            hootWay={[]}
                            setHootWay={() => {}}
                            associateCam={[]}
                            setAssociateCam={()=> {}}
                            GetAlertDetails={getAlertChatFlowDetails}
                            updateChatflow={updateChatflow}
                            imageData={imageData}
                            fileName={fileName}
                            alertClosed={alertClosed}
                        />
                        <div ref={messagesEndRef} />
                    </div>
                </>
              </>
              }
            </div>
          </div>
        </div>
        {/* Modal popup for save filter */}
        <Modal
          size="lg"
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          backdrop="static"
          className="saveCameraFilterModal"
        >
          <>
            <Modal.Header>
              <Modal.Title id="example-custom-modal-styling-title">
                Save Filter
              </Modal.Title>
              <img
                src={RemoveIcon}
                alt="Remove"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShow(false);
                  setFilterName("")
                  //   setAddCustomerError('')
                }}
              />
            </Modal.Header>

            <Modal.Body>
              <div className="inputMainContainer w40dot75rem w-100">
                <div className="inputTypeLabelContainer">
                  <label className="formFieldLabels">Filter Name</label>
                  <input
                    style={{ width: "40vw" }}
                    type="text"
                    className="inputType"
                    placeholder="Ex. Filter for xyz company with 10 sites"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                  <p className="Error_P" style={{ float: "left" }}>
                    <span>{}</span>
                  </p>
                </div>
              </div>
              <button
                className="DataAccessEdit mt-3"
                style={{ width: "100%" }}
                onClick={saveFilter}
              >
                <span className="ViewUpdate">Save</span>
              </button>
            </Modal.Body>
          </>
        </Modal>

        
              {/* Modal popup for delete of scheduler */}
              <Modal
                // size="lg"
                show={openDeleteFilter}
                onHide={() => setOpenDeleteFilter(false)}
                className="deleteModalMainClass"
                backdrop="static"
                // centered
              >
                <Modal.Body>
                  <div className="ModalBodyDiv">
                    <div style={{ padding: "37px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <img
                          className="informationIcon infoorangeicon"
                          src={WarningDelete}
                          alt="Info"
                        />
                        <div style={{ width: "100%" }}>
                          <h3 className="No-Data-Access-Group">
                            Delete Saved Filter!
                          </h3>
                        </div>
                      </div>
                      <p className="DeleteText mb-4">
                        Are you sure you want to delete the saved filter{" "}
                        <span className="font-weight-bold"> {modalFilterName}</span>
                      </p>
                      <div
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          className="DataAccessEdit ml-0"
                          style={{ width: "45%" ,backgroundColor: "#FB3030"}}
                          onClick={() => deleteFilterList(modalFilterId)}
                        >
                          <span className="ViewUpdate">Yes</span>
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenDeleteFilter(false);
                            setModalFilterId(0)
                            setModalFilterName("")                  
                          }}
                          className="DataAccessEdit ml-0"
                          style={{ width: "45%" }}
                        >
                          <span className="ViewUpdate">No</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

         {/* Modal popup for info saved filter */}
       <Modal
        size="xl"
        show={openInfoFilter}
        onHide={() => setOpenInfoFilter(false)}
        className="deleteModalMainClass"
        backdrop="static"
        // centered
        scrollable
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {modalFilterName} - info
          </Modal.Title>
          <img
            src={RemoveIcon}
            alt="Remove"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpenInfoFilter(false);
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <div id="mainContainer" className="main-container">
            {
              customerMasterArr?.length > 0
                ?
                  <div className="SiteLeftContainer ml-2 mr-2">
                    <div className="infoFilterHeader">
                      <p>Customer</p>
                    </div>
                    <div className="m-2 HeaderSiteList">
                      <input
                        id="Search_txt_site"
                        className="SearchViewInput1"
                        type="text"
                        placeholder="Search Customer"
                        value={customerSearch}
                        onChange={(e) => searchCustomerHandler(e)}
                      />
                      <div className="SearchTextDivConfigTool">
                        {!isDarkTheme ? (
                          <img
                            className="EyeIcon"
                            src={SearchIcon}
                            alt="search icon"
                          />
                        ) : (
                          <img
                            className="EyeIcon"
                            src={SearchIconWhite}
                            alt="search icon"
                            height="15"
                          />
                        )}
                      </div>
                    </div>
                    <div className="SaveFilterInfoList">
                      {customerInfoArr &&
                        customerInfoArr?.length > 0 ?
                        (
                          <div>{
                            customerInfoArr.map((item) => (
                              <p className="ListofText">
                                {item.Values}
                              </p>
                            ))
                          }


                          </div>
                        ) : (
                          <div className="no-data-found">
                            <p>No Customer Found.</p>
                          </div>
                        )}
                      {!customerInfoArr && (
                        <div className="no-data-found">
                          No Customer Available.
                        </div>
                      )}
                    </div>
                  </div>
                :
                null
            }
            {
              siteMasterArr?.length > 0
                ?
                  <div className="SiteLeftContainer ml-2 mr-2">
                    <div className="infoFilterHeader">
                      <p>Site</p>
                    </div>
                    <div className="m-2 HeaderSiteList">
                      <input
                        id="Search_txt_site"
                        className="SearchViewInput1"
                        type="text"
                        placeholder="Search Site"
                        value={siteSearch}
                        onChange={(e) => searchSiteHandler(e)}
                      />
                      <div className="SearchTextDivConfigTool">
                        {!isDarkTheme ? (
                          <img
                            className="EyeIcon"
                            src={SearchIcon}
                            alt="search icon"
                          />
                        ) : (
                          <img
                            className="EyeIcon"
                            src={SearchIconWhite}
                            alt="search icon"
                            height="15"
                          />
                        )}
                      </div>
                    </div>
                    <div className="SaveFilterInfoList">
                      {siteInfoArr &&
                        siteInfoArr?.length > 0
                        ? (
                          <div>
                            {
                              siteInfoArr.map((item) => (
                                <p className="ListofText">
                                  {item.Values}

                                </p>
                              ))
                            }

                          </div>
                        ) : (
                          <div className="no-data-found">
                            <p>No Site Found.</p>
                          </div>
                        )}
                      {!siteInfoArr && (
                        <div className="no-data-found">
                          No Sites Available.
                        </div>
                      )}
                    </div>
                  </div>
                :
                null
            }

            {
              deviceTypeMasterArr?.length > 0
                ?
                  <div className="SiteLeftContainer ml-2 mr-2">
                    <div className="infoFilterHeader">
                      <p>Device Type</p>
                    </div>
                    <div className="m-2 HeaderSiteList">
                      <input
                        id="Search_txt_site"
                        className="SearchViewInput1"
                        type="text"
                        placeholder="Search Device Type"
                        value={deviceTypeSearch}
                        onChange={(e) => searchDeviceTypeHandler(e)}
                      />
                      <div className="SearchTextDivConfigTool">
                        {!isDarkTheme ? (
                          <img
                            className="EyeIcon"
                            src={SearchIcon}
                            alt="search icon"
                          />
                        ) : (
                          <img
                            className="EyeIcon"
                            src={SearchIconWhite}
                            alt="search icon"
                            height="15"
                          />
                        )}
                      </div>
                    </div>
                    <div className="SaveFilterInfoList">
                      {devieceTypeInfoArr &&
                        devieceTypeInfoArr?.length > 0
                        ? (
                          <div>{
                            devieceTypeInfoArr.map((item) => (
                              <p className="ListofText">
                                {item.Values}
                              </p>
                            ))
                          }


                          </div>
                        ) : (
                          <div className="no-data-found">
                            <p>No Device Type Found.</p>
                          </div>
                        )}
                      {!devieceTypeInfoArr && (
                        <div className="no-data-found">
                          No Device Type Available.
                        </div>
                      )}
                    </div>
                  </div>
                :
                null
            }

            {
              alertTypeMasterArr?.length > 0
                ?
                  <div className="SiteLeftContainer ml-2 mr-2">
                    <div className="infoFilterHeader">
                      <p>Alert Type</p>
                    </div>
                    <div className="m-2 HeaderSiteList">
                      <input
                        id="Search_txt_site"
                        className="SearchViewInput1"
                        type="text"
                        placeholder="Search Alert Type"
                        value={alertTypeSearch}
                        onChange={(e) => searchAlertTypeHandler(e)}
                      />
                      <div className="SearchTextDivConfigTool">
                        {!isDarkTheme ? (
                          <img
                            className="EyeIcon"
                            src={SearchIcon}
                            alt="search icon"
                          />
                        ) : (
                          <img
                            className="EyeIcon"
                            src={SearchIconWhite}
                            alt="search icon"
                            height="15"
                          />
                        )}
                      </div>
                    </div>
                    <div className="SaveFilterInfoList">
                      {alertTypeInfoArr &&
                        alertTypeInfoArr?.length > 0
                        ? (
                          <div>{
                            alertTypeInfoArr.map((item) => (
                              <p className="ListofText">
                                {item.Values}
                              </p>

                            ))
                          }

                          </div>
                        ) : (
                          <div className="no-data-found">
                            <p>No Alert Type Found.</p>
                          </div>
                        )}
                      {!alertTypeInfoArr && (
                        <div className="no-data-found">
                          No Alert Type Available.
                        </div>
                      )}
                    </div>
                  </div>
                :
                null
            }
            {
              alertSeverityMasterArr?.length > 0
                ?
                  <div className="SiteLeftContainer ml-2 mr-2">
                    <div className="infoFilterHeader">
                      <p>Alert Severity</p>
                    </div>
                    <div className="m-2 HeaderSiteList">
                      <input
                        id="Search_txt_site"
                        className="SearchViewInput1"
                        type="text"
                        placeholder="Search Site"
                        value={alertSeveritySearch}
                        onChange={(e) => searchAlertSeverityHandler(e)}
                      />
                      <div className="SearchTextDivConfigTool">
                        {!isDarkTheme ? (
                          <img
                            className="EyeIcon"
                            src={SearchIcon}
                            alt="search icon"
                          />
                        ) : (
                          <img
                            className="EyeIcon"
                            src={SearchIconWhite}
                            alt="search icon"
                            height="15"
                          />
                        )}
                      </div>
                    </div>
                    <div className="SaveFilterInfoList">
                      {alertSeverityInfoArr &&
                        alertSeverityInfoArr?.length > 0
                        ? (
                          <div>{
                            alertSeverityInfoArr.map((item) => (
                              <p className="ListofText">
                                {item.Values}
                              </p>
                            ))
                          }
                          </div>
                        ) : (
                          <div className="no-data-found">
                            <p>No Alert Severity Found.</p>
                          </div>
                        )}
                      {!alertSeverityInfoArr && (
                        <div className="no-data-found">
                          No Alert Severity Available.
                        </div>
                      )}
                    </div>
                  </div>
                :
                null
            }

          </div>
        </Modal.Body>
      </Modal>

              <Modal
                size="lg"
                show={switchAlertModal}
                onHide={() => setSwitchAlertModal(false)}
                className="deleteModalMainClass"
                backdrop="static"
                // centered
              >
                <Modal.Body>
                  <div className="ModalBodyDiv">
                    <div style={{ padding: "37px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <img
                          className="informationIcon infoorangeicon"
                          src={WarningDelete}
                          alt="Info"
                        />
                        <div style={{ width: "100%" }}>
                          <h3 className="No-Data-Access-Group">
                            {/* Confirm Alert Switch? */}
                            Unable to Switch Alert.
                          </h3>
                        </div>
                      </div>
                      <p className="DeleteText">
                      {/* You have an open alert. Switching to a new alert will only close the current alert view.{" "} */}
                        Kindly close the alert you are working on before operating another alert!
                        {/* <span>Do you want to close the current view and open the new alert?</span> */}
                      </p>
                      {/* <p className="DeleteTextPTag mb-4" style={{fontWeight: "800"}}>Do you want to close the current view and open the new alert?</p> */}
                      <div
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {/* <button
                          className="DataAccessEdit ml-0"
                          style={{ width: "45%" }}
                          onClick={(e) => {
                            e.preventDefault();
                            chatFlowClicked(alertClicked, true);
                          }}
                        >
                          <span className="ViewUpdate">Yes</span>
                        </button> */}
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setSwitchAlertModal(false);
                          }}
                          className="DataAccessEdit ml-0"
                          style={{ width: "45%" ,marginTop:"1rem"}}
                        >
                          <span className="ViewUpdate">Ok</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
        </Modal>

        <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />

      </div>
  );
}

import React, { useState } from 'react'
import { HIDE_STUFF } from '../../config'
import { AlertGridViewActive, AlertGridViewInactive, AlertListViewActive, AlertListViewInactive } from '../../assets/images'
import AlertMosaicView1 from './AlertMosaicView1';
import AlertMosaicView from './AlertMosaicView';
import AlertFilterListView from './AlertFilterListView';
import { ALERTGRIDVIEW } from '../../navigation/CONSTANTS';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function AlertMainContainer({ selectedSubMenu, childWindow, setChildWindow, isDarkTheme, onAddAlertChat, alertChildWindow, setAlertChildWindow }) {

    const [isActive, setIsActive] = useState("listview");

    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;
    let windowFeatures = `menubar=no, fullscreen=yes, location=no, toolbar=no, scrollbars=yes, resizable=yes, status=no, width=${windowWidth}}, height=${windowHeight}`

    // function openChildWindow() {
    //     let newChildWindow
    //     if (childWindow == null || childWindow.name == "") {
    //         newChildWindow = window.open(ALERTGRIDVIEW, "mosaicWindow", "_blank", windowFeatures)
    //         setChildWindow(newChildWindow);
    //         sessionStorage.setItem("mosaicWindowKey", "mosaicWindow")
    //     } else {
    //         childWindow.focus()
    //     }
    // }

    function openChildWindow() {
        let newChildWindow
        if (alertChildWindow == null || alertChildWindow.name == "") {
            newChildWindow = window.open(ALERTGRIDVIEW, "alertWindow", "_blank", windowFeatures)
            setAlertChildWindow(newChildWindow);
            sessionStorage.setItem("alertWindowKey", "alertWindow")
        } else {
            alertChildWindow.focus()
        }
    }


  return (
    <div className='MainContentMargin'>
        <div className="col-lg-12" style={{ margin: "0px auto", padding: "0px" }}>
            <div className="col-lg-12 SecondDivSearch dashboardHeadingInnerDiv">
                <div className="cameramainheader"><h4>Alerts</h4></div>
                <div className="DownloadTextDiv mr-2">
                             <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>
                                   Listview
                                  </Tooltip>
                                }>
                                <button 
                                className={ isActive == "listview" ? 'ListGridBtn' : 'ListGridActiveBtn' }
                                    onClick={() => {
                                    setIsActive("listview")
                                    localStorage.setItem("IsListView", false)
                                    }}
                                >
                                    <img src={isActive == "listview" ? AlertListViewActive : AlertListViewInactive} />
                                </button>
                            </OverlayTrigger>  
                          
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>
                                   Gridview
                                  </Tooltip>
                                }>
                            <button 
                            className='ListGridActiveBtn'
                                onClick={() => {
                                // setIsActive("mosaicview")
                                // setIsAlertMosaicGridClick(true)
                                localStorage.setItem("IsListView", false)
                                openChildWindow()
                                }}
                            >
                                <img src={isActive == "mosaicview" ? AlertGridViewActive : AlertGridViewInactive} />
                            </button>
                            </OverlayTrigger>
                </div>
            </div>
            <div id="divListView" style={{ display: (isActive == "listview" ? 'block' : 'none') }}>
                    <AlertFilterListView isDarkTheme={isDarkTheme} onAddAlertChat={onAddAlertChat} alertChildWindow={alertChildWindow} />
                </div>
            {/* <div id="divMosaicView" style={{ display: (isActive == "mosaicview" ? 'block' : 'none') }}>
                    <AlertMosaicView />
                    <AlertMosaicView1 />
                </div> */}
        </div>
    </div>
  )
}

import React, { useEffect, useState, useContext, useRef } from "react"
import Chatbotcomponent from '../Chatflow/Chatbotcomponent'
import { getAlertDetailsById } from "../../services/index";
import { checkFileDownloaded, getPrePostDetails, getalertactivitylogs, localFileDownload } from "../../services/alertAuthService"
import ReactPlayer from 'react-player'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { Modal, Row, Spinner } from 'react-bootstrap'
import { ChatFlowWebArrowIcon, BotAvatarIcon, UserAvatarIcon, ERTAvatarIcon, MosaicNoStream, AlertGenericImg1, AlertGenericImg, Video_Export, CheckmarkSuccessCircle, monitoringHubLogo_dataURL, LoaderModal } from '../../assets/images';
import Reactfunctionalchatbot from '../../components/ReactChatBot/Reactfunctionalchatbot.js'
import Swal from "sweetalert2";
import { frontDateConverter } from "../../utils/utils";
import { toPng } from "html-to-image";
import jsPDF from "jspdf";
import JSZip, { file } from 'jszip';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { SuccessToast } from '../../CustomHooks/SuccessToast'
import { ErrorToast } from "../../CustomHooks/ErrorToast.js";
import { WarningToast } from "../../CustomHooks/WarningToast.js";
import { FILE_DOWNLOAD_CHECK_WAIT_TIME } from "../../config";
import { ALLALERTS } from "../../navigation/CONSTANTS.js";

// import html2canvas from 'html2canvas';

export default function AlertReport() {
    let match1 = useRouteMatch("/alertreport/:id") //to fetch id from url
    let alertId = match1.params['id']

    const [singleAlertDet, setSingleAlertDet] = useState(null)
    const [activitylogs, setactivitylogs] = useState(null)
    const [MessagesList, setMessagesList] = useState(null)
    const [config, setconfig] = useState(null)
    let history = useHistory()
    const [messages, setmessages] = useState([])

    const [isimage, setisimage] = useState(false)
    const [isOpenmodal, setisOpenmodal] = useState(false)
    const [exportModal, setExportModal] = useState(false);
    const [snapshoturl, setsnapshoturl] = useState([])
    const [isShowChatbotComponent, setIsShowChatbotComponent] = useState(false)
    const [attachmentData, setAttachmentData] = useState([]);
    const [audioNames, setAudioNames] = useState("NA");
    const [imageNames, setImageNames] = useState("NA");
    const [videoNames, setVideoNames] = useState("NA");
    const [docNames, setDocNames] = useState("NA");
    const [prePostFileSource, setPrePostFileSource] = useState("");
    const [prePostFileUrl, setPrePostFileUrl] = useState("");
    const [localObj, setLocalObj] = useState(null);
    const [downloadingLocal, setDownloadingLocal] = useState(false);

    const [showEmail, setShowEmail] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        (async () => {
            if (alertId && alertId != null) {
                GetAlertDetails()
                //Getalertactivitylogs()
            }
        })();

    }, [alertId])

    // useEffect(async () => {
    //     if (alertId && alertId != null) {
    //         GetAlertDetails()
    //         //Getalertactivitylogs()
    //     }

    // }, [alertId])
    const GetAlertDetails = async () => {
        try {
            await getAlertDetailsById(alertId).then((resp) => {
                if (resp.status == "Ok") {
                    if (resp.message) {
                        setSingleAlertDet(resp.message[0])
                    }
                } else if (resp.status === "Failed") {
                    // alert(resp.message)
                    Swal.fire({ text: resp.message })
                    return;
                }
            })
        } catch (error) {
            console.error(error)
        }
    }
    const Getalertactivitylogs = async () => {
        try {
            let obj = {
                "alertId": alertId
            }
            getalertactivitylogs(obj).then((resp) => {
                if (resp.status == "Ok") {
                    setactivitylogs(resp.message.conversations)
                } else if (resp.status === "Failed") {
                    Swal.fire({ text: resp.message })
                    return;
                }
            })
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        if (singleAlertDet != null) {
            Getalertactivitylogs()
        }
    }, [singleAlertDet])

    useEffect(() => {
        if (singleAlertDet != null) {
            // Call API to check if prepost video exits in S3 or not
            getPrePostDetails(singleAlertDet.alertId)
                .then((res) => {
                    if(res.Status == "success"){
                        if(res.Data.IsSubscribed == 0){
                            // setPrePostFileSource("Not Subscribed")
                            let str = `Alert_${singleAlertDet.alertId}_Pre-Post_Video.mp4 - Local Storage`
                                setPrePostFileSource(str)
                                let preTime = parseInt(res.Data.PreTimeSec)
                                let postTime = parseInt(res.Data.PostTimeSec);
                                downloadPrePostLocal(preTime, postTime, res.Data);                            
                        }else{
                            if(res.Data.DownloadLink != "" && res.Data.DeleteFlag == "0"){
                                // Set S3 url link in the url path so that it can be downloaded later
                                setPrePostFileUrl(res.Data.DownloadLink);
                                let str = `Alert_${singleAlertDet.alertId}_Pre-Post_Video.mp4 - Cloud Storage`
                                setPrePostFileSource(str)
                            }else if(res.Data.DownloadLink != "" && res.Data.DeleteFlag == "1"){
                                //Download from local machine
                                // Call api through client component and you will get url then set according to it.
                                let str = `Alert_${singleAlertDet.alertId}_Pre-Post_Video.mp4 - Local Storage`
                                setPrePostFileSource(str)
                                let preTime = parseInt(res.Data.PreTimeSec)
                                let postTime = parseInt(res.Data.PostTimeSec);
                                downloadPrePostLocal(preTime, postTime, res.Data);
                            }else if(res.Data.DownloadLink == ""){
                                // Download from local machine
                                // Call api through client component and you will get url then set according to it.
                                let str = `Alert_${singleAlertDet.alertId}_Pre-Post_Video.mp4 - Local Storage`
                                setPrePostFileSource(str)
                                let preTime = parseInt(res.Data.PreTimeSec)
                                let postTime = parseInt(res.Data.PostTimeSec);
                                downloadPrePostLocal(preTime, postTime, res.Data);
                            }else{
                                let str = `Alert_${singleAlertDet.alertId}_Pre-Post_Video.mp4 - Not Available`
                                setPrePostFileSource(str)
                            }
                        }
                    }else{
                        setPrePostFileSource("Not Available")
                    }
                }) 
                .catch(err => console.log(err))
        }
    }, [singleAlertDet])

    useEffect(() => {
        let timer;
        if(downloadingLocal){
            //To Set Timeout of 20 seconds and then display the loader overlay;
            timer = setTimeout(() => {
                setDownloadingLocal(false);
                setShow(false);
                SuccessToast("Check your downloads folder for prepost video");
            }, 20000)
        }
        return () => clearTimeout(timer);
    }, [downloadingLocal])

    useEffect(() => {
        if (activitylogs != null) {
            ConvertRasaMessages(activitylogs, singleAlertDet)
        }
    }, [activitylogs])

    useEffect(() => {
        if (config != null) {
            setIsShowChatbotComponent(true)
        }
    }, [config])

    const getFileName = (url) => {
        let fileName = singleAlertDet.evidenceList.filter((item) => item.DocumentURL == url);
        let fName = ""
        if(fileName.length > 0){
            fName = fileName[0].AttachedDocTitle
        }
        return fName;
    }

    useEffect(() => {
        setAttachmentData(singleAlertDet?.evidenceList);
        if(singleAlertDet?.evidenceList.length > 0){

        let audioArr = singleAlertDet.evidenceList.filter((item) => item.AttachedDocTitle.includes('.mp3')).map((i) => i.AttachedDocTitle);
        if(audioArr.length == 0){
            setAudioNames("NA");
        }else{
            setAudioNames(audioArr.join(", "));
        }

        let videoArr = singleAlertDet.evidenceList.filter((item) => item.AttachedDocTitle.includes('.mp4')).map((i) => i.AttachedDocTitle);
        if(videoArr.length == 0){
            setVideoNames("NA")
        }else{
            setVideoNames(videoArr.join(", "))
        }

        let imageArr = singleAlertDet.evidenceList.filter((item) => item.AttachedDocTitle.includes('.png') || item.AttachedDocTitle.includes('.jpeg') || item.AttachedDocTitle.includes('.jpg')).map((i) => i.AttachedDocTitle);
        if(imageArr.length == 0){
            setImageNames("NA")
        }else{
            setImageNames(imageArr.join(", "))
        }

        let docArr = singleAlertDet.evidenceList.filter((item) => item.AttachedDocTitle.includes('.txt') || item.AttachedDocTitle.includes('.doc') || item.AttachedDocTitle.includes('.docx') || item.AttachedDocTitle.includes('.pdf') || item.AttachedDocTitle.includes('.xls') || item.AttachedDocTitle.includes('.xlsx')).map((i) => i.AttachedDocTitle);
        if(docArr.length == 0){
            setDocNames("NA")
        }else{
            setDocNames(docArr.join(", "))
        }
    }
    }, [singleAlertDet]);


    const ConvertRasaMessages = async (convlist, alertdetails) => {
        var messageslist = []

        var index1 = 0
        convlist.map((mess, index) => {
            index1 = index1 + 1

            if (mess.data.otherProps.botMessage == "Please confirm if you want to close this alert?") {
                if (mess.data.otherProps.btnResponse != 0) {
                    var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null)
                    var messobj = {
                        id: index1,
                        type: "bot",
                        message: cmess,
                        loading: true,
                        responseTime: mess.createdOn,
                        direction: "left",
                    }
                    messageslist.push(messobj)

                    var buttonclicked = mess.data.otherProps.buttons.find(x => x.btnId == mess.data.otherProps.btnResponse)
                    index1 = index1 + 1
                    var cmess = BotMessageComponent("user", buttonclicked.buttonProperties.responseText, mess.data.otherProps.responseTime, null)
                    var messobj = {
                        id: index1,
                        type: "user",
                        message: cmess,
                        loading: true,
                        responseTime: mess.data.otherProps.responseTime,
                        direction: "right",
                    }
                    messageslist.push(messobj)
                } else {
                    var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, mess.data.otherProps.buttons, mess)
                    var messobj = {
                        id: index1,
                        type: "bot",
                        message: cmess,
                        loading: true,
                        responseTime: mess.createdOn,
                        direction: "left",
                    }
                    messageslist.push(messobj)
                }
            } else if (mess.data.otherProps.action == "Escalation Matrix") {
                if (singleAlertDet.emergencyContactsList == null) {
                    var bmess = <h3>No Contacts for Escalation</h3>
                } else {
                    var bmess = EscalationContactList(singleAlertDet.emergencyContactsList)
                }

                var prepostchatmess =
                    <>
                        <div className="chatFlowBoxContainer justify-content-start">
                            <div className="chatFlowChatBoxBig float-left">
                                <p className="chatFlowChatTextBig">{mess.data.otherProps.botMessage}</p>
                            </div>
                        </div>
                        {bmess}
                    </>
                var cmess = BotMessageComponent("bot", prepostchatmess, mess.createdOn, null, null)
                var messobj = {
                    id: index1,
                    type: "bot",
                    message: cmess,
                    //loading:true,
                    responseTime: mess.createdOn,
                    direction: "left",
                }
                messageslist.push(messobj)
            } else if (mess.data.otherProps.action == "Please watch live stream") {
                if (singleAlertDet.associatedCamList == null) {
                    var bmess = <h3>No devices associated</h3>
                } else {
                    // var bmess = AssociatedDevicesListLiveComponent(singleAlertDet.associatedCamList)
                    var bmess = <>
                        <p>No live stream available once alert closed.</p>
                    </>
                }

                var prepostchatmess =
                    <>
                        <div className="chatFlowBoxContainer justify-content-start">
                            <div className="chatFlowChatBoxBig float-left">
                                <p className="chatFlowChatTextBig">{mess.data.otherProps.botMessage}</p>
                            </div>
                        </div>
                        {bmess}
                    </>
                var cmess = BotMessageComponent("bot", prepostchatmess, mess.createdOn, null, null)
                var messobj = {
                    id: index1,
                    type: "bot",
                    message: cmess,
                    //loading:true,
                    responseTime: mess.createdOn,
                    direction: "left",
                }
                messageslist.push(messobj)
            } else if (mess.data.otherProps.action == "Request alert snapshot") {
                // var prepostchatmess =<AlertSnapshotComponent />
                var prepostchatmess =
                <>
                   <div className="chatFlowBoxContainer justify-content-start">
                <div className="chatFlowChatBoxBig float-left">
                    <p className="chatFlowChatTextBig">Alert Snapshot</p>
                </div>
            </div>
            <div className='GridBgImgChat'>
                {singleAlertDet && singleAlertDet.snapshotURL ? <img alt="Snapshot" src={singleAlertDet.snapshotURL}
                    onClick={() => handleChange(true, singleAlertDet.snapshotURL)}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = AlertGenericImg1;
                    }}
                    style={{ height: "15rem" }} /> : <AlertGenericImg />}
            </div>
                </>
                // <AlertSnapshotComponent />
                var cmess = BotMessageComponent("bot", prepostchatmess, mess.createdOn, null, null)
                var messobj = {
                    id: index1,
                    type: "bot",
                    message: cmess,
                    //loading:true,
                    responseTime: mess.createdOn,
                    direction: "left",
                }
                messageslist.push(messobj)
            }
            else if (mess.data.otherProps.botMessage == "Please attach alert evidence(E.G.: Videos, Comments, Documents, Voice notes & Photos)") {

                if (mess.data.otherProps.btnResponse != 0) {
                    var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null)
                    var messobj = {
                        id: index1,
                        type: "bot",
                        message: cmess,
                        loading: true,
                        responseTime: mess.createdOn,
                        direction: "left",
                    }
                    messageslist.push(messobj)

                    var buttonclicked = mess.data.otherProps.buttons.find(x => x.btnId == mess.data.otherProps.btnResponse)
                    index1 = index1 + 1
                    var cmess = BotMessageComponent("user", buttonclicked.buttonProperties.responseText, mess.data.otherProps.responseTime, null)
                    var messobj = {
                        id: index1,
                        type: "user",
                        message: cmess,
                        loading: true,
                        responseTime: mess.data.otherProps.responseTime,
                        direction: "right",
                    }
                    messageslist.push(messobj)
                } else {
                    var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, mess.data.otherProps.buttons, mess)
                    var messobj = {
                        id: index1,
                        type: "bot",
                        message: cmess,
                        loading: true,
                        responseTime: mess.createdOn,
                        direction: "left",
                    }
                    messageslist.push(messobj)
                }
            }
            else if (mess.data.otherProps.botMessage == "Please check the pre & post video link below:") {
                var bmess = ""
                if (alertdetails.alertPlayBackUrl.includes("https://")) {
                    bmess = <ReactPlayer
                        id={"video-" + alertdetails.alertId}
                        url={alertdetails.alertPlayBackUrl}
                        playing={true}
                        width="264px"
                        height="150px"
                        controls={true}
                        style={{ backgroundColor: "rgb(189,188,188)", borderRadius: "14px" }}
                    />
                } else {
                    bmess = alertdetails.alertPlayBackUrl
                }
                var prepostchatmess =
                    <>
                        <div className="chatFlowBoxContainer">
                            <div className="chatFlowChatBoxBig">
                                <p className="chatFlowChatTextBig">{mess.data.otherProps.botMessage}</p>
                            </div>
                        </div>
                        <p>{bmess}</p>
                    </>
                var cmess = BotMessageComponent("bot", prepostchatmess, mess.createdOn, null, null)
                var messobj = {
                    id: index1,
                    type: "bot",
                    message: cmess,
                    loading: true,
                    responseTime: mess.createdOn,
                    direction: "left",

                }
                messageslist.push(messobj)
            }
            else if (mess.data.otherProps.action == "Op Evidence") {
                var evitxt = mess.data.otherProps.botMessage
                var ertmess = ""
                if (evitxt.includes("https://")) {
                    if (evitxt.includes(".jpg") || evitxt.includes(".jpeg") || evitxt.includes(".png")) {
                        ertmess = <div className="chatFlowMultiImageContainer"><img className="chatFlowChatTimeIconBig" src={evitxt} /></div>
                    } else if (evitxt.includes(".mp3")) {
                        ertmess = <div className="chatFlowVoiceBoxContainer"><audio src={evitxt} controls /></div>
                    } else {
                        ertmess = <a className="evidence-link" href={evitxt} target="_blank">{evitxt}</a>
                    }
                } else {
                    ertmess = evitxt
                }
                var cmess = BotMessageComponent("user", ertmess, mess.data.otherProps.responseTime, null, null)
                var messobj = {
                    id: index1,
                    type: "user",
                    message: cmess,
                    responseTime: mess.data.otherProps.responseTime,
                    direction: "right",
                }
                messageslist.push(messobj)
            } else if (mess.data.otherProps.action == "ERT Evidence") {
                var evitxt = mess.data.otherProps.botMessage
                var ertmess = ""
                if (evitxt.includes("https://")) {
                    if (mess.data.otherProps.evidenceType == "img") {
                        ertmess = <div className="chatFlowMultiImageContainer"><img className="chatFlowChatTimeIconBig" src={evitxt} /></div>
                    } else if (mess.data.otherProps.evidenceType == "video") {
                        var ertmess = ""
                        if (evitxt.includes("https://")) {
                            ertmess = <ReactPlayer
                                id={"video-" + singleAlertDet.alertId}
                                url={evitxt}
                                playing={true}
                                width="264px"
                                height="150px"
                                controls={true}
                                style={{ backgroundColor: "rgb(189,188,188)", borderRadius: "14px" }}
                            />
                        } else {
                            ertmess = <a className="evidence-link" href={evitxt} target="_blank">{evitxt}</a>
                        }
                    } else if (mess.data.otherProps.evidenceType == "location") {
                        var loc = mess.data.otherProps.botMessage.split("My current location : ")
                        ertmess = <><p>{"ERT current location : "}</p><a className="evidence-link" href={loc[1]} target="_blank">{loc[1]}</a></>
                    } else if (mess.data.otherProps.evidenceType == "audio") {
                        ertmess = <div className="chatFlowVoiceBoxContainer"><audio src={evitxt} controls /></div>
                    } else {
                        ertmess = <a className="evidence-link" href={evitxt} target="_blank">{evitxt}</a>
                    }
                } else {
                    ertmess = evitxt
                }
                var emess = BotMessageComponent("ert", ertmess, mess.data.otherProps.responseTime, null, null)
                var messobj = {
                    id: index1,
                    type: "bot",
                    message: emess,
                    responseTime: mess.data.otherProps.responseTime,
                    direction: "left",
                }
                messageslist.push(messobj)
            }
            else if (mess.data.otherProps.btnResponse == 0) {
                if (mess.data.otherProps.buttons.length > 0) {
                    if(mess.data.otherProps.botMessage != "Please acknowledge the alert"){
                        var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, mess.data.otherProps.buttons, mess)
                        var messobj = {
                            id: index1,
                            type: "bot",
                            message: cmess,
                            loading: true,
                            responseTime: mess.createdOn,
                            direction: "left",
                        }
                        messageslist.push(messobj)
                    }
                } else {
                    if (mess.data.otherProps.botMessage == "Autoclose alert") {
                        var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null, null)
                        var messobj = {
                            id: index1,
                            type: "bot",
                            message: cmess,
                            loading: true,
                            responseTime: mess.createdOn,
                            direction: "left",
                        }
                        messageslist.push(messobj)

                        index1 = index1 + 1
                        var cmess = BotMessageComponent("user", "Alert has been closed successfully.", mess.createdOn, null, null)
                        var messobj = {
                            id: index1,
                            type: "user",
                            message: cmess,
                            loading: true,
                            responseTime: mess.createdOn,
                            direction: "right",
                        }
                        messageslist.push(messobj)
                    } else {
                        var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null, null)
                        var messobj = {
                            id: index1,
                            type: "bot",
                            message: cmess,
                            loading: true,
                            responseTime: mess.createdOn,
                            direction: "left",
                        }
                        messageslist.push(messobj)
                    }
                }
            } else {
                var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null)
                var messobj = {
                    id: index1,
                    type: "bot",
                    message: cmess,
                    loading: true,
                    responseTime: mess.createdOn,
                    direction: "left",
                }
                messageslist.push(messobj)

                var buttonclicked = mess.data.otherProps.buttons.find(x => x.btnId == mess.data.otherProps.btnResponse)
                if (buttonclicked) {
                    index1 = index1 + 1
                    if(mess.data.otherProps.action == "Request to select reason code" || mess.data.otherProps.action == "Request to select closure comment"){
                        var cmess = BotMessageComponent("user", mess.data.otherProps.selectedValue, mess.data.otherProps.responseTime, null)
                        var messobj = {
                            id: index1,
                            type: "user",
                            message: cmess,
                            loading: true,
                            responseTime: mess.data.otherProps.responseTime,
                            direction: "right",
                        }
                    }else{
                        var cmess = BotMessageComponent("user", buttonclicked.buttonProperties.responseText, mess.data.otherProps.responseTime, null)
                        var messobj = {
                            id: index1,
                            type: "user",
                            message: cmess,
                            loading: true,
                            responseTime: mess.data.otherProps.responseTime,
                            direction: "right",
                        }
                    }
                    messageslist.push(messobj)
                }
            }

        })

        const sorted = messageslist.sort(function (a, b) {
            return new Date(a.responseTime) - new Date(b.responseTime);
        });

        setmessages(sorted)

    }

    const AlertSnapshotComponent = () => (
        <div className="SampleOne">
            <div className="chatFlowBoxContainer justify-content-start">
                <div className="chatFlowChatBoxBig float-left">
                    <p className="chatFlowChatTextBig">Alert Snapshot</p>
                </div>
            </div>
            <div className='GridBgImgChat'>
                {singleAlertDet && singleAlertDet.snapshotURL ? <img alt="Snapshot" src={singleAlertDet.snapshotURL}
                    onClick={() => handleChange(true, singleAlertDet.snapshotURL)}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = AlertGenericImg1;
                    }}
                    style={{ height: "15rem" }} /> : <AlertGenericImg />}
            </div>
            </div>
    )

    const BotMessageComponent = (chattype, mess, chatdatetime, buttonlist) => (
        <div>
            {
                (chattype == "bot")
                    ?
                    <>
                        <div className="chatFlowBoxContainer-bot">
                            <div className="chatFlowTimeIconContainerBig">
                                <img className="chatFlowChatTimeIconBig" src={BotAvatarIcon} />
                            </div>
                            <p className="chatFlowChatTimeIconTextBig">{frontDateConverter(chatdatetime)}</p>
                        </div>
                        {
                            <div className="chatFlowBoxContainer-bot">
                                <div className="chatFlowChatBoxBig">
                                    <p className="chatFlowChatTextBig">{mess}</p>
                                </div>
                            </div>
                        }
                    </>
                    :
                    (chattype == "ert")
                        ?
                        <>
                            <div className="chatFlowBoxContainer-bot">
                                <div className="chatFlowTimeIconContainerBig">
                                    <img className="chatFlowChatTimeIconBig" src={ERTAvatarIcon} />
                                </div>
                                <p className="chatFlowChatTimeIconTextBig">{frontDateConverter(chatdatetime)}</p>
                            </div>
                            <div className="chatFlowBoxContainer-bot">
                                <div className="chatFlowChatBoxBig">
                                    <p className="chatFlowChatTextBig">{mess}</p>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="chatFlowBoxContainer">

                                <p className="chatFlowChatTimeIconTextBig">{frontDateConverter(chatdatetime)}</p>
                                <div className="chatFlowTimeIconContainerBig">
                                    <img className="chatFlowChatTimeIconBig" src={UserAvatarIcon} />
                                </div>
                            </div>
                            <div className="chatFlowBoxContainer">
                                <div className="chatFlowChatBoxBig">
                                    <p className="chatFlowChatTextBig">{mess}</p>
                                </div>
                            </div>
                        </>

            }
        </div>
    )

    //#region Live component

    const AssociatedDevicesListLiveComponent = (lst) => {
        return <>
            {lst.length > 0 &&
                <div>
                    <AlertSourceDeviceLiveComponent />
                    <span>Associated Devices</span>
                    {lst.map(item => (
                        PlayerComponent(item.deviceId, item.cameraName, item.hlsStreamingUrl)
                    ))}
                </div>
            }
            {lst.length == 0 &&
                <>
                    <AlertSourceDeviceLiveComponent />
                    <span className="Ass_Name">Associated Devices</span>
                    <p>No devices associated</p>
                </>
            }
        </>
    }

    const AlertSourceDeviceLiveComponent = () => (
        <>
            {
                (singleAlertDet.deviceTypeID == 1)
                    ?
                    <>
                        <span>Alert Device</span>
                        {PlayerComponent(singleAlertDet.deviceId, singleAlertDet.deviceName, singleAlertDet.hlsStreamingUrl)}
                        <hr className="solid"></hr>
                    </>
                    :
                    <></>
            }
        </>

    )
    const PlayerComponent = (devid, devname, devurl) => (
        <div className="chatbot-live-camera" key={devid}>
            <p className="LocationNameText camera-name"><span>{devname}</span> </p>
            {
                devurl != null
                    ?
                    <ReactPlayer
                        id={"video-" + devid}
                        url={devurl}
                        playing={true}
                        width="264px"
                        height="150px"
                        controls={true}
                        style={{ backgroundColor: "rgb(189,188,188)", borderRadius: "14px" }} />
                    :
                    <img src={MosaicNoStream} alt="mosaic" style={{ height: "150px", width: "264px", backgroundColor: '#D0D2D1', borderRadius: "14px" }} />
            }
        </div>
    )

    //#endregion
    const ClosePreview = () => {
        setisOpenmodal(false)
    }
    const handleChange = (isimg, snapurl) => {
        setisimage(isimg)
        setisOpenmodal(true)
        setsnapshoturl(snapurl)
    }

    const downloadImage = (dataUrl, file_name) => {
        /* ------------------------- JPG/PNG Download Block ------------------------- */
        // let file_n = file_name + '.png'
        // const a = document.createElement('a');
        // a.setAttribute('download', file_n);
        // a.setAttribute('href', dataUrl);
        // a.click();
    
        /* --------------------------- PDF Download Block --------------------------- */
        const doc = new jsPDF('p', 'pt', 'a4');
        let width = doc.internal.pageSize.getWidth()
        let height = doc.internal.pageSize.getHeight()
        doc.addImage(dataUrl, null, 0, 0, width, height, null, 'FAST');
        doc.save(`${file_name}.pdf`);
    }

    const exportChart = () => {
        toPng(document.querySelector('.react-functional-chatbot-main-div')
        ).then(function (dataUrl) {
            downloadImage(dataUrl, "Sample PDF")
        })
    }

    function capture(video, scaleFactor) {
        if (scaleFactor == null) {
          scaleFactor = 1;
        }
        // let w = video.videoWidth * scaleFactor;
        // let h = video.videoHeight * scaleFactor;
        // let h = video.videoHeight
        // let w = video.videoWidth
        // let h = video.videoHeight > 700 ? 480 : video.videoHeight
        // let w = video.videoWidth > 750 ? 640 : video.videoWidth
        let h = 500
        let w = 540
        let canvas = document.createElement('canvas');
        canvas.setAttribute("id", "canvasId")
        canvas.width = w;
        canvas.height = h;
        let ctx = canvas.getContext('2d');
        let blobd = canvas.toBlob((blob) => {
          console.log("Canvas", blob)
        });
        let url = canvas.toDataURL();
        // canvas.toBlob() = (blob) => {
        //   const img = new Image();
        //   img.src = window.URL.createObjectURL(blob);
        // }
        ctx.drawImage(video, 0, 0, w, h);
        return canvas;
    }

    const shoot = () => {
        let scaleFactor = 0.25;
        var video = document.getElementById('react-functional-chatbot-main-div');
        var output = document.getElementById('output');
        var canvas = capture(video, scaleFactor);
    
        output.innerHTML = '';
        output.append(canvas);
    }

    const reportTemplateRef = useRef(null);

    const handleGeneratePdf = () => {
		// const doc = new jsPDF({
		// 	format: 'a4',
		// 	unit: 'px',
		// });

        let jsPdfDoc = new jsPDF('portrait', 'pt', 'a4');


		// Adding the fonts.
		// doc.setFont('Inter-Regular', 'normal');

		// doc.html(reportTemplateRef.current, {
		// 	async callback(doc) {
		// 		await doc.save('document');
		// 	},
		// });

        // let pageHeight= doc.internal.pageSize.height;
        // let pageWidth= doc.internal.pageSize.width;
        let element = document.querySelector('#contentToPrint');
        jsPdfDoc.html(element, {
			async callback(doc) {
				await doc.save('document');
			},
            // x: 0,
            // y: 0,
            autoPaging: 'text',
            // margin: [700, 0, 700, 0],
		});
	};

    const loadImage = (imageUrl) => {
        let img = new Image();

        img.src = imageUrl;

        img.onload()
    }

    function getBase64Image(img) {

        var canvas = document.createElement("canvas");
    
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
    
        ctx.drawImage(img, 0, 0);
    
        var dataURL = canvas.toDataURL("image/jpeg");
    
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    
    }

    function imageToBase64(src, callback) {
        // Create an image element
        var img = new Image();
        
        // Set the onload event handler
        img.onload = function() {
            // Create a canvas element
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            
            // Set the canvas dimensions to match the image
            canvas.width = img.width;
            canvas.height = img.height;
            
            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);
            
            // Get the base64 encoded data URL from the canvas
            var dataURL = canvas.toDataURL('image/png'); // Change 'image/png' to the appropriate MIME type
            
            // Pass the data URL to the callback function
            callback(dataURL);
        };
        
        // Set the image source
        img.src = src;
    }

    const handleDownloadDummy = async () => {

        let files = singleAlertDet?.evidenceList.map((item) => {
            let obj = {
                name: item.AttachedDocTitle,
                url: item.DocumentURL
            }
            return obj;

        })

        // setDownloading(true);

        const zip = new JSZip();
        const filePromises = [];

        files.forEach(file => {
            const promise = axios.get(file.url, { responseType: 'arraybuffer' })
                .then(response => {
                    zip.file(file.name, response.data);
                })
                .catch(error => console.error('Error fetching file:', error));
            filePromises.push(promise);
        });



        Promise.all(filePromises)
            .then(() => {
                zip.generateAsync({ type: 'blob' })
                    .then(blob => {
                        saveAs(blob, `Alert_${singleAlertDet.alertId}_attachments.zip`);
                        // setDownloading(false);
                    })
                    .catch(error => console.error('Error generating ZIP:', error));
            })
            .catch(error => console.error('Error downloading files:', error));
    };    

    const handleDownload = async (files) => {
        // setDownloading(true);

        const zip = new JSZip();
        const filePromises = [];

        // This will be used for downloading of the prepost video file.
        if(prePostFileUrl != ""){

            // Download inside zip file from the below
            let individualPromise = axios.get(prePostFileUrl, { responseType: 'arraybuffer'})
            .then(resp => {
                zip.file(`Alert_${singleAlertDet.alertId}_Pre-Post_Video.mp4`, resp.data);
            })
            .catch(err => console.log("Error", err))
    
            filePromises.push(individualPromise);

            //Download as another file from below.
            // download(prePostFileUrl, `Alert_${singleAlertDet.alertId}_Pre-Post_Video.mp4`)
        }

        files.forEach(file => {
            const promise = axios.get(file.url, { responseType: 'arraybuffer', 
            headers: { "Cache-Control": "no-cache" } })
                .then(response => {
                    zip.file(file.name, response.data);
                })
                .catch(error => console.error('Error fetching file:', error));
            filePromises.push(promise);
        });

        Promise.all(filePromises)
            .then(() => {
                zip.generateAsync({ type: 'blob' })
                    .then(blob => {
                        saveAs(blob, `Alert_${singleAlertDet.alertId}_attachments.zip`);
                        // setDownloading(false);
                    })
                    .catch(error => console.error('Error generating ZIP:', error));
            })
            .catch(error => console.error('Error downloading files:', error));
    };

    const downloadPrePostLocal = (pre, post, obj) => {
        let cam_username = obj.CamUserName;
        let cam_password = obj.CamPassword;
        let cam_port = obj.CamPort;
        let cam_ipaddress = obj.PrivateIPAddress;
        let cam_brandName = obj.BrandName;
        let channelNo = obj.ChannelNum;
        let playbackSubType = obj.SubTypePlayback
        let split1 = singleAlertDet.actualTime.split(' ')
        let split2 = split1[0].split('-')
    
        let newdateSplit = `${split2[2]}-${split2[1]}-${split2[0]} ${split1[1]}`
        let alerttime = new Date(newdateSplit)
    
        // let preposttime = 3600 //1800 sec 30min
        let pretime = pre;
        let posttime = post//1800 sec 30min
        alerttime.setSeconds(alerttime.getSeconds() - pretime)
        let styy = alerttime.getFullYear()
        let stmm = alerttime.getMonth() + 1
        let stdd = alerttime.getDate()
        let sth = alerttime.getHours()
        let stm = alerttime.getMinutes()
        let sts = alerttime.getSeconds()
    
        alerttime.setSeconds(alerttime.getSeconds() + pretime + posttime)
        let etyy = alerttime.getFullYear()
        let etmm = alerttime.getMonth() + 1
        let etdd = alerttime.getDate()
        let eth = alerttime.getHours()
        let etm = alerttime.getMinutes()
        let ets = alerttime.getSeconds()
    
        if (stmm.toString().length == 1) { stmm = "0" + stmm.toString() }
        if (stdd.toString().length == 1) { stdd = "0" + stdd.toString() }
        if (sth.toString().length == 1) { sth = "0" + sth.toString() }
        if (stm.toString().length == 1) { stm = "0" + stm.toString() }
        if (sts.toString().length == 1) { sts = "0" + sts.toString() }
    
        if (etmm.toString().length == 1) { etmm = "0" + etmm.toString() }
        if (etdd.toString().length == 1) { etdd = "0" + etdd.toString() }
        if (eth.toString().length == 1) { eth = "0" + eth.toString() }
        if (etm.toString().length == 1) { etm = "0" + etm.toString() }
        if (ets.toString().length == 1) { ets = "0" + ets.toString() }
    
        // let startTime = styy + "_" + stmm + "_" + stdd + "_" + sth + "_" + stm + "_" + sts;
        // let endTime = etyy + "_" + etmm + "_" + etdd + "_" + eth + "_" + etm + "_" + ets

        let startTime = styy + "-" + stmm + "-" + stdd + " " + sth + ":" + stm + ":" + sts;
        let endTime = etyy + "-" + etmm + "-" + etdd + " " + eth + ":" + etm + ":" + ets
        let fileName = `Alert_${singleAlertDet.alertId}_Pre-Post_Video.mp4`
        let tempObj = {
            privateIpAddress: cam_ipaddress,
            camPort: cam_port,
            user: cam_username,
            password: cam_password,
            brandName: cam_brandName,
            fileName: fileName,
            startTime: startTime,
            endTime: endTime,
            channelNo: channelNo,
            playbackSubType: playbackSubType,
            communicationPort: obj.Communicationport,
            internetPort: obj.InternetProtocolPort
        }
        setLocalObj(tempObj);
     }

     const downloadFromLocal = (obj) => {
        let { privateIpAddress, communicationPort, user, password, brandName, fileName, startTime, endTime, channelNo, camPort, internetPort } = obj
              //Bug - 7098 Resolution
            // setDownloadingLocal(true);
        WarningToast("Check your downloads folder for prepost video after 30-40 seconds.")
        setShow(false);
        localFileDownload(privateIpAddress, communicationPort, user, password, brandName, fileName, startTime, endTime, channelNo)
            .then((data) => {
                setTimeout(() => checkFile(fileName), FILE_DOWNLOAD_CHECK_WAIT_TIME)
            //   checkFileDownloaded(fileName) 
            // .then((res) => {
            //   console.log("checkFileDownloaded", res);
            //   if(res.res == "Yes"){
            //     SuccessToast(fileName+ " video exported successfully")
            //   }else{
            //     ErrorToast(fileName+ " video failed to export")
            //   }
            // })
            // .catch(err => {
            //    ErrorToast(fileName+ " video failed to export")
            //   console.log("checkFileDownloaded", err);
            // })
            })
            .catch(err => console.log(err))
     }

     const checkFile = (fileName) => {
        checkFileDownloaded(fileName) 
            .then((res) => {
              if(res.res == "Yes"){
                SuccessToast(fileName+ " video exported successfully")
              }else{
                ErrorToast(fileName+ " video failed to export")
              }
            })
            .catch(err => {
               ErrorToast(fileName+ " video failed to export")
            })
     }

    const handleCustomExport = () => {
        let doc = new jsPDF("p", "pt", "a4");
        let attachmentArray = [];
        let zipAttachmentArray = [];
        let y = 25;
        let leftX = 20;
        let rightX = 320
        let smallText = 7;
        let bigText = 10;
        let midText = 9;
        let largeText = 15;
        let smallDiff = 15;
        let bigDiff = 35;
        let isImage = false;
        let isEscMatrix = false;
        let escString = ""
        let pageMinHeight = 760
        let escalationArr = [];
        doc.setFontSize(bigText);

        // Adding the header to the report
        let header = "Alert Report"

        // Adding the MH image to the report
        doc.addImage(monitoringHubLogo_dataURL, 'JPEG', 10, 5, 75, 40);
        doc.setFontSize(largeText);
        doc.text(header, 250, 20);
        const textWidth = doc.getTextWidth(header); 
        // doc.line(250, 20, 250 + textWidth, 20)

        // Adding the sub header to the report
        y = y + smallDiff;
        doc.setFontSize(bigText)
        doc.text(`Alert Id - ${singleAlertDet.alertId} | Site - ${singleAlertDet.site} | Device - ${singleAlertDet.deviceName} | Time - ${singleAlertDet.actualTime}`, 100, 40);

        y = y + bigDiff;

        // Logic for iterating through the chatflow history.
        for(let i=0; i<messages.length; i++){

            // If block is considering the messages to be printed on the left side of the pdf (Bot Messages)
            if(messages[i].direction == "left"){
                doc.setFontSize(smallText);
                let str = "MH  -  " + messages[i].message.props.children.props.children[0].props.children[1].props?.children 
                doc.text(str, leftX, y);
                y = y + smallDiff;
                let printTextLeft = "";
                if(typeof(messages[i].message.props.children.props.children[1].props?.children?.props?.children?.props?.children) != "string"){
                    if(typeof(messages[i]?.message?.props?.children?.props?.children[1]?.props?.children?.props?.children?.props?.children?.props?.children[0]?.props?.children?.props?.children?.props?.children) == "string"){
                        printTextLeft = messages[i].message.props.children.props.children[1].props?.children?.props?.children?.props?.children?.props?.children[0]?.props?.children?.props?.children?.props?.children;
                        if(printTextLeft == "Alert Snapshot"){
                            doc.setFontSize(bigText);
                            doc.text(printTextLeft, leftX, y);
                            y = y + smallDiff;
                            if (typeof (messages[i].message.props.children.props.children[1].props?.children?.props?.children?.props?.children?.props?.children[1]?.props?.children?.props?.src) == "string") {
                                let imageSrc = messages[i].message.props.children.props.children[1].props?.children?.props?.children?.props?.children?.props?.children[1]?.props?.children?.props?.src;
                                console.log("Image Tag", imageSrc)

                                let extensionArr = imageSrc.split('.');
                                let extensionFormat = extensionArr[extensionArr.length - 1];
                                let fileFormat = imageSrc.slice(-3);
                                // let fName = `Attachment_${parseInt(zipAttachmentArray.length+1)}.${fileFormat}`
                                let fName = `Attachment_${parseInt(zipAttachmentArray.length+1)}.${extensionFormat}`
                                let obj = {
                                    name: fName,
                                    url: imageSrc
                                }
                                zipAttachmentArray.push(obj);

                                let text = fName + " in Alert_" + singleAlertDet.alertId + "_attachments folder" 

                                doc.text(text, leftX, y);
                                y = y + bigDiff;
                                if (y > pageMinHeight && i.length != messages.length - 1) {
                                    doc.addPage();
                                    y = 20;
                                }

                            } else {
                                doc.text('No Snapshot Found', leftX, y)
                                y = y + bigDiff;
                                if (y > pageMinHeight && i.length != messages.length - 1) {
                                    doc.addPage();
                                    y = 20;
                                }
                                console.log("NoAlertImage", messages[i].message.props.children.props.children[1].props?.children?.props?.children?.props.children?.props?.children[1].props.children.props.src)
                            }

                            continue;
                        }
                        else if(printTextLeft == "Escalation Matrix"){
                            console.log("Escalation", printTextLeft);
                            console.log("Escalation Error", messages[i].message.props.children.props.children[1].props.children.props.children.props.children.props.children[1].props.children)
                            // console.log("Escalation", messages[i].message.props.children.props.children[1].props.children.props.children.props.children.props.children[1].props.children[0].props.children[0].props.children.props.children);
                            if(messages[i].message.props.children.props.children[1].props.children.props.children.props.children.props.children[1].props.children != "No Contacts for Escalation"){
                                escalationArr = messages[i].message.props.children.props.children[1].props.children.props.children.props.children.props.children[1].props.children[0].props.children; 
                                // console.log("1st", messages[i].message.props.children.props.children[1].props.children.props.children.props.children.props.children[1].props.children[0].props.children.length);
                                // console.log("1st", messages[i].message.props.children.props.children[1].props.children.props.children.props.children.props.children[1].props.children[0].props.children[0].props.children.props.children)
                                // console.log("1st", messages[i].message.props.children.props.children[1].props.children.props.children.props.children.props.children[1].props.children[0].props.children[1].props.children.props.children);
                                if(escalationArr.length > 1){
                                    // for(let q = 0; q < escalationArr.length; q++){
                                    //     if(Array.isArray(messages[i].message.props.children.props.children[1].props.children.props.children.props.children.props.children[1].props.children[0].props.children[q].props.children.props.children)){
                                    //         console.log("is Array", messages[i].message.props.children.props.children[1].props.children.props.children.props.children.props.children[1].props.children[0].props.children[q].props.children.props.children)
                                    //         let arr = messages[i].message.props.children.props.children[1].props.children.props.children.props.children.props.children[1].props.children[0].props.children[q].props.children.props.children;
                                    //         console.log("isArray", arr.toString().replaceAll(",", ""));
                                    //         escString = arr.toString().replaceAll(",", "")
                                    //         console.log("escString", escString);
                                    //         // isEscMatrix = true;
                                    //         doc.setFontSize(midText);
                                    //         y = y - 10;
                                    //         doc.text(escString, leftX, y);
                                    //         y = y + bigDiff;
                                    //         if(y > pageMinHeight && i.length != messages.length - 1){
                                    //             doc.addPage();
                                    //             y = 20;
                                    //         }
                                    //         escString = "";
                                    //     }
                                    // }
                                }else{
                                    if(Array.isArray(messages[i].message.props.children.props.children[1].props.children.props.children.props.children.props.children[1].props.children[0].props.children[0].props.children.props.children)){
                                        let arr = messages[i].message.props.children.props.children[1].props.children.props.children.props.children.props.children[1].props.children[0].props.children[0].props.children.props.children;
                                        escString = arr.toString().replaceAll(",", "")
                                        isEscMatrix = true;
                                    }    
                                }
                            }else{
                                escString = "No Contacts for Escalation"
                                isEscMatrix = true;
                            }
                         }
                    }else{
                        if(typeof(messages[i].message.props.children.props.children[1].props?.children?.props?.children?.props?.children?.props?.children?.props?.src) != "undefined"){
                            printTextLeft = messages[i].message.props.children.props.children[1].props?.children?.props?.children?.props?.children?.props?.children?.props?.src;
                        }else{
                            printTextLeft = "Snapshot not present"
                        }
                    }
                }else{
                    printTextLeft = messages[i].message.props.children.props.children[1].props?.children?.props?.children?.props?.children
                }
                // doc.text(printTextLeft, x, leftY);
                doc.setFontSize(bigText);
                doc.text(printTextLeft, leftX, y);
                y = y + bigDiff;
                if(y > pageMinHeight && i.length != messages.length - 1){
                    doc.addPage();
                    y = 20;
                }
                if(isEscMatrix){
                    doc.setFontSize(midText);
                    y = y - 10;
                    doc.text(escString, leftX, y);
                    y = y + bigDiff;
                    if(y > pageMinHeight && i.length != messages.length - 1){
                        doc.addPage();
                        y = 20;
                    }
                    isEscMatrix = false;
                    escString="";
                }
                if(escalationArr.length > 1){
                    for(let q = 0; q < escalationArr.length; q++){
                        if(Array.isArray(messages[i].message.props.children.props.children[1].props.children.props.children.props.children.props.children[1].props.children[0].props.children[q].props.children.props.children)){
                            let arr = messages[i].message.props.children.props.children[1].props.children.props.children.props.children.props.children[1].props.children[0].props.children[q].props.children.props.children;
                            escString = arr.toString().replaceAll(",", "")
                            // isEscMatrix = true;
                            doc.setFontSize(midText);
                            y = y - 10;
                            doc.text(escString, leftX, y);
                            y = y + bigDiff;
                            if(y > pageMinHeight && i.length != messages.length - 1){
                                doc.addPage();
                                y = 20;
                            }
                            escString = "";
                        }
                    }
                    escalationArr = [];
                }
                // x = x + 20;
            }
            // Else block is considering the messages to be printed on the right side of the pdf (User Messages)
            else{
                let printTextRight = "";
                doc.setFontSize(smallText);
                let str = singleAlertDet?.userName.trim() + "  -  " +messages[i].message.props.children.props.children[0].props.children[0].props?.children 
                doc.text(str, rightX, y)
                y = y + smallDiff
                if(typeof(messages[i].message.props.children.props.children[1].props?.children?.props?.children?.props?.children) != "string"){

                    if(typeof(messages[i].message.props.children.props.children[1].props?.children?.props?.children?.props?.children?.props?.children[0]?.props?.children?.props?.children?.props?.children) == "string"){
                        printTextRight = messages[i].message.props.children.props.children[1].props?.children?.props?.children?.props?.children?.props?.children[0]?.props?.children?.props?.children?.props?.children;
                    }else{
                        if(typeof(messages[i].message.props.children.props.children[1].props?.children?.props?.children?.props?.children?.props?.children?.props?.src) != "undefined"){
                            if(messages[i].message.props.children.props.children[1].props?.children?.props?.children?.props?.children?.props?.children?.type == "audio"){
                                let extensionArr = messages[i].message.props.children.props.children[1].props?.children?.props?.children?.props?.children?.props?.children?.props?.src.split('.');
                                let extensionFormat = extensionArr[extensionArr.length - 1];
                                // let fileFormat = messages[i].message.props.children.props.children[1].props?.children?.props?.children?.props?.children?.props?.children?.props?.src.slice(-3);
                                // let fName = `Attachment_${parseInt(zipAttachmentArray.length+1)}.${fileFormat}`
                                let fName = `Attachment_${parseInt(zipAttachmentArray.length+1)}.${extensionFormat}`
                                
                                // let fetchFileName = getFileName(messages[i].message.props.children.props.children[1].props?.children?.props?.children?.props?.children?.props?.children?.props?.src)
                                // if (fetchFileName != "") {
                                //     fName = fetchFileName
                                // }
                                
                                let obj = {
                                    name: fName,
                                    url: messages[i].message.props.children.props.children[1].props?.children?.props?.children?.props?.children?.props?.children?.props?.src
                                }
                                attachmentArray.push(messages[i].message.props.children.props.children[1].props?.children?.props?.children?.props?.children?.props?.children?.props?.src);
                                zipAttachmentArray.push(obj);
                                // printTextRight = "Attachment downloaded in zip file - " + fName
                                printTextRight = fName + " in Alert_" + singleAlertDet.alertId + "_attachments folder" 
                            }else{
                                isImage = true;
                                printTextRight = messages[i]?.message?.props?.children?.props?.children[1].props?.children?.props?.children?.props?.children?.props?.children?.props?.src;    
                            }
                        }else{
                            let extensionArr = messages[i]?.message?.props?.children?.props?.children[1]?.props?.children?.props?.children?.props?.children?.props?.children.split('.');
                            let extensionFormat = extensionArr[extensionArr.length - 1];
                            // let fileFormat = messages[i]?.message?.props?.children?.props?.children[1]?.props?.children?.props?.children?.props?.children?.props?.children.slice(-3);
                            // let fName = `Attachment_${parseInt(zipAttachmentArray.length+1)}.${fileFormat}`
                            let fName = `Attachment_${parseInt(zipAttachmentArray.length+1)}.${extensionFormat}`
                            
                            // let fetchFileName = getFileName(messages[i]?.message?.props?.children?.props?.children[1]?.props?.children?.props?.children?.props?.children?.props?.children)
                            // if(fetchFileName != ""){
                            //     fName = fetchFileName
                            // }

                            let obj = {
                                name: fName,
                                url: messages[i]?.message?.props?.children?.props?.children[1]?.props?.children?.props?.children?.props?.children?.props?.children
                            }
                            attachmentArray.push(messages[i]?.message?.props?.children?.props?.children[1]?.props?.children?.props?.children?.props?.children?.props?.children);
                            zipAttachmentArray.push(obj);
                            // printTextRight = "Attachment downloaded in zip file - " + fName;
                            printTextRight = fName + " in Alert_" + singleAlertDet.alertId + " attachments folder"
                        }
                    }
                }else{
                    printTextRight = messages[i]?.message?.props?.children?.props?.children[1]?.props?.children?.props?.children?.props?.children;
                }
                doc.setFontSize(bigText);
                if(isImage){
                    let extensionArr = printTextRight.split('.');
                    let extensionFormat = extensionArr[extensionArr.length - 1];
                    // let fileFormat = printTextRight.slice(-3);
                    // let fName = `Attachment_${parseInt(zipAttachmentArray.length+1)}.${fileFormat}`
                    let fName = `Attachment_${parseInt(zipAttachmentArray.length+1)}.${extensionFormat}`
                    
                    // let fetchFileName = getFileName(printTextRight)
                    // if(fetchFileName != ""){
                    //     fName = fetchFileName
                    // }

                    let obj = {
                        name: fName,
                        url: printTextRight
                    }
                    zipAttachmentArray.push(obj);
                    printTextRight = fName + " in Alert_" + singleAlertDet.alertId + " attachments folder"
                    doc.text(printTextRight, rightX, y, { maxWidth: 250});
                    isImage = false;
                }else{
                    doc.text(printTextRight, rightX, y, { maxWidth: 250});
                }
                y = y + bigDiff;
                if(y > pageMinHeight && i.length != messages.length - 1){
                    doc.addPage();
                    y = 20;
                }
                // x = x + 20;
            }

        }
        
        if(singleAlertDet?.alertComment != null){
            y = y + bigDiff;
            let remarkText = "Remark: " + singleAlertDet.alertComment
            doc.text(remarkText, leftX, y, {
                maxWidth: 520
            })
        }

        let fileName = "Alert_" + singleAlertDet.alertId + "_report" + ".pdf"
        // for(let i=0; i<attachmentArray.length; i++){
        //     download(attachmentArray[i], `Alert_${singleAlertDet.alertId}_Attachment_${parseInt(i+1)}`);
        // }

        for(let i=0; i<singleAlertDet.evidenceList.length; i++){
            if(zipAttachmentArray.some(item => item.url == singleAlertDet.evidenceList[i].DocumentURL)){
                console.log("PPP Same", singleAlertDet.evidenceList[i].DocumentURL);
            }else{
                let extensionArr = singleAlertDet.evidenceList[i].DocumentURL.split('.');
                let extensionFormat = extensionArr[extensionArr.length - 1];
                // let fileFormat = singleAlertDet.evidenceList[i].DocumentURL.slice(-3);
                // let fName = `Attachment_${parseInt(zipAttachmentArray.length+1)}.${fileFormat}`
                let fName = `Attachment_${parseInt(zipAttachmentArray.length+1)}.${extensionFormat}`
                
                // let fetchFileName = getFileName(singleAlertDet.evidenceList[i].DocumentURL)
                //     if(fetchFileName != ""){
                //         fName = fetchFileName
                //     }

                let obj = {
                    name: fName,
                    url: singleAlertDet.evidenceList[i].DocumentURL
                }
                zipAttachmentArray.push(obj);
            }
        }

        if(localObj != null){
            downloadFromLocal(localObj)
        }

        if(zipAttachmentArray.length > 0){
            handleDownload(zipAttachmentArray);
        }else if(zipAttachmentArray.length == 0 && prePostFileUrl != ""){
            handleDownload(zipAttachmentArray);
        }
        // Adding page no to the bottom of the pages.
        addFooters(doc);
        doc.save(fileName);

    }

    function download(url, filename) {
        fetch(url)
          .then(response => response.blob())
          .then(blob => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        })
        .catch(console.error);
      }

      function addFooters(doc) {
        const pageCount = doc.internal.getNumberOfPages();
        for(var i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.text(String(i), 280, 820);
        }
    }
      
    const exportHTML = () => {
        console.log("exportHTML", messages);
    }

    // const downloadPdfDocumentTemp = () => {
    //     const input = document.getElementById('contentToPrintModal');
    //     document.getElementById('contentToPrintModal').style.height = 'auto';
    
    //     const inputHeight = input.clientHeight;
    
    //     const pdf = new jsPDF({
    //       orientation: 'landscape',
    //     });
    
    //     var wi = pdf.internal.pageSize.getWidth();
    
    //     let height = pdf.internal.pageSize.height;
    //     let pageHeightInPixels = inputHeight;
    //     let pages = pageHeightInPixels / height;
    
    //     const roundOff = Number(pages.toString().split('.')[1].substring(0, 1));
    //     const pageNo = roundOff > 0 ? pages + 1 : pages;
    //     let pageCount = pages < 1 ? 1 : Math.trunc(pageNo);
    
    //     html2canvas(input, {
    //       // scrollX: 0,
    //       // scrollY: 0,
    //       // scrollY: -window.scrollY,
    //       // scale: 1,
    //       // dpi: 300,
    //     }).then((canvas) => {
    //       const imgData = canvas.toDataURL('image/png');
    //       pdf.text(`Alert Id - ${singleAlertDet.alertId} | Site - ${singleAlertDet.site} | Device - ${singleAlertDet.deviceName} | Time - ${singleAlertDet.actualTime}`, 50, 10);

    //       for (let i = 1; i <= pageCount; i++) {
    //         let pdfStartingHeight = height * (i - 1);
    //         pdf.addImage(
    //           imgData,
    //           'PNG',
    //           0,
    //           -pdfStartingHeight + 20,
    //           wi,
    //           inputHeight * 0.8
    //         );
    //         if (i < pageCount) {
    //           pdf.addPage();
    //         }
    //       }
    //       var pageLen = pdf.internal.getNumberOfPages();
    //       pdf.deletePage(pageLen);
    //       pdf.deletePage(pageLen - 1);
    //       pdf.save('download.pdf');
    //     });
    //   };

    return (
        <>
            {/* modal is for snapshot preview only start*/}
            <Modal 
            show={downloadingLocal}
            backdrop="static"
            className="loaderSpinner"
            >
                {/* <LoaderModal />     */}
                <Spinner style={{marginLeft: '6rem'}} animation="border" variant="primary" />
                <span style={{padding: '1rem'}}>
                Downloading files... Please wait
                </span>
            </Modal>
            <Modal
                show={isOpenmodal}
                dialogClassName={"activityUpdateModel"}
                onHide={ClosePreview}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton style={{ padding: "0 1rem", borderBottom: "0" }}></Modal.Header>
                <Modal.Body>
                    <div id="EvidenceViewContainer">
                        <div style={{ display: (isimage ? 'block' : 'none') }}>
                            <img
                                style={{ height: "500px", width: "465px" }}
                                src={snapshoturl}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* modal is for snapshot preview only end*/}

            <Modal
            size="lg"
            show={showEmail}
            dialogClassName="modal-90w"
            backdrop="static" 
            // className="AddCustomerModal"
            onHide={() => setShowEmail(false)}
            >

                <Modal.Header closeButton>
                    <Modal.Title>Email Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="DeviceList">
                    <div className="inputMainContainer w40dot75rem mt35">
                                    <div className="inputTypeLabelContainer">
                                        <label className='formFieldLabels'>Email ID</label> 
                                        <input
                                            className="inputType"
                                            // className="SiteNameTextBox"
                                            placeholder="Ex. example@monitoringhub.in"
                                            // {...register("Email")}
                                            // defaultValue={user.Email}
                                            // disabled={!isUserEdit || user.UserID != 0} 
                                            // onChange={() => setUserEmailError('')}
                                            />  
                                            <p className="Error_P" style={{float: "left"}}><span> {} </span></p>  
                                    </div>
                    </div>
                    <div className="EmailDownloadBox">
                        <Row className="header-width" style={{ margin: "0 auto" }}>
                        <div className="Add_Devices add-devices-heading">
                            <p>Following details and evidences will be emailed:</p>
                        </div>
                        </Row>
                        <div className="lst-section-width" style={{height: "0%"}}>
                        <div className="column" style={{width: "100%"}}>
                        <p className="SiteNameText ml-2 mb-0">
                        Chat(s): <span>Complete history</span> 
                        </p>
                        </div>
                        <div className="column" style={{width: "100%"}}>
                        <p className="SiteNameText ml-2 mb-0">
                        Image(s):  <span>image-1, image-name-2, image-20-03-2024</span> 
                        </p>
                        </div>
                        <div className="column" style={{width: "100%"}}>
                        <p className="SiteNameText ml-2 mb-0">
                        Video(s): <span>video-1, video-2</span> 
                        </p>
                        </div>
                        <div className="column" style={{width: "100%"}}>
                        <p className="SiteNameText ml-2 mb-0">
                        Voice note(s): <span>voiceNote-20-03-2024</span> 
                        </p>
                        </div>
                        <div className="column" style={{width: "100%"}}>
                        <p className="SiteNameText ml-2 mb-0">
                        Cloud file(s): <span>no cloud file found</span>
                        </p>
                        </div>
                        </div>
                    </div>
                    <div className='filterbuttonsdiv'>
                        <button className='DataAccessEdit ml-0' style={{width: "100%"}}>
                            <span className='ViewUpdate'>Email Now</span>
                        </button>
                    </div>

                    <div className="inputMainContainer w25dot75rem successMsg" style={{ display: "flex", alignItems: "center" }}>
                    <img src={CheckmarkSuccessCircle} />
                      <p className="closemarkErrorText">Email sent successfully.</p>
                    </div>
                </div>
                </Modal.Body>
            </Modal>

            <Modal
            size="lg"
            // show={true}
            show={show}
            dialogClassName="modal-90w"
            backdrop="static" 
            // className="AddCustomerModal"
            onHide={() => setShow(false)}
            >

                <Modal.Header closeButton>
                    <Modal.Title>Download Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="DeviceList">
                <div className="EmailDownloadBox">
                    <Row className="header-width" style={{ margin: "0 auto" }}>
                    <div className="Add_Devices add-devices-heading">
                        <p>Following details and evidences will be downloaded:</p>
                    </div>
                    </Row>
                    <div className="lst-section-width" style={{height: "0%"}}>
                    <div className="column" style={{width: "100%"}}>
                      <p className="SiteNameText ml-2 mb-0" style={{fontWeight: "bold"}}>
                      Chat(s): <span style={{fontWeight: "400"}}>Complete history</span> 
                      </p>
                    </div>
                    <div className="column" style={{width: "100%"}}>
                      <p className="SiteNameText ml-2 mb-0" style={{fontWeight: "bold", wordWrap: "break-word"}}>
                      Image(s):  <span style={{fontWeight: "400"}}>{imageNames}</span> 
                      </p>
                    </div>
                    <div className="column" style={{width: "100%"}}>
                      <p className="SiteNameText ml-2 mb-0" style={{fontWeight: "bold", wordWrap: "break-word"}}>
                      Video(s): <span style={{fontWeight: "400"}}>{videoNames}</span> 
                      </p>
                    </div>
                    <div className="column" style={{width: "100%"}}>
                      <p className="SiteNameText ml-2 mb-0" style={{fontWeight: "bold", wordWrap: "break-word"}}>
                      Voice note(s): <span style={{fontWeight: "400"}}>{audioNames}</span> 
                      </p>
                    </div>
                    <div className="column" style={{width: "100%"}}>
                      <p className="SiteNameText ml-2 mb-0" style={{fontWeight: "bold", wordWrap: "break-word"}}>
                      Doc(s): <span style={{fontWeight: "400"}}>{docNames}</span> 
                      </p>
                    </div>
                    <div className="column" style={{width: "100%"}}>
                      <p className="SiteNameText ml-2 mb-0" style={{fontWeight: "bold", wordWrap: "break-word"}}>
                      Alert Pre-Post file(s): <span style={{fontWeight: "400"}}>{prePostFileSource}</span>
                      </p>
                    </div>
                    </div>
                </div>
                <div className='filterbuttonsdiv'>
                    <button className='DataAccessEdit ml-0' onClick={handleCustomExport} style={{width: "100%"}}>
                        <span className='ViewUpdate'>Download Now</span>
                    </button>
                </div>
                </div>
                </Modal.Body>
            </Modal>

            <div className="MainContentMargin">
            <div className="col-lg-12 pr-0 chatFlowWebMaiContainer">
                {
                    singleAlertDet && <>
                        <div className="chatFlowWebHeaderTop d-flex align-items-center justify-content-center">
                            <div className="container-fluid m-0">
                                <div className="row">
                                    <div className="col-11  d-flex align-items-center">
                                        <img className="mr-3 float-left evidence-item" alt="backarrow icon" src={ChatFlowWebArrowIcon} onClick={() => {
                                            // history.push(`/alertdetails/${singleAlertDet.alertId}`)
                                            history.push(ALLALERTS)
                                        }} />
                                        <p className="chatFlowWebHeaderAlertText">
                                            {/* {singleAlertDet.alertCode} */}
                                            {singleAlertDet.alertType}
                                        </p>
                                        <div className={`chatFlowWebHeaderSeverityBox ${singleAlertDet.severity.toLowerCase()}-bg`}>
                                            <p>{singleAlertDet.severity}</p>
                                        </div>
                                        {/* <p className="chatFlowWebOperatorNameText">Ravi and You</p> */}
                                    </div>
                                    <div className="col-1" style={{display: "flex", justifyContent: "flex-end"}}>
                                    {/* <button 
                                    data-title='Export' 
                                    className='TwoWayAudioToggle'>
                                        <img 
                                            style={{padding: "2px", height: "22px" }} 
                                            src={Video_Export} 
                                            onClick={} 
                                            />
                                    </button>
                                    <button 
                                        data-title='Email Export' 
                                        className='TwoWayAudioToggle'>
                                            <img 
                                                style={{padding: "2px", height: "22px" }} 
                                                src={Video_Export} 
                                                onClick={} 
                                                />
                                            
                                    </button> */}
                                    <button className="DataAccessEdit" style={{ width: "100px", display: "none" }} onClick={(e) => {
                                        setShowEmail(!showEmail)
                                    }}>
                                        <span className="ViewUpdate">Email</span>
                                    </button>
                                    <button className="DataAccessEdit" style={{ width: "100px" }} onClick={(e) => {
                                        setShow(!show)
                                    }}>
                                        <span className="ViewUpdate">Download</span>
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="chatFlowWebHeaderBottom d-flex align-items-center justify-content-center">
                            <div className="container-fluid m-0">
                                <div className="row">
                                    <div className="col-11">
                                        <p className="chatFlowWebHeaderAddress">{singleAlertDet.site} | {singleAlertDet.actualTime} | ID: {singleAlertDet.alertId}</p>
                                    </div>
                                    <div className="col-1">
                                    {/* Modal Way     */}
                                    {/* <button onClick={() => setExportModal(true)}>Preview Export</button> */}

                                    {/* Data Set Way */}
                                    {/* Needs to be commented later */}
                                        {/* <button onClick={handleCustomExport}>Export Report</button> */}

                                    {/* Snapshot way     */}
                                    {/* <button onClick={handleGeneratePdf}>Export</button> */}

                                    {/* Export Image     */}
                                    {/* <button onClick={exportChart}>Export</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="Chatbot">
                            {isShowChatbotComponent &&
                                <Chatbotcomponent Configurations={config} />}
                        </div>
                        <div id="output"></div>
                        <div className="alert-report-section" id="contentToPrint" ref={reportTemplateRef}><Reactfunctionalchatbot messages={messages} /></div>
                            <Modal
                                show={exportModal}
                                dialogClassName={"activityUpdateModelAlertReport"}
                                onHide={() => setExportModal(false)}
                                backdrop="static"
                                keyboard={false}>
                                <Modal.Header closeButton style={{ padding: "0 1rem", borderBottom: "0" }}></Modal.Header>
                                <Modal.Body>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center', 
                                        }}
                                    >
                                <button 
                                style={{ backgroundColor: '#0b70d1', color: 'white', padding: '0.4rem' }}
                                // className="DataAccessEdit"
                                //  onClick={downloadPdfDocumentTemp}
                                 >
                                    Download PDF</button>
                                    </div>
                                <div style={{width: '600px'}} id="contentToPrintModal" ref={reportTemplateRef}><Reactfunctionalchatbot messages={messages} /></div>
                                </Modal.Body>
                            </Modal>
                    </>
                }
            </div>
            </div>
        </>

    )
}

const EscalationContactList = (lst) => {
    return <>
        {lst.length > 0 &&
            <div className="container">
                {lst.map(item => (
                    <div><p>{"L" + item.contactLevel}{" - "}{item.shortDesignation}{" - "}{item.phoneNumber}{" - "}{item.shortContactName}</p></div>
                ))}
               
            </div>
        }
        {lst.length == 0 &&
            <>
                <h3>No Contacts for Escalation</h3>
            </>}
    </>
}
